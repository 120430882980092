/*!
 * SmartAdmin v1.8.0 ()
 * Copyright 2011-2015 
 */ /*!
 * SmartAdmin - Responsive WebApp
 */
.flag {
  width: 16px;
  height: 11px;
  margin-top: -0.18em;
  display: inline-block;
}
.flag.flag-ad {
  background-position: -16px 0;
}
.flag.flag-ae {
  background-position: -32px 0;
}
.flag.flag-af {
  background-position: -48px 0;
}
.flag.flag-ag {
  background-position: -64px 0;
}
.flag.flag-ai {
  background-position: -80px 0;
}
.flag.flag-al {
  background-position: -96px 0;
}
.flag.flag-am {
  background-position: -112px 0;
}
.flag.flag-an {
  background-position: -128px 0;
}
.flag.flag-ao {
  background-position: -144px 0;
}
.flag.flag-ar {
  background-position: -160px 0;
}
.flag.flag-as {
  background-position: -176px 0;
}
.flag.flag-at {
  background-position: -192px 0;
}
.flag.flag-au {
  background-position: -208px 0;
}
.flag.flag-aw {
  background-position: -224px 0;
}
.flag.flag-az {
  background-position: -240px 0;
}
.flag.flag-ba {
  background-position: 0 -11px;
}
.flag.flag-bb {
  background-position: -16px -11px;
}
.flag.flag-bd {
  background-position: -32px -11px;
}
.flag.flag-be {
  background-position: -48px -11px;
}
.flag.flag-bf {
  background-position: -64px -11px;
}
.flag.flag-bg {
  background-position: -80px -11px;
}
.flag.flag-bh {
  background-position: -96px -11px;
}
.flag.flag-bi {
  background-position: -112px -11px;
}
.flag.flag-bj {
  background-position: -128px -11px;
}
.flag.flag-bm {
  background-position: -144px -11px;
}
.flag.flag-bn {
  background-position: -160px -11px;
}
.flag.flag-bo {
  background-position: -176px -11px;
}
.flag.flag-br {
  background-position: -192px -11px;
}
.flag.flag-bs {
  background-position: -208px -11px;
}
.flag.flag-bt {
  background-position: -224px -11px;
}
.flag.flag-bv {
  background-position: -240px -11px;
}
.flag.flag-bw {
  background-position: 0 -22px;
}
.flag.flag-by {
  background-position: -16px -22px;
}
.flag.flag-bz {
  background-position: -32px -22px;
}
.flag.flag-ca {
  background-position: -48px -22px;
}
.flag.flag-catalonia {
  background-position: -64px -22px;
}
.flag.flag-cd {
  background-position: -80px -22px;
}
.flag.flag-cf {
  background-position: -96px -22px;
}
.flag.flag-cg {
  background-position: -112px -22px;
}
.flag.flag-ch {
  background-position: -128px -22px;
}
.flag.flag-ci {
  background-position: -144px -22px;
}
.flag.flag-ck {
  background-position: -160px -22px;
}
.flag.flag-cl {
  background-position: -176px -22px;
}
.flag.flag-cm {
  background-position: -192px -22px;
}
.flag.flag-cn {
  background-position: -208px -22px;
}
.flag.flag-co {
  background-position: -224px -22px;
}
.flag.flag-cr {
  background-position: -240px -22px;
}
.flag.flag-cu {
  background-position: 0 -33px;
}
.flag.flag-cv {
  background-position: -16px -33px;
}
.flag.flag-cw {
  background-position: -32px -33px;
}
.flag.flag-cy {
  background-position: -48px -33px;
}
.flag.flag-cz {
  background-position: -64px -33px;
}
.flag.flag-de {
  background-position: -80px -33px;
}
.flag.flag-dj {
  background-position: -96px -33px;
}
.flag.flag-dk {
  background-position: -112px -33px;
}
.flag.flag-dm {
  background-position: -128px -33px;
}
.flag.flag-do {
  background-position: -144px -33px;
}
.flag.flag-dz {
  background-position: -160px -33px;
}
.flag.flag-ec {
  background-position: -176px -33px;
}
.flag.flag-ee {
  background-position: -192px -33px;
}
.flag.flag-eg {
  background-position: -208px -33px;
}
.flag.flag-eh {
  background-position: -224px -33px;
}
.flag.flag-england {
  background-position: -240px -33px;
}
.flag.flag-er {
  background-position: 0 -44px;
}
.flag.flag-es {
  background-position: -16px -44px;
}
.flag.flag-et {
  background-position: -32px -44px;
}
.flag.flag-eu {
  background-position: -48px -44px;
}
.flag.flag-fi {
  background-position: -64px -44px;
}
.flag.flag-fj {
  background-position: -80px -44px;
}
.flag.flag-fk {
  background-position: -96px -44px;
}
.flag.flag-fm {
  background-position: -112px -44px;
}
.flag.flag-fo {
  background-position: -128px -44px;
}
.flag.flag-fr {
  background-position: -144px -44px;
}
.flag.flag-ga {
  background-position: -160px -44px;
}
.flag.flag-gb {
  background-position: -176px -44px;
}
.flag.flag-gd {
  background-position: -192px -44px;
}
.flag.flag-ge {
  background-position: -208px -44px;
}
.flag.flag-gf {
  background-position: -224px -44px;
}
.flag.flag-gg {
  background-position: -240px -44px;
}
.flag.flag-gh {
  background-position: 0 -55px;
}
.flag.flag-gi {
  background-position: -16px -55px;
}
.flag.flag-gl {
  background-position: -32px -55px;
}
.flag.flag-gm {
  background-position: -48px -55px;
}
.flag.flag-gn {
  background-position: -64px -55px;
}
.flag.flag-gp {
  background-position: -80px -55px;
}
.flag.flag-gq {
  background-position: -96px -55px;
}
.flag.flag-gr {
  background-position: -112px -55px;
}
.flag.flag-gs {
  background-position: -128px -55px;
}
.flag.flag-gt {
  background-position: -144px -55px;
}
.flag.flag-gu {
  background-position: -160px -55px;
}
.flag.flag-gw {
  background-position: -176px -55px;
}
.flag.flag-gy {
  background-position: -192px -55px;
}
.flag.flag-hk {
  background-position: -208px -55px;
}
.flag.flag-hm {
  background-position: -224px -55px;
}
.flag.flag-hn {
  background-position: -240px -55px;
}
.flag.flag-hr {
  background-position: 0 -66px;
}
.flag.flag-ht {
  background-position: -16px -66px;
}
.flag.flag-hu {
  background-position: -32px -66px;
}
.flag.flag-ic {
  background-position: -48px -66px;
}
.flag.flag-id {
  background-position: -64px -66px;
}
.flag.flag-ie {
  background-position: -80px -66px;
}
.flag.flag-il {
  background-position: -96px -66px;
}
.flag.flag-im {
  background-position: -112px -66px;
}
.flag.flag-in {
  background-position: -128px -66px;
}
.flag.flag-io {
  background-position: -144px -66px;
}
.flag.flag-iq {
  background-position: -160px -66px;
}
.flag.flag-ir {
  background-position: -176px -66px;
}
.flag.flag-is {
  background-position: -192px -66px;
}
.flag.flag-it {
  background-position: -208px -66px;
}
.flag.flag-je {
  background-position: -224px -66px;
}
.flag.flag-jm {
  background-position: -240px -66px;
}
.flag.flag-jo {
  background-position: 0 -77px;
}
.flag.flag-jp {
  background-position: -16px -77px;
}
.flag.flag-ke {
  background-position: -32px -77px;
}
.flag.flag-kg {
  background-position: -48px -77px;
}
.flag.flag-kh {
  background-position: -64px -77px;
}
.flag.flag-ki {
  background-position: -80px -77px;
}
.flag.flag-km {
  background-position: -96px -77px;
}
.flag.flag-kn {
  background-position: -112px -77px;
}
.flag.flag-kp {
  background-position: -128px -77px;
}
.flag.flag-kr {
  background-position: -144px -77px;
}
.flag.flag-kurdistan {
  background-position: -160px -77px;
}
.flag.flag-kw {
  background-position: -176px -77px;
}
.flag.flag-ky {
  background-position: -192px -77px;
}
.flag.flag-kz {
  background-position: -208px -77px;
}
.flag.flag-la {
  background-position: -224px -77px;
}
.flag.flag-lb {
  background-position: -240px -77px;
}
.flag.flag-lc {
  background-position: 0 -88px;
}
.flag.flag-li {
  background-position: -16px -88px;
}
.flag.flag-lk {
  background-position: -32px -88px;
}
.flag.flag-lr {
  background-position: -48px -88px;
}
.flag.flag-ls {
  background-position: -64px -88px;
}
.flag.flag-lt {
  background-position: -80px -88px;
}
.flag.flag-lu {
  background-position: -96px -88px;
}
.flag.flag-lv {
  background-position: -112px -88px;
}
.flag.flag-ly {
  background-position: -128px -88px;
}
.flag.flag-ma {
  background-position: -144px -88px;
}
.flag.flag-mc {
  background-position: -160px -88px;
}
.flag.flag-md {
  background-position: -176px -88px;
}
.flag.flag-me {
  background-position: -192px -88px;
}
.flag.flag-mg {
  background-position: -208px -88px;
}
.flag.flag-mh {
  background-position: -224px -88px;
}
.flag.flag-mk {
  background-position: -240px -88px;
}
.flag.flag-ml {
  background-position: 0 -99px;
}
.flag.flag-mm {
  background-position: -16px -99px;
}
.flag.flag-mn {
  background-position: -32px -99px;
}
.flag.flag-mo {
  background-position: -48px -99px;
}
.flag.flag-mp {
  background-position: -64px -99px;
}
.flag.flag-mq {
  background-position: -80px -99px;
}
.flag.flag-mr {
  background-position: -96px -99px;
}
.flag.flag-ms {
  background-position: -112px -99px;
}
.flag.flag-mt {
  background-position: -128px -99px;
}
.flag.flag-mu {
  background-position: -144px -99px;
}
.flag.flag-mv {
  background-position: -160px -99px;
}
.flag.flag-mw {
  background-position: -176px -99px;
}
.flag.flag-mx {
  background-position: -192px -99px;
}
.flag.flag-my {
  background-position: -208px -99px;
}
.flag.flag-mz {
  background-position: -224px -99px;
}
.flag.flag-na {
  background-position: -240px -99px;
}
.flag.flag-nc {
  background-position: 0 -110px;
}
.flag.flag-ne {
  background-position: -16px -110px;
}
.flag.flag-nf {
  background-position: -32px -110px;
}
.flag.flag-ng {
  background-position: -48px -110px;
}
.flag.flag-ni {
  background-position: -64px -110px;
}
.flag.flag-nl {
  background-position: -80px -110px;
}
.flag.flag-no {
  background-position: -96px -110px;
}
.flag.flag-np {
  background-position: -112px -110px;
}
.flag.flag-nr {
  background-position: -128px -110px;
}
.flag.flag-nu {
  background-position: -144px -110px;
}
.flag.flag-nz {
  background-position: -160px -110px;
}
.flag.flag-om {
  background-position: -176px -110px;
}
.flag.flag-pa {
  background-position: -192px -110px;
}
.flag.flag-pe {
  background-position: -208px -110px;
}
.flag.flag-pf {
  background-position: -224px -110px;
}
.flag.flag-pg {
  background-position: -240px -110px;
}
.flag.flag-ph {
  background-position: 0 -121px;
}
.flag.flag-pk {
  background-position: -16px -121px;
}
.flag.flag-pl {
  background-position: -32px -121px;
}
.flag.flag-pm {
  background-position: -48px -121px;
}
.flag.flag-pn {
  background-position: -64px -121px;
}
.flag.flag-pr {
  background-position: -80px -121px;
}
.flag.flag-ps {
  background-position: -96px -121px;
}
.flag.flag-pt {
  background-position: -112px -121px;
}
.flag.flag-pw {
  background-position: -128px -121px;
}
.flag.flag-py {
  background-position: -144px -121px;
}
.flag.flag-qa {
  background-position: -160px -121px;
}
.flag.flag-re {
  background-position: -176px -121px;
}
.flag.flag-ro {
  background-position: -192px -121px;
}
.flag.flag-rs {
  background-position: -208px -121px;
}
.flag.flag-ru {
  background-position: -224px -121px;
}
.flag.flag-rw {
  background-position: -240px -121px;
}
.flag.flag-sa {
  background-position: 0 -132px;
}
.flag.flag-sb {
  background-position: -16px -132px;
}
.flag.flag-sc {
  background-position: -32px -132px;
}
.flag.flag-scotland {
  background-position: -48px -132px;
}
.flag.flag-sd {
  background-position: -64px -132px;
}
.flag.flag-se {
  background-position: -80px -132px;
}
.flag.flag-sg {
  background-position: -96px -132px;
}
.flag.flag-sh {
  background-position: -112px -132px;
}
.flag.flag-si {
  background-position: -128px -132px;
}
.flag.flag-sk {
  background-position: -144px -132px;
}
.flag.flag-sl {
  background-position: -160px -132px;
}
.flag.flag-sm {
  background-position: -176px -132px;
}
.flag.flag-sn {
  background-position: -192px -132px;
}
.flag.flag-so {
  background-position: -208px -132px;
}
.flag.flag-somaliland {
  background-position: -224px -132px;
}
.flag.flag-sr {
  background-position: -240px -132px;
}
.flag.flag-ss {
  background-position: 0 -143px;
}
.flag.flag-st {
  background-position: -16px -143px;
}
.flag.flag-sv {
  background-position: -32px -143px;
}
.flag.flag-sx {
  background-position: -48px -143px;
}
.flag.flag-sy {
  background-position: -64px -143px;
}
.flag.flag-sz {
  background-position: -80px -143px;
}
.flag.flag-tc {
  background-position: -96px -143px;
}
.flag.flag-td {
  background-position: -112px -143px;
}
.flag.flag-tf {
  background-position: -128px -143px;
}
.flag.flag-tg {
  background-position: -144px -143px;
}
.flag.flag-th {
  background-position: -160px -143px;
}
.flag.flag-tj {
  background-position: -176px -143px;
}
.flag.flag-tk {
  background-position: -192px -143px;
}
.flag.flag-tl {
  background-position: -208px -143px;
}
.flag.flag-tm {
  background-position: -224px -143px;
}
.flag.flag-tn {
  background-position: -240px -143px;
}
.flag.flag-to {
  background-position: 0 -154px;
}
.flag.flag-tr {
  background-position: -16px -154px;
}
.flag.flag-tt {
  background-position: -32px -154px;
}
.flag.flag-tv {
  background-position: -48px -154px;
}
.flag.flag-tw {
  background-position: -64px -154px;
}
.flag.flag-tz {
  background-position: -80px -154px;
}
.flag.flag-ua {
  background-position: -96px -154px;
}
.flag.flag-ug {
  background-position: -112px -154px;
}
.flag.flag-um {
  background-position: -128px -154px;
}
.flag.flag-us {
  background-position: -144px -154px;
}
.flag.flag-uy {
  background-position: -160px -154px;
}
.flag.flag-uz {
  background-position: -176px -154px;
}
.flag.flag-va {
  background-position: -192px -154px;
}
.flag.flag-vc {
  background-position: -208px -154px;
}
.flag.flag-ve {
  background-position: -224px -154px;
}
.flag.flag-vg {
  background-position: -240px -154px;
}
.flag.flag-vi {
  background-position: 0 -165px;
}
.flag.flag-vn {
  background-position: -16px -165px;
}
.flag.flag-vu {
  background-position: -32px -165px;
}
.flag.flag-wales {
  background-position: -48px -165px;
}
.flag.flag-wf {
  background-position: -64px -165px;
}
.flag.flag-ws {
  background-position: -80px -165px;
}
.flag.flag-ye {
  background-position: -96px -165px;
}
.flag.flag-yt {
  background-position: -112px -165px;
}
.flag.flag-za {
  background-position: -128px -165px;
}
.flag.flag-zanzibar {
  background-position: -144px -165px;
}
.flag.flag-zm {
  background-position: -160px -165px;
}
.flag.flag-zw {
  background-position: -176px -165px;
}
.flag + span {
  text-transform: capitalize;
  display: inline-block;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  margin-top: -3px;
}
@media (min-width: 768px) and (max-width: 979px) {
  .flag + span {
    max-width: 30px !important;
  }
}
:-webkit-full-screen #fullscreen > :first-child > a {
  background-color: #e8e8e8;
  background-image: -moz-linear-gradient(top, #5a5a5a 0, #686868 100%);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #5a5a5a),
    color-stop(100%, #686868)
  );
  background-image: -webkit-linear-gradient(to bottom, #5a5a5a 0, #686868 100%);
  background-image: -o-linear-gradient(to bottom, #5a5a5a 0, #686868 100%);
  background-image: -ms-linear-gradient(to bottom, #5a5a5a 0, #686868 100%);
  background-image: linear-gradient(to bottom, #5a5a5a 0, #686868 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5A5A5A', endColorstr='#686868', GradientType=0);
  -webkit-box-shadow: inset 0 0 3px 1px rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 0 3px 1px rgba(0, 0, 0, 0.15);
  color: #fff;
  border-color: #494949;
}
:-moz-full-screen #fullscreen > :first-child > a {
  background-color: #e8e8e8;
  background-image: -moz-linear-gradient(top, #5a5a5a 0, #686868 100%);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #5a5a5a),
    color-stop(100%, #686868)
  );
  background-image: -webkit-linear-gradient(to bottom, #5a5a5a 0, #686868 100%);
  background-image: -o-linear-gradient(to bottom, #5a5a5a 0, #686868 100%);
  background-image: -ms-linear-gradient(to bottom, #5a5a5a 0, #686868 100%);
  background-image: linear-gradient(to bottom, #5a5a5a 0, #686868 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5A5A5A', endColorstr='#686868', GradientType=0);
  -webkit-box-shadow: inset 0 0 3px 1px rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 0 3px 1px rgba(0, 0, 0, 0.15);
  color: #fff;
  border-color: #494949;
}
:-ms-fullscreen #fullscreen > :first-child > a {
  background-color: #e8e8e8;
  background-image: -moz-linear-gradient(top, #5a5a5a 0, #686868 100%);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #5a5a5a),
    color-stop(100%, #686868)
  );
  background-image: -webkit-linear-gradient(to bottom, #5a5a5a 0, #686868 100%);
  background-image: -o-linear-gradient(to bottom, #5a5a5a 0, #686868 100%);
  background-image: -ms-linear-gradient(to bottom, #5a5a5a 0, #686868 100%);
  background-image: linear-gradient(to bottom, #5a5a5a 0, #686868 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5A5A5A', endColorstr='#686868', GradientType=0);
  -webkit-box-shadow: inset 0 0 3px 1px rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 0 3px 1px rgba(0, 0, 0, 0.15);
  color: #fff;
  border-color: #494949;
}
:full-screen #fullscreen > :first-child > a {
  background-color: #e8e8e8;
  background-image: -moz-linear-gradient(top, #5a5a5a 0, #686868 100%);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #5a5a5a),
    color-stop(100%, #686868)
  );
  background-image: -webkit-linear-gradient(to bottom, #5a5a5a 0, #686868 100%);
  background-image: -o-linear-gradient(to bottom, #5a5a5a 0, #686868 100%);
  background-image: -ms-linear-gradient(to bottom, #5a5a5a 0, #686868 100%);
  background-image: linear-gradient(to bottom, #5a5a5a 0, #686868 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5A5A5A', endColorstr='#686868', GradientType=0);
  -webkit-box-shadow: inset 0 0 3px 1px rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 0 3px 1px rgba(0, 0, 0, 0.15);
  color: #fff;
  border-color: #494949;
}
:fullscreen #fullscreen > :first-child > a {
  background-color: #e8e8e8;
  background-image: -moz-linear-gradient(top, #5a5a5a 0, #686868 100%);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #5a5a5a),
    color-stop(100%, #686868)
  );
  background-image: -webkit-linear-gradient(to bottom, #5a5a5a 0, #686868 100%);
  background-image: -o-linear-gradient(to bottom, #5a5a5a 0, #686868 100%);
  background-image: -ms-linear-gradient(to bottom, #5a5a5a 0, #686868 100%);
  background-image: linear-gradient(to bottom, #5a5a5a 0, #686868 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5A5A5A', endColorstr='#686868', GradientType=0);
  -webkit-box-shadow: inset 0 0 3px 1px rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 0 3px 1px rgba(0, 0, 0, 0.15);
  color: #fff;
  border-color: #494949;
}
:-webkit-full-screen video {
  width: 100%;
  height: 100%;
}
.smart-form *,
.smart-form :after,
.smart-form :before {
  margin: 0;
  padding: 0;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
}
.smart-form .btn {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.smart-form .checkbox + .checkbox,
.smart-form .radio + .radio {
  margin-top: 0;
}
.smart-form footer .btn {
  float: right;
  height: 31px;
  margin: 10px 0 0 5px;
  padding: 0 22px;
  font: 300 15px/29px "Open Sans", Helvetica, Arial, sans-serif;
  cursor: pointer;
}
.smart-form legend {
  padding-top: 15px;
}
.smart-form {
  margin: 0;
  outline: 0;
  color: #666;
  position: relative;
}
.smart-form header {
  display: block;
  padding: 8px 0;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
  background: #fff;
  font-size: 16px;
  font-weight: 300;
  color: #232323;
  margin: 10px 14px 0;
}
.smart-form fieldset {
  display: block;
  padding: 25px 14px 5px;
  border: none;
  background: rgba(255, 255, 255, 0.9);
  position: relative;
}
.smart-form fieldset + fieldset {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.smart-form section {
  margin-bottom: 15px;
  position: relative;
}
.smart-form footer {
  display: block;
  padding: 7px 14px 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(248, 248, 248, 0.9);
}
.smart-form footer:after {
  content: "";
  display: table;
  clear: both;
}
.smart-form .label {
  display: block;
  margin-bottom: 6px;
  line-height: 19px;
  font-weight: 400;
  font-size: 13px;
  color: #333;
  text-align: left;
  white-space: normal;
}
.smart-form .label.col {
  margin: 0;
  padding-top: 7px;
}
.note,
.smart-form .note {
  margin-top: 6px;
  padding: 0 1px;
  font-size: 11px;
  line-height: 15px;
  color: #999;
}
.smart-form .note a {
  font-size: 13px;
}
.smart-form .button,
.smart-form .checkbox,
.smart-form .input,
.smart-form .radio,
.smart-form .select,
.smart-form .textarea,
.smart-form .toggle {
  position: relative;
  display: block;
  font-weight: 400;
}
.smart-form .input input,
.smart-form .select select,
.smart-form .textarea textarea {
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;
  height: 32px;
  line-height: 32px;
  padding: 5px 10px;
  outline: 0;
  border-width: 1px;
  border-style: solid;
  border-radius: 0;
  background: #fff;
  font: 13px/16px "Open Sans", Helvetica, Arial, sans-serif;
  color: #404040;
  appearance: normal;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.smart-form .input-file .button {
  position: absolute;
  top: 4px;
  right: 4px;
  float: none;
  height: 22px;
  margin: 0;
  padding: 0 14px;
  font-size: 13px;
  line-height: 22px;
}
.smart-form .input-file .button:hover {
  box-shadow: none;
}
.smart-form .input-file .button input {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  font-size: 30px;
  cursor: pointer;
  opacity: 0;
}
.smart-form .select i {
  position: absolute;
  top: 10px;
  right: 11px;
  width: 5px;
  height: 11px;
  background: #fff;
  box-shadow: 0 0 0 9px #fff;
}
.smart-form .select i:after,
.smart-form .select i:before {
  content: "";
  position: absolute;
  right: 0;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.smart-form .select i:after {
  bottom: 0;
  border-top: 4px solid #404040;
}
.smart-form .select i:before {
  top: 0;
  border-bottom: 4px solid #404040;
}
.smart-form .select-multiple select {
  height: auto;
}
.smart-form .textarea textarea {
  height: auto;
  resize: none;
}
.smart-form .textarea-resizable textarea {
  resize: vertical;
}
.smart-form .textarea-expandable textarea {
  height: 31px;
}
.smart-form .textarea-expandable textarea:focus {
  height: auto;
}
.smart-form .checkbox,
.smart-form .radio {
  margin-bottom: 4px;
  padding-left: 25px;
  line-height: 25px;
  color: #404040;
  cursor: pointer;
  font-size: 13px;
}
.smart-form .checkbox:last-child,
.smart-form .radio:last-child {
  margin-bottom: 0;
}
.smart-form .checkbox input,
.smart-form .radio input {
  position: absolute;
  left: -9999px;
}
.smart-form .checkbox i,
.smart-form .radio i {
  position: absolute;
  top: 3px;
  left: 0;
  display: block;
  width: 17px;
  height: 17px;
  outline: 0;
  border-width: 1px;
  border-style: solid;
  background: #fff;
}
.smart-form .radio i {
  border-radius: 50%;
}
.smart-form .checkbox input + i:after,
.smart-form .radio input + i:after {
  position: absolute;
  opacity: 0;
  transition: opacity 0.1s;
  -o-transition: opacity 0.1s;
  -ms-transition: opacity 0.1s;
  -moz-transition: opacity 0.1s;
  -webkit-transition: opacity 0.1s;
}
.smart-form .radio input + i:after {
  content: "";
  top: 4px;
  left: 4px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
}
.smart-form .checkbox input + i:after {
  content: "\f00c";
  top: -1px;
  left: 1px;
  width: 15px;
  height: 15px;
  font: 400 16px/19px FontAwesome;
  text-align: center;
}
.smart-form .checkbox input:checked:hover + i:after {
  content: "\f00d";
}
.smart-form .checkbox input:checked:disabled:hover + i:after {
  content: "\f00c";
}
.smart-form .checkbox input:checked + i:after,
.smart-form .radio input:checked + i:after {
  opacity: 1;
}
.smart-form .inline-group {
  margin: 0 -15px -4px 0;
}
.smart-form .inline-group:after {
  content: "";
  display: table;
  clear: both;
}
.smart-form .inline-group .checkbox,
.smart-form .inline-group .radio {
  float: left;
  margin-right: 30px;
}
.smart-form .inline-group .checkbox:last-child,
.smart-form .inline-group .radio:last-child {
  margin-bottom: 4px;
}
.smart-form .toggle {
  margin-bottom: 4px;
  padding-right: 61px;
  font-size: 15px;
  line-height: 25px;
  color: #404040;
  cursor: pointer;
}
.smart-form .toggle:last-child {
  margin-bottom: 0;
}
.smart-form .toggle input {
  position: absolute;
  left: -9999px;
}
.smart-form .toggle i {
  content: "";
  position: absolute;
  top: 4px;
  right: 0;
  display: block;
  width: 49px;
  height: 17px;
  border-width: 1px;
  border-style: solid;
  border-radius: 12px;
  background: #fff;
}
.smart-form .toggle i:after {
  content: attr(data-swchoff-text);
  position: absolute;
  top: 2px;
  right: 8px;
  left: 8px;
  font-style: normal;
  font-size: 9px;
  line-height: 13px;
  font-weight: 700;
  text-align: left;
  color: #5f5f5f;
}
.smart-form .toggle i:before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 4px;
  right: 4px;
  display: block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  opacity: 1;
  transition: right 0.2s;
  -o-transition: right 0.2s;
  -ms-transition: right 0.2s;
  -moz-transition: right 0.2s;
  -webkit-transition: right 0.2s;
}
.smart-form .toggle input:checked + i:after {
  content: attr(data-swchon-text);
  text-align: right;
}
.smart-form .toggle input:checked + i:before {
  right: 36px;
}
.smart-form .rating {
  margin-bottom: 4px;
  font-size: 13px;
  line-height: 25px;
  color: #404040;
}
.smart-form .rating:last-child {
  margin-bottom: 0;
}
.smart-form .rating input {
  position: absolute;
  left: -9999px;
}
.smart-form .rating label {
  display: block;
  float: right;
  height: 17px;
  margin-top: 5px;
  padding: 0 2px;
  font-size: 17px;
  line-height: 17px;
  cursor: pointer;
}
.smart-form .button {
  float: right;
  height: 31px;
  overflow: hidden;
  margin: 10px 0 0 5px;
  padding: 0 25px;
  outline: 0;
  border: 0;
  font: 300 15px/31px "Open Sans", Helvetica, Arial, sans-serif;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}
.smart-form .icon-append,
.smart-form .icon-prepend {
  position: absolute;
  top: 5px;
  width: 22px;
  height: 22px;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}
.smart-form .icon-append {
  right: 5px;
  padding-left: 3px;
  border-left-width: 1px;
  border-left-style: solid;
}
.smart-form .icon-prepend {
  left: 5px;
  padding-right: 3px;
  border-right-width: 1px;
  border-right-style: solid;
}
.smart-form .input .icon-prepend + input,
.smart-form .textarea .icon-prepend + textarea {
  padding-left: 37px;
}
.smart-form .input .icon-append + input,
.smart-form .textarea .icon-append + textarea {
  padding-right: 37px;
}
.smart-form .input .icon-prepend + .icon-append + input,
.smart-form .textarea .icon-prepend + .icon-append + textarea {
  padding-left: 37px;
}
.smart-form .row {
  margin: 0 -15px;
}
.smart-form .row:after {
  content: "";
  display: table;
  clear: both;
}
.smart-form .col {
  float: left;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.smart-form .col-1 {
  width: 8.33%;
}
.smart-form .col-2 {
  width: 16.66%;
}
.smart-form .col-3 {
  width: 25%;
}
.smart-form .col-4 {
  width: 33.33%;
}
.smart-form .col-5 {
  width: 41.66%;
}
.smart-form .col-6 {
  width: 50%;
}
.smart-form .col-8 {
  width: 66.67%;
}
.smart-form .col-9 {
  width: 75%;
}
.smart-form .col-10 {
  width: 83.33%;
}
@media screen and (max-width: 600px) {
  .smart-form .col {
    float: none;
    width: 100%;
  }
}
.smart-form .select select {
  padding: 5px;
}
.smart-form .tooltip {
  position: absolute;
  z-index: 99999;
  left: -9999px;
  padding: 2px 8px 3px;
  font-size: 11px;
  line-height: 16px;
  font-weight: 400;
  background: rgba(0, 0, 0, 0.9);
  color: #fff;
  opacity: 0;
  transition: margin 0.3s, opacity 0.3s;
  -o-transition: margin 0.3s, opacity 0.3s;
  -ms-transition: margin 0.3s, opacity 0.3s;
  -moz-transition: margin 0.3s, opacity 0.3s;
  -webkit-transition: margin 0.3s, opacity 0.3s;
}
.smart-form .tooltip:after {
  content: "";
  position: absolute;
}
.smart-form .input input:focus + .tooltip,
.smart-form .textarea textarea:focus + .tooltip {
  opacity: 1;
}
.smart-form .tooltip-top-right {
  bottom: 100%;
  margin-bottom: 15px;
}
.smart-form .tooltip-top-right:after {
  top: 100%;
  right: 11px;
  border-top: 4px solid rgba(0, 0, 0, 0.9);
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.smart-form .input input:focus + .tooltip-top-right,
.smart-form .textarea textarea:focus + .tooltip-top-right {
  right: 0;
  left: auto;
  margin-bottom: 5px;
}
.smart-form .tooltip-top-left {
  bottom: 100%;
  margin-bottom: 15px;
}
.smart-form .tooltip-top-left:after {
  top: 100%;
  left: 11px;
  border-top: 4px solid rgba(0, 0, 0, 0.9);
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.smart-form .input input:focus + .tooltip-top-left,
.smart-form .textarea textarea:focus + .tooltip-top-left {
  right: auto;
  left: 0;
  margin-bottom: 5px;
}
.smart-form .tooltip-right {
  top: 4px;
  white-space: nowrap;
  margin-left: 15px;
}
.smart-form .tooltip-right:after {
  top: 6px;
  right: 100%;
  border-top: 4px solid transparent;
  border-right: 4px solid rgba(0, 0, 0, 0.9);
  border-bottom: 4px solid transparent;
}
.smart-form .input input:focus + .tooltip-right,
.smart-form .textarea textarea:focus + .tooltip-right {
  left: 100%;
  margin-left: 5px;
}
.smart-form .tooltip-left {
  top: 4px;
  white-space: nowrap;
  margin-right: 15px;
}
.smart-form .tooltip-left:after {
  top: 6px;
  left: 100%;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid rgba(0, 0, 0, 0.9);
}
.smart-form .input input:focus + .tooltip-left,
.smart-form .textarea textarea:focus + .tooltip-left {
  right: 100%;
  left: auto;
  margin-right: 5px;
}
.smart-form .tooltip-bottom-right {
  top: 100%;
  margin-top: 15px;
}
.smart-form .tooltip-bottom-right:after {
  bottom: 100%;
  right: 11px;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(0, 0, 0, 0.9);
  border-left: 4px solid transparent;
}
.smart-form .input input:focus + .tooltip-bottom-right,
.smart-form .textarea textarea:focus + .tooltip-bottom-right {
  right: 0;
  left: auto;
  margin-top: 5px;
}
.smart-form .tooltip-bottom-left {
  top: 100%;
  margin-top: 15px;
}
.smart-form .tooltip-bottom-left:after {
  bottom: 100%;
  left: 11px;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(0, 0, 0, 0.9);
  border-left: 4px solid transparent;
}
.smart-form .input input:focus + .tooltip-bottom-left,
.smart-form .textarea textarea:focus + .tooltip-bottom-left {
  right: auto;
  left: 0;
  margin-top: 5px;
}
.smart-form .checkbox i,
.smart-form .icon-append,
.smart-form .icon-prepend,
.smart-form .input input,
.smart-form .radio i,
.smart-form .select select,
.smart-form .textarea textarea,
.smart-form .toggle i {
  border-color: #bdbdbd;
  transition: border-color 0.3s;
  -o-transition: border-color 0.3s;
  -ms-transition: border-color 0.3s;
  -moz-transition: border-color 0.3s;
  -webkit-transition: border-color 0.3s;
}
.smart-form .toggle i:before {
  background-color: #3276b1;
}
.smart-form .rating label {
  color: #ccc;
  transition: color 0.3s;
  -o-transition: color 0.3s;
  -ms-transition: color 0.3s;
  -moz-transition: color 0.3s;
  -webkit-transition: color 0.3s;
}
.smart-form .button {
  background-color: #3276b1;
  opacity: 0.8;
  transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  -ms-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -webkit-transition: opacity 0.2s;
}
.smart-form .button.button-secondary {
  background-color: #b3b3b3;
}
.smart-form .icon-append,
.smart-form .icon-prepend {
  color: #a2a2a2;
}
.smart-form .checkbox:hover i,
.smart-form .input:hover input,
.smart-form .radio:hover i,
.smart-form .select:hover select,
.smart-form .textarea:hover textarea,
.smart-form .toggle:hover i {
  border-color: #5d98cc;
}
.smart-form .rating input + label:hover,
.smart-form .rating input + label:hover ~ label {
  color: #3276b1;
}
.smart-form .button:hover {
  opacity: 1;
}
.smart-form .checkbox:hover i,
.smart-form .radio:hover i,
.smart-form .toggle:hover i {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
}
.smart-form .checkbox:active i,
.smart-form .radio:active i,
.smart-form .toggle:active i {
  background: #f0f0f0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
}
.smart-form .checkbox input:focus + i,
.smart-form .input input:focus,
.smart-form .radio input:focus + i,
.smart-form .select select:focus,
.smart-form .textarea textarea:focus,
.smart-form .toggle input:focus + i {
  border-color: #3276b1;
}
.smart-form .radio input + i:after {
  background-color: #3276b1;
}
.smart-form .checkbox input + i:after {
  color: #3276b1;
}
.smart-form .checkbox input:checked + i,
.smart-form .radio input:checked + i,
.smart-form .toggle input:checked + i {
  border-color: #3276b1;
}
.smart-form .rating input:checked ~ label {
  color: #3276b1;
}
.smart-form .checkbox.state-error i,
.smart-form .radio.state-error i,
.smart-form .state-error input,
.smart-form .state-error select,
.smart-form .state-error textarea,
.smart-form .toggle.state-error i {
  background: #fff0f0;
  border-color: #a90329;
}
.smart-form .toggle.state-error input:checked + i {
  background: #fff0f0;
}
.smart-form .state-error + em {
  display: block;
  margin-top: 6px;
  padding: 0 1px;
  font-style: normal;
  font-size: 11px;
  line-height: 15px;
  color: #d56161;
}
.smart-form .rating.state-error + em {
  margin-top: -4px;
  margin-bottom: 4px;
}
.smart-form .state-error select + i {
  background: #fff0f0;
  box-shadow: 0 0 0 9px #fff0f0;
}
.state-error .icon-append,
.state-error .icon-prepend {
  color: #ed1c24;
}
.smart-form .checkbox.state-success i,
.smart-form .radio.state-success i,
.smart-form .state-success input,
.smart-form .state-success select,
.smart-form .state-success textarea,
.smart-form .toggle.state-success i {
  background: #f0fff0;
  border-color: #7dc27d;
}
.smart-form .toggle.state-success input:checked + i {
  background: #f0fff0;
}
.smart-form .note-success {
  color: #6fb679;
}
.smart-form .state-success select + i {
  background: #f0fff0;
  box-shadow: 0 0 0 9px #f0fff0;
}
.smart-form .button.state-disabled,
.smart-form .checkbox.state-disabled,
.smart-form .input.state-disabled input,
.smart-form .radio.state-disabled,
.smart-form .select.state-disabled,
.smart-form .textarea.state-disabled,
.smart-form .toggle.state-disabled {
  cursor: default !important;
  opacity: 0.6 !important;
}
.smart-form .checkbox.state-disabled:hover i,
.smart-form .input.state-disabled:hover input,
.smart-form .radio.state-disabled:hover i,
.smart-form .select.state-disabled:hover select,
.smart-form .textarea.state-disabled:hover textarea,
.smart-form .toggle.state-disabled:hover i {
  border-color: #e5e5e5 !important;
}
.smart-form .state-disabled.checkbox input + i:after,
.smart-form .state-disabled.checkbox input:checked + i,
.smart-form .state-disabled.radio input + i:after,
.smart-form .state-disabled.radio input:checked + i,
.smart-form .state-disabled.toggle input:checked + i {
  border-color: #e5e5e5 !important;
  color: #333 !important;
}
.smart-form .state-disabled.radio input + i:after {
  background-color: #333;
}
.smart-form .message {
  display: none;
  color: #6fb679;
}
.smart-form .message i {
  display: block;
  margin: 0 auto 20px;
  width: 81px;
  height: 81px;
  border: 1px solid #6fb679;
  border-radius: 50%;
  font-size: 30px;
  line-height: 81px;
}
.smart-form.submited fieldset,
.smart-form.submited footer {
  display: none;
}
.smart-form.submited .message {
  display: block;
  padding: 25px 30px;
  background: rgba(255, 255, 255, 0.9);
  font: 300 18px/27px "Open Sans", Helvetica, Arial, sans-serif;
  text-align: center;
}
.smart-form .ui-widget-content .ui-spinner-input {
  margin: 0;
  border: 0;
  box-shadow: none !important;
  height: 29px;
}
.smart-form-modal {
  position: fixed;
  z-index: 1;
  display: none;
  width: 400px;
}
.smart-form-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}
.ui-timepicker-div .ui-widget-header {
  margin-bottom: 8px;
}
.ui-timepicker-div dl {
  text-align: left;
}
.ui-timepicker-div dl dt {
  float: left;
  clear: left;
  padding: 0 0 0 5px;
}
.ui-timepicker-div dl dd {
  margin: 17px 10px 12px 40%;
}
.ui-timepicker-div td {
  font-size: 90%;
}
.ui-tpicker-grid-label {
  background: 0 0;
  border: none;
  margin: 0;
  padding: 0;
}
.ui-timepicker-rtl {
  direction: rtl;
}
.ui-timepicker-rtl dl {
  text-align: right;
  padding: 0 5px 0 0;
}
.ui-timepicker-rtl dl dt {
  float: right;
  clear: right;
}
.ui-timepicker-rtl dl dd {
  margin: 0 40% 10px 10px;
}
.smart-form input.input-lg {
  height: 44px;
  padding: 10px 16px;
  font-size: 17px;
  line-height: 1.33;
}
.smart-form input.input-sm {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}
.smart-form input.input-xs {
  height: 24px;
  padding: 2px 10px;
  font-size: 11px;
  line-height: 1.5;
}
.smart-form select.input-sm {
  height: 30px;
  line-height: 15px;
  font-size: 12px;
}
.smart-form select.input-sm + i {
  height: 10px;
}
.smart-form select.input-lg {
  height: 44px;
  line-height: 22px;
  font-size: 17px;
}
.smart-form select.input-lg + i {
  height: 11px;
  top: 17px;
}
.smart-form .checkbox input + input[type="hidden"] + i:after {
  content: "\f00c";
  top: -1px;
  left: 1px;
  width: 15px;
  height: 15px;
  font: 400 16px/19px FontAwesome;
  text-align: center;
}
.smart-form .checkbox input:checked:hover + input[type="hidden"] + i:after {
  content: "\f00d";
}
.smart-form
  .checkbox
  input:checked:disabled:hover
  + input[type="hidden"]
  + i:after {
  content: "\f00c";
}
.smart-form .checkbox input:checked + input[type="hidden"] + i:after {
  opacity: 1;
}
.smart-form .toggle input:checked + input[type="hidden"] + i:after {
  content: attr(data-swchon-text);
  text-align: right;
}
.smart-form .toggle input:checked + input[type="hidden"] + i:before {
  right: 36px;
}
.error-box {
  max-width: 900px;
  margin: 0 auto;
}
.error-text {
  font-weight: 400;
  color: #fff;
  letter-spacing: -4px;
  font-size: 700%;
  margin-bottom: 30px;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #bfbfbf, 0 3px 0 #bbb, 0 4px 0 #b9b9b9,
    0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2),
    0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2),
    0 20px 20px rgba(0, 0, 0, 0.15);
}
.error-search {
  width: 300px;
  margin: 0 auto 20px;
}
.error-icon-shadow {
  text-shadow: 0 1px 0 #803838, 0 2px 0 #a85d5d, 0 3px 0 #b86565,
    0 4px 0 #d86f6f, 0 5px 0 #b3a5a5, 0 6px 1px rgba(131, 51, 51, 0.1),
    0 0 5px rgba(102, 30, 30, 0.1), 0 1px 3px rgba(95, 15, 15, 0.3),
    0 3px 5px rgba(100, 23, 23, 0.2), 0 5px 10px rgba(114, 30, 30, 0.25),
    0 10px 10px rgba(94, 15, 15, 0.2), 0 20px 20px rgba(121, 38, 38, 0.15);
}
.display-image {
  margin-top: -60px;
  margin-right: 20px;
}
.terms-body {
  max-height: 400px;
  overflow: auto;
}
#extr-page {
  background: #fff;
  overflow: visible;
}
#extr-page .container {
  border: none;
}
#extr-page #header {
  margin: 0;
  height: 71px;
  border-bottom: 1px solid #eee !important;
  overflow: hidden;
  padding: 0 30px;
  border-width: 0;
  min-height: 28px;
  background: #f4f4f4 !important;
}
#extr-page #main {
  padding-top: 20px;
}
#extr-page #header #logo {
  margin-top: 22px;
  margin-left: 0;
}
#extr-page #header #logo img {
  width: 137px;
}
#extr-page-header-space {
  float: right;
  text-align: right;
  display: block;
  vertical-align: middle;
  line-height: 71px;
}
#extr-page-header-space > :first-child {
  font-size: 13px;
  margin-right: 14px;
  vertical-align: -3px;
  font-weight: 400;
}
#extr-page-header-space .btn {
  font-weight: 700;
  text-transform: uppercase;
}
#extr-page-header-space > * {
  display: inline-block;
}
#extr-page #main {
  background: #fff;
  margin: 0;
}
#extr-page .hero {
  background-repeat: no-repeat;
  background-position: 0 137px;
  height: 360px;
  width: 100%;
  float: left;
}
#extr-page h4.paragraph-header {
  color: #565656;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  margin-top: 15px;
  width: 270px;
}
#extr-page h5.about-heading {
  color: #565656;
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  margin: 0 0 5px;
}
#extr-page .login-header-big {
  font-weight: 400;
}
#extr-page .login-desc-box-l {
  min-height: 350px;
  width: 50%;
}
#extr-page .login-app-icons {
  vertical-align: top;
  margin-top: 90px;
  width: 300px;
}
@media (min-width: 768px) and (max-width: 880px) {
  #extr-page #header {
    padding: 0 5px !important;
  }
}
@media only screen and (min-width: 0) and (max-width: 679px) {
  #extr-page #header {
    padding: 0 5px !important;
  }
  #extr-page #header #logo img {
    width: 135px;
  }
  #extr-page #header #logo {
    margin-top: 22px;
  }
}
html {
  margin: 0;
  padding: 0;
  min-height: 100%;
  position: relative;
}
body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  direction: ltr;
}
body.mobile-view-activated.hidden-menu {
  overflow-x: hidden;
}
body.modal-open {
  overflow: hidden !important;
}
a:active,
a:focus,
a:hover,
button,
button:active,
button:focus,
embed,
input::-moz-focus-inner,
object {
  outline: 0;
}
h1,
h2,
h3,
h4 {
  margin: 0;
  font-family: "Open Sans", Arial, Helvetica, Sans-Serif;
  font-weight: 300;
}
.page-title {
  margin: 12px 0 28px;
}
.page-title span {
  font-size: 16px;
  color: #333;
  display: inline-block;
  vertical-align: 1px;
}
label {
  font-weight: 400;
}
:focus {
  outline: 0 !important;
}
a,
button,
input {
  -ms-touch-action: none !important;
}
.uneditable-input:focus,
input[type="color"]:focus,
input[type="date"]:focus,
input[type="datetime-local"]:focus,
input[type="datetime"]:focus,
input[type="email"]:focus,
input[type="month"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="text"]:focus,
input[type="time"]:focus,
input[type="url"]:focus,
input[type="week"]:focus,
select:focus,
textarea:focus {
  outline: 0;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.form-control,
.input-lg,
.input-sm,
.input-xs {
  border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
}
.input-xs {
  height: 24px;
  padding: 2px 10px;
  font-size: 11px;
  line-height: 1.5;
}
.btn-xs {
  padding: 1px 5px;
}
.btn-sm {
  padding: 6px 10px 5px;
}
.btn-lg {
  padding: 10px 16px;
}
.no-space {
  margin: 0;
}
.no-space > [class*="col-"] {
  margin: 0 !important;
  padding-right: 0;
  padding-left: 0;
}
#content {
  padding: 10px 14px;
  position: relative;
}
body.container {
  position: absolute;
  left: 0;
  right: 0;
  padding: 0;
  border-left: 1px solid #bfbfbf;
  border-right: 1px solid #bfbfbf;
  box-shadow: 0 2px 70px rgba(0, 0, 0, 0.45);
}
body.container.hidden-menu:not(.mobile-view-activated) {
  overflow: hidden;
}
body.container.modal-open {
  padding: 0 !important;
}
h1 {
  letter-spacing: -1px;
  font-size: 24px;
  margin: 10px 0;
}
h1 small {
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -1px;
}
.lead {
  font-size: 19px;
}
h2 {
  letter-spacing: -1px;
  font-size: 22px;
  margin: 20px 0;
  line-height: normal;
}
h3 {
  display: block;
  font-size: 19px;
  font-weight: 400;
  margin: 20px 0;
  line-height: normal;
}
h4 {
  line-height: normal;
}
h5 {
  font-size: 17px;
  font-weight: 300;
  margin: 10px 0;
  line-height: normal;
}
h6 {
  font-size: 15px;
  margin: 10px 0;
  font-weight: 700;
  line-height: normal;
}
.row-seperator-header {
  margin: 15px 14px 20px;
  border-bottom: none;
  display: block;
  color: #646464;
  font-size: 20px;
  font-weight: 400;
}
.center-canvas,
.center-child-canvas > canvas {
  display: block !important;
  margin: 0 auto !important;
}
.bordered {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.bordered:hover {
  border: 1px solid rgba(0, 0, 0, 0.4);
}
aside {
  display: block;
  background: #3a3633;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSI5NyUiIHN0b3AtY29sb3I9IiMzYTM2MzMiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMmEyNzI1IiBzdG9wLW9wYWNpdHk9IjEiLz4KICA8L2xpbmVhckdyYWRpZW50PgogIDxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjZ3JhZC11Y2dnLWdlbmVyYXRlZCkiIC8+Cjwvc3ZnPg==);
  background: -moz-linear-gradient(left, #3a3633 93%, #2a2725 100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(93%, #3a3633),
    color-stop(100%, #2a2725)
  );
  background: -webkit-linear-gradient(left, #3a3633 93%, #2a2725 100%);
  background: -o-linear-gradient(left, #3a3633 93%, #2a2725 100%);
  background: -ms-linear-gradient(left, #3a3633 93%, #2a2725 100%);
  background: linear-gradient(to right, #3a3633 93%, #2a2725 100%);
  min-height: 100%;
}
#header {
  display: block;
  height: 49px;
  margin: 0;
  padding: 0 13px 0 0;
  background-color: #f3f3f3;
  background-image: -moz-linear-gradient(top, #f3f3f3, #e2e2e2);
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    from(#f3f3f3),
    to(#e2e2e2)
  );
  background-image: -webkit-linear-gradient(top, #f3f3f3, #e2e2e2);
  background-image: -o-linear-gradient(top, #f3f3f3, #e2e2e2);
  background-image: linear-gradient(to bottom, #f3f3f3, #e2e2e2);
  background-repeat: repeat-x;
  position: relative;
  z-index: 905;
}
#header > div {
  display: inline-block;
  vertical-align: middle;
  height: 49px;
  float: left;
}
#header > div.open {
  font-weight: 700;
}
.fixed-header #header {
  position: fixed;
  width: 100%;
  z-index: 905;
  top: 0;
  border-bottom: 2px solid #999;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: -11px 12px 23px rgba(0, 0, 0, 0.1);
}
.fixed-header.fixed-ribbon #header {
  border-bottom: none;
  box-shadow: none;
}
.container.fixed-header #header {
  max-width: 1164px;
}
.fixed-header #header .ajax-dropdown {
  z-index: 906;
}
.fixed-header #shortcut {
  position: fixed;
}
.fixed-header #main {
  margin-top: 49px;
}
#logo-group > span {
  display: inline-block;
  height: 39px;
  float: left;
}
#logo-group span#activity {
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  cursor: default !important;
  display: inline-block;
  font-weight: 700;
  height: 24px;
  width: 24px;
  padding: 2px;
  text-align: center;
  text-decoration: none !important;
  -moz-user-select: none;
  -webkit-user-select: none;
  background-color: #f8f8f8;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f8f8f8),
    to(#f1f1f1)
  );
  background-image: -webkit-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -moz-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -ms-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -o-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: linear-gradient(to bottom, #f8f8f8, #f1f1f1);
  border: 1px solid #bfbfbf;
  color: #c4bab6;
  font-size: 19px;
  margin: 10px 0 0;
  position: relative;
}
#logo-group span#activity:hover {
  border: 1px solid #bfbfbf;
  transition: all 0s;
  cursor: pointer !important;
  -webkit-box-shadow: inset 0 0 4px 1px rgba(0, 0, 0, 0.08);
  box-shadow: inset 0 0 4px 1px rgba(0, 0, 0, 0.08);
}
#logo {
  display: inline-block;
  width: 175px;
  margin-top: 13px;
  margin-left: 9px;
}
#logo img {
  width: 110px;
  height: auto;
  padding-left: 3px;
}
#activity b.badge {
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
  background: #0091d9;
  display: inline-block;
  font-size: 10px;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1),
    inset 0 -1px 0 rgba(0, 0, 0, 0.07);
  color: #fff;
  font-weight: 700;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  padding: 2px 4px 3px;
  text-align: center;
  line-height: normal;
}
#activity.active .badge {
  background: #006fa6 !important;
}
#project-context,
.project-context {
  display: inline-block;
  padding: 7px 13px 0;
  position: relative;
}
#project-context > span,
.project-context > span {
  display: block;
}
#project-context > :first-child,
.project-context > :first-child {
  display: block;
  color: #bfbfbf;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  text-shadow: 0 0 1px #fff;
  padding-left: 0;
  text-align: left;
}
#project-selector,
.project-selector {
  max-width: 97%;
  white-space: nowrap;
  background: 0 0;
  border: none;
  padding: 0;
  text-align: left;
  color: #555;
  font-size: 14px;
  cursor: pointer;
}
#project-selector:hover,
.project-selector:hover {
  color: #333;
}
.header-search {
  position: relative;
}
.header-search.pull-right {
  margin-left: 6px;
}
.header-search > input[type="text"] {
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  min-width: 200px;
  width: 100%;
  height: 30px;
  padding: 0 10px;
  outline: 0;
  border-width: 1px;
  border-style: solid;
  border-radius: 0;
  border-color: #bfbfbf;
  background: #fff;
  color: #404040;
  appearance: normal;
  -moz-appearance: none;
  -webkit-appearance: none;
  margin-top: 10px;
  line-height: normal;
}
.header-search > button {
  background: 0 0;
  border: none;
  color: #6d6a69;
  font-size: 17px;
  height: 30px;
  line-height: 30px;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 10px;
  width: 30px;
  z-index: 2;
}
#search-mobile {
  display: none;
}
#cancel-search-js {
  position: absolute;
  display: none;
  background: #a90329;
  z-index: 3;
  color: #fff;
  padding: 0;
  right: 10px;
  top: 10px;
  text-align: center;
  height: 29px;
  line-height: 29px;
  width: 30px;
  font-size: 17px;
  text-decoration: none !important;
}
#header > :first-child,
aside {
  width: 220px;
}
#left-panel {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 904;
  padding-top: 49px;
}
#main {
  margin-left: 220px;
  padding: 0;
  padding-bottom: 52px;
  min-height: 500px;
  position: relative;
}
#ribbon {
  min-height: 40px;
  background: #474544;
  padding: 0 13px;
  position: relative;
}
#ribbon .breadcrumb {
  display: inline-block;
  margin: 0;
  padding: 11px 34px 11px 0 !important;
  background: 0 0;
  vertical-align: top;
}
#ribbon .breadcrumb,
#ribbon .breadcrumb a {
  color: #bbb !important;
  text-decoration: none !important;
}
#ribbon .breadcrumb li:last-child,
#ribbon .breadcrumb > .active {
  color: #e4e4e4;
}
.fixed-ribbon #ribbon {
  position: fixed;
  right: 0;
  left: 220px;
  top: 49px;
  z-index: 901;
}
.container.fixed-ribbon #ribbon {
  width: 944px;
  left: 590px;
}
.fixed-ribbon #content {
  padding-top: 50px;
}
.minified.fixed-ribbon #ribbon {
  left: 45px;
}
.hidden-menu.fixed-ribbon #ribbon {
  left: 10px;
}
.fixed-navigation #left-panel {
  height: 100%;
  position: fixed;
}
.fixed-navigation nav {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  height: 100%;
  padding-bottom: 49px;
}
.fixed-navigation nav > ul {
  padding-right: 15px;
  position: relative;
  height: 100%;
  width: 114%;
  overflow-x: hidden;
  overflow-y: scroll;
}
.mobile-detected.fixed-navigation nav > ul {
  padding-right: 30px !important;
}
.minified.fixed-navigation nav {
  -webkit-overflow-scrolling: none;
  overflow: visible;
  height: auto;
  padding-bottom: 0;
}
.minified.fixed-navigation nav > ul {
  overflow: visible;
  padding-right: 0;
  width: 100%;
}
nav ul li a:active {
  background: #616161 !important;
}
nav ul {
  width: 100%;
  padding: 40px 28px 25px 0;
  padding: 0;
  margin: 0;
  font-size: 13px;
  line-height: 0.5em;
  list-style: none;
  position: relative;
}
nav ul .active > a {
  color: #fff !important;
  position: relative;
}
nav ul li.active > a:before {
  content: "\f0d9";
  font-family: FontAwesome;
  display: block;
  height: 27px;
  line-height: normal;
  width: 27px;
  position: absolute;
  right: -21px;
  font-size: 20px;
  color: #eee;
}
nav ul li.active.open > a:before {
  content: "";
}
nav > ul > li:last-child.open {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
nav ul li a {
  line-height: normal;
  font-size: 14px;
  padding: 10px 10px 10px 11px;
  color: #c0bbb7;
  display: block;
  font-weight: 400;
  text-decoration: none !important;
  position: relative;
}
nav > ul > li > ul > li > a,
nav > ul > li > ul > li > ul > li > a {
  padding-top: 7px;
  padding-bottom: 7px;
}
nav ul li a:focus {
  color: #c9c9c9;
}
nav ul li a:hover {
  color: #fff;
  text-decoration: none;
}
nav ul li a.inactive,
nav ul li a.inactive:hover {
  opacity: 0.5;
  cursor: not-allowed;
  color: #c0bbb7;
}
nav ul b {
  float: right;
  font-size: 11px;
  margin-top: 2px;
}
nav ul span.menu-item-parent {
  display: inline-block;
  margin: 0;
  padding: 0;
}
nav ul li.open > a,
nav ul li.open > a b {
  color: #fff !important;
}
nav > ul > li > a b {
  position: absolute !important;
  right: 10px;
  top: 10px;
}
nav > ul > li > a > i {
  margin-right: 5px;
  width: 15px;
  display: inline-block;
  text-align: center;
  position: relative;
}
nav > ul > li > a > i > em {
  font-size: 9px;
  display: block;
  padding: 2px;
  position: absolute;
  top: -8px;
  right: -6px;
  text-decoration: none;
  font-style: normal;
  background: #ed1c24;
  color: #fff;
  min-width: 13px;
  border-radius: 50%;
  max-height: 13px;
  line-height: 8px;
  font-weight: 700;
  vertical-align: baseline;
  white-space: nowrap;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
nav ul li li {
  border-bottom: none;
  position: relative;
}
nav > ul > li > ul::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  left: 23px;
  top: 0;
  bottom: 0;
  border-left: 1px solid #7a7a7a;
}
nav > ul ul li::before {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  left: 23px;
  top: 16px;
  border-top: 1px solid #7a7a7a;
  z-index: 1;
}
nav > ul ul ul li::before {
  content: "";
  display: block;
  position: absolute;
  width: 18px;
  left: 10px;
  top: 17px;
  border-top: 1px solid transparent;
}
nav > ul ul li a i {
  font-size: 14px !important;
  width: 18px !important;
  text-align: center !important;
  vertical-align: 0 !important;
  line-height: 1 !important;
}
nav > ul > li:hover > ul::before,
nav > ul > li:hover > ul > li::before {
  border-color: #acacac !important;
}
nav ul ul {
  margin: 0;
  display: none;
  background: rgba(69, 69, 69, 0.6);
  padding: 7px 0;
}
nav ul ul ul {
  background: 0 0;
  padding: 0;
}
nav ul ul li {
  margin: 0;
  padding: 0;
}
nav ul ul li > a {
  padding-left: 42px;
  font-size: 14px;
  font-weight: 400;
  outline: 0;
}
nav ul ul li > a:hover {
  background-color: rgba(69, 69, 69, 0.8);
  color: #fff;
}
nav ul ul ul li a {
  color: #bfbfbf;
  padding: 8px 10px 8px 60px;
  font-size: 14px;
}
nav ul ul ul li a:hover {
  color: #fff;
}
nav ul ul ul ul li a {
  padding-left: 80px;
}
nav ul ul ul ul ul li a {
  padding-left: 100px;
}
nav ul ul ul ul ul ul li a {
  padding-left: 120px;
}
nav ul ul b {
  color: #b3b3b3;
}
nav ul ul > li:hover b {
  color: #d5d9e2;
}
.header-dropdown-list {
  list-style: none;
  float: right;
  position: relative;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
}
.header-dropdown-list > li {
  display: inline-block;
  padding-right: 5px;
}
.header-dropdown-list > li > .dropdown-toggle {
  margin-top: 16px;
  display: block;
}
.smart-accordion-default.panel-group {
  margin-bottom: 0;
}
.smart-accordion-default.panel-group .panel + .panel {
  margin-top: -1px;
}
.smart-accordion-default.panel-group .panel-heading {
  padding: 0;
}
.smart-accordion-default.panel-group .panel-title a {
  display: block;
  padding: 10px 15px;
  text-decoration: none !important;
}
.panel-group .panel,
.smart-accordion-default .panel-heading {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}
.smart-accordion-default .panel-default > .panel-heading {
  background-color: #fcfcfc;
}
.smart-accordion-default .panel-default {
  border-color: #c3c3c3;
}
.smart-accordion-default .panel-title > a.collapsed > .fa,
.smart-accordion-default .panel-title > a > :first-child {
  display: none;
}
.smart-accordion-default .panel-title > a.collapsed > :first-child {
  display: inline-block;
}
.no-padding .smart-accordion-default > div {
  border-left: none !important;
  border-right: none !important;
}
.no-padding .smart-accordion-default > div:first-child {
  border-top: none !important;
}
.no-padding .smart-accordion-default > div:last-child {
  border-bottom: none !important;
}
.onoffswitch-container {
  margin-top: 4px;
  margin-left: 7px;
  display: inline-block;
}
.onoffswitch {
  position: relative;
  width: 50px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 5px;
  display: inline-block;
  vertical-align: middle;
}
.onoffswitch-checkbox {
  display: none;
}
.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #626262;
  border-radius: 50px;
  border-color: #adadad #b3b3b3 #9e9e9e;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.onoffswitch-inner {
  width: 200%;
  margin-left: -100%;
  display: block;
}
.onoffswitch-inner:after,
.onoffswitch-inner:before {
  float: left;
  width: 50%;
  height: 15px;
  padding: 0;
  line-height: 15px;
  font-size: 10px;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: 700;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.onoffswitch-inner:before {
  content: attr(data-swchon-text);
  text-shadow: 0 -1px 0 #333;
  padding-left: 7px;
  background-color: #3276b1;
  color: #fff;
  box-shadow: inset 0 2px 6px rgba(0, 0, 0, 0.5), 0 1px 2px rgba(0, 0, 0, 0.05);
  text-align: left;
}
.onoffswitch-inner:after {
  content: attr(data-swchoff-text);
  padding-right: 7px;
  text-shadow: 0 -1px 0 #fff;
  background-color: #fff;
  color: #555;
  text-align: right;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}
.onoffswitch-switch {
  width: 19px;
  height: 19px;
  margin: -2px;
  background: #fff;
  border: 1px solid #9a9a9a;
  border-radius: 50px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 32px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  background-color: #f4f4f4;
  background-image: -moz-linear-gradient(top, #fff, #eee);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fff), to(#eee));
  background-image: -webkit-linear-gradient(top, #fff, #eee);
  background-image: -o-linear-gradient(top, #fff, #eee);
  background-image: linear-gradient(to bottom, #fff, #eee);
  background-repeat: repeat-x;
  -webkit-box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.3);
}
.onoffswitch-checkbox + .onoffswitch-label .onoffswitch-switch:before,
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch:before {
  content: "\f00d";
  color: #a90329;
  display: block;
  text-align: center;
  line-height: 19px;
  font-size: 10px;
  text-shadow: 0 -1px 0 #fff;
  font-weight: 700;
  font-family: FontAwesome;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch:before {
  content: "\f00c";
  color: #57889c;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
  display: block;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0;
}

.onoffswitch-switch:hover {
  background-color: #eee;
}
.onoffswitch-switch:active {
  background-color: #eee;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}
.onoffswitch-checkbox:checked:disabled
  + .onoffswitch-label
  .onoffswitch-inner:before,
.onoffswitch-checkbox:disabled + .onoffswitch-label .onoffswitch-inner:after {
  text-shadow: 0 1px 0 #fff;
  background: #bfbfbf;
  color: #333;
}
.onoffswitch-checkbox:checked:disabled + .onoffswitch-label .onoffswitch-switch,
.onoffswitch-checkbox:disabled + .onoffswitch-label .onoffswitch-switch {
  background-color: #f4f4f4;
  background-image: -moz-linear-gradient(top, #bfbfbf, #f4f4f4);
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    from(#bfbfbf),
    to(#f4f4f4)
  );
  background-image: -webkit-linear-gradient(top, #bfbfbf, #f4f4f4);
  background-image: -o-linear-gradient(top, #bfbfbf, #f4f4f4);
  background-image: linear-gradient(to bottom, #bfbfbf, #f4f4f4);
  box-shadow: none !important;
}
.onoffswitch-checkbox:checked:disabled + .onoffswitch-label .onoffswitch-label,
.onoffswitch-checkbox:disabled + .onoffswitch-label {
  border-color: #ababab #999 #878787 !important;
}
.onoffswitch-checkbox:checked + .onoffswitch-label {
  border-color: #3276b1 #2a6395 #255681;
}
.onoffswitch + span,
.onoffswitch-title {
  display: inline-block;
  vertical-align: middle;
  margin-top: -5px;
}
.form-control {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
}
.form hr {
  margin-left: -13px;
  margin-right: -13px;
  border-color: rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  margin-bottom: 20px;
}
.form fieldset {
  display: block;
  border: none;
  background: rgba(255, 255, 255, 0.9);
  position: relative;
}
fieldset {
  position: relative;
}
.form-actions {
  display: block;
  padding: 13px 14px 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(249, 249, 249, 0.9);
  margin-top: 25px;
  margin-left: -13px;
  margin-right: -13px;
  margin-bottom: -13px;
  text-align: right;
}
.well .form-actions {
  margin-left: -19px;
  margin-right: -19px;
  margin-bottom: -19px;
}
.well.well-lg .form-actions {
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: -24px;
}
.well.well-sm .form-actions {
  margin-left: -9px;
  margin-right: -9px;
  margin-bottom: -9px;
}
.popover-content .form-actions {
  margin: 0 -14px -9px;
  border-radius: 0 0 3px 3px;
  padding: 9px 14px;
}
.no-padding .form .form-actions {
  margin: 0;
  display: block;
  padding: 13px 14px 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(248, 248, 248, 0.9);
  text-align: right;
  margin-top: 25px;
}
.bootstrap-duallistbox-container label,
.form header,
legend {
  display: block;
  padding: 8px 0;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
  background: #fff;
  font-size: 16px;
  font-weight: 300;
  color: #222;
  margin: 25px 0 20px;
}
.no-padding .form header {
  margin: 25px 14px 0;
}
.form header:first-child {
  margin-top: 10px;
}
legend {
  font-weight: 400;
  margin-top: 0;
  background: 0 0;
}
.input-group-addon {
  padding: 6px 10px;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -ms-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
}
.input-group-addon .fa {
  font-size: 14px;
}
.input-group-addon .fa-2x,
.input-group-addon .fa-lg {
  font-size: 2em;
}
.input-group-addon .fa-3x,
.input-group-addon .fa-4x,
.input-group-addon .fa-5x {
  font-size: 30px;
}
input[type="text"]:focus + .input-group-addon {
  border-color: #0091d9;
  background-color: #72a0ce;
  color: #fff;
}
.has-warning input[type="text"],
.has-warning input[type="text"] + .input-group-addon {
  border-color: #dbab57;
}
.has-warning input[type="text"] + .input-group-addon {
  background-color: #faf2cc;
  color: #c09853;
}
.has-warning input[type="text"]:focus,
.has-warning input[type="text"]:focus + .input-group-addon {
  border-color: #dbab57;
}
.has-warning input[type="text"]:focus + .input-group-addon {
  background-color: #e1c118;
  color: #fff;
}
.has-error .input-group-addon {
  border-color: #b94a48;
  background: #f2dede;
  color: #b94a48;
}
.has-error input[type="text"],
.has-error input[type="text"] + .input-group-addon {
  border-color: #b94a48;
}
.has-error input[type="text"] + .input-group-addon {
  background-color: #f2dede;
  color: #b94a48;
}
.has-error input[type="text"]:focus,
.has-error input[type="text"]:focus + .input-group-addon {
  border-color: #c05e5e;
}
.has-error input[type="text"]:focus + .input-group-addon {
  background-color: #c05e5e;
  color: #fff;
}
.has-success input[type="text"]:focus + .input-group-addon {
  border-color: #468847;
  background-color: #468847;
  color: #fff;
}
.form .form-group:last-child,
.form .form-group:last-child .note,
.form fieldset .form-group:last-child,
.form fieldset .form-group:last-child .note {
  margin-bottom: 0;
}
.note {
  margin-top: 6px;
  padding: 0 1px;
  font-size: 11px;
  line-height: 15px;
  color: #999;
}
.input-icon-right {
  position: relative;
}
.input-icon-left > i,
.input-icon-right > i {
  position: absolute;
  right: 10px;
  top: 30%;
  font-size: 16px;
  color: #bfbfbf;
}
.input-icon-left > i {
  right: auto;
  left: 24px;
}
.input-icon-right .form-control {
  padding-right: 27px;
}
.input-icon-left .form-control {
  padding-left: 29px;
}
input[type="color"].ui-autocomplete-loading,
input[type="date"].ui-autocomplete-loading,
input[type="datetime-local"].ui-autocomplete-loading,
input[type="datetime"].ui-autocomplete-loading,
input[type="email"].ui-autocomplete-loading,
input[type="month"].ui-autocomplete-loading,
input[type="number"].ui-autocomplete-loading,
input[type="password"].ui-autocomplete-loading,
input[type="search"].ui-autocomplete-loading,
input[type="tel"].ui-autocomplete-loading,
input[type="text"].ui-autocomplete-loading,
input[type="time"].ui-autocomplete-loading,
input[type="url"].ui-autocomplete-loading,
input[type="week"].ui-autocomplete-loading {
  background-repeat: no-repeat;
  background-position: 99% 50%;
  padding-right: 27px;
}
.input-group-addon .checkbox,
.input-group-addon .radio {
  min-height: 0;
  margin-right: 0 !important;
  padding-top: 0;
}
.input-group-addon label input[type="checkbox"].checkbox + span,
.input-group-addon label input[type="checkbox"].checkbox + span:before,
.input-group-addon label input[type="radio"].radiobox + span,
.input-group-addon label input[type="radio"].radiobox + span:before {
  margin-right: 0;
}
.input-group-addon .onoffswitch,
.input-group-addon .onoffswitch-label {
  margin: 0;
}
.timeline-seperator {
  display: block;
  text-align: center;
  color: #999;
  margin: 20px 0 0;
}
.timeline-seperator > :first-child {
  padding: 4px 5px;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  background: #fff;
  font-size: 11px;
  border-radius: 4px;
}
.timeline-seperator .btn,
.timeline-seperator .btn + .dropdown-menu {
  margin-right: 15px;
}
.timeline-seperator:after {
  display: block;
  content: " ";
  margin: 0 20px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
  margin-top: -8px;
}
.alert {
  margin-bottom: 20px;
  margin-top: 0;
  color: #675100;
  border-width: 0;
  border-left-width: 5px;
  padding: 10px;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}
.alert .close {
  top: 0;
  right: -5px;
  line-height: 20px;
}
.alert-heading {
  font-weight: 600;
}
.alert-danger {
  border-color: #953b39;
  color: #fff;
  background-color: #c26565;
  text-shadow: none;
}
.alert-warning {
  border-color: #dfb56c;
  color: #826430;
  background-color: #efe1b3;
}
.alert-success {
  border-color: #8ac38b;
  color: #356635;
  background-color: #cde0c4;
}
.alert-info {
  border-color: #9cb4c5;
  color: #305d8c;
  background-color: #d6dde7;
}
.well {
  background-color: #fbfbfb;
  border: 1px solid #ddd;
  box-shadow: 0 1px 1px #ececec;
  -webkit-box-shadow: 0 1px 1px #ececec;
  -moz-box-shadow: 0 1px 1px #ececec;
  position: relative;
}
.well.well-clean {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-color: transparent;
}
.well.well-glass {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-color: transparent;
  background-color: rgba(255, 255, 255, 0.25);
  color: #fff;
}
.well.well-light {
  background: #fff;
  border: 1px solid #e2e2e2;
}
.widget-body .well {
  margin-bottom: 0;
}
.well.transparent {
  border: none !important;
  background: none !important;
}
.jarviswidget.well.transparent > div,
.jarviswidget.well.transparent > div .widget-body {
  padding: 0 !important;
}
.well[class*=" bg-"],
.well[class^="bg-"] {
  border: 1px solid #555 !important;
}
.nav-tabs > li > a .badge {
  font-size: 11px;
  padding: 3px 5px;
  opacity: 0.5;
  margin-left: 5px;
  min-width: 17px;
  font-weight: 400;
}
.nav-tabs > li > a > .fa {
  opacity: 0.5;
}
.tabs-left .nav-tabs > li > a .badge {
  margin-right: 5px;
  margin-left: 0;
}
.nav-tabs > li > a .label {
  display: inline-block;
  font-size: 11px;
  margin-left: 5px;
  opacity: 0.5;
}
.nav-tabs > li.active > a .badge,
.nav-tabs > li.active > a .label,
.nav-tabs > li.active > a > .fa {
  opacity: 1;
}
.nav-tabs > li > a {
  border-radius: 0;
  color: #333;
}
.nav-tabs > li.active > a {
  -webkit-box-shadow: 0 -2px 0 #57889c;
  -moz-box-shadow: 0 -2px 0 #57889c;
  box-shadow: 0 -2px 0 #57889c;
  border-top-width: 0 !important;
  margin-top: 1px !important;
  font-weight: 700;
}
.tabs-left .nav-tabs > li.active > a {
  -webkit-box-shadow: -2px 0 0 #57889c;
  -moz-box-shadow: -2px 0 0 #57889c;
  box-shadow: -2px 0 0 #57889c;
  border-top-width: 1px !important;
  border-left: none !important;
  margin-left: 1px !important;
}
.tabs-left .nav-pills > li.active > a {
  border: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
}
.tabs-right .nav-tabs > li.active > a {
  -webkit-box-shadow: 2px 0 0 #57889c;
  -moz-box-shadow: 2px 0 0 #57889c;
  box-shadow: 2px 0 0 #57889c;
  border-top-width: 1px !important;
  border-right: none !important;
  margin-right: 1px !important;
}
.tabs-below .nav-tabs > li.active > a {
  -webkit-box-shadow: 0 2px 0 #57889c;
  -moz-box-shadow: 0 2px 0 #57889c;
  box-shadow: 0 2px 0 #57889c;
  border-bottom-width: 0 !important;
  border-top: none !important;
  margin-top: 0 !important;
}
.tabs-left > .nav-pills > li,
.tabs-left > .nav-tabs > li,
.tabs-right > .nav-pills > li,
.tabs-right > .nav-tabs > li {
  float: none;
}
.tabs-left > .nav-pills > li > a,
.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-pills > li > a,
.tabs-right > .nav-tabs > li > a {
  min-width: 74px;
  margin-right: 0;
  margin-bottom: 3px;
}
.tabs-left > .nav-pills,
.tabs-left > .nav-tabs {
  float: left;
  margin-right: 19px;
  border-right: 1px solid #ddd;
}
.tabs-left > .nav-pills {
  border-right: none;
}
.tabs-left > .nav-tabs > li > a {
  margin-right: -1px;
}
.tabs-left > .nav-tabs > li > a:focus,
.tabs-left > .nav-tabs > li > a:hover {
  border-color: #eee #d5d5d5 #eee #eee;
}
.tabs-left > .nav-tabs .active > a,
.tabs-left > .nav-tabs .active > a:focus,
.tabs-left > .nav-tabs .active > a:hover {
  border-color: #d5d5d5 transparent #d5d5d5 #ddd;
  border-right-color: #fff;
}
.tabs-left > .tab-content {
  margin-left: 109px;
}
.tabs-right > .nav-tabs {
  float: right;
  margin-left: 19px;
  border-left: 1px solid #ddd;
}
.tabs-right > .nav-tabs > li > a {
  margin-left: -1px;
}
.tabs-right > .nav-tabs > li > a:focus,
.tabs-right > .nav-tabs > li > a:hover {
  border-color: #eee #eee #eee #ddd;
}
.tabs-right > .nav-tabs .active > a,
.tabs-right > .nav-tabs .active > a:focus,
.tabs-right > .nav-tabs .active > a:hover {
  border-color: #ddd #ddd #ddd transparent;
  border-left-color: #fff;
}
.tabs-below > .nav-tabs,
.tabs-left > .nav-tabs,
.tabs-right > .nav-tabs {
  border-bottom: 0;
}
.pill-content > .pill-pane,
.tab-content > .tab-pane {
  display: none;
}
.pill-content > .active,
.tab-content > .active {
  display: block;
}
.tabs-below > .nav-tabs {
  border-top: 1px solid #ddd;
}
.tabs-below > .nav-tabs > li {
  margin-top: -1px;
  margin-bottom: 0;
}
.tabs-below > .nav-tabs > li > a:focus,
.tabs-below > .nav-tabs > li > a:hover {
  border-top-color: #ddd;
  border-bottom-color: transparent;
}
.tabs-below > .nav-tabs > .active > a,
.tabs-below > .nav-tabs > .active > a:focus,
.tabs-below > .nav-tabs > .active > a:hover {
  border-color: transparent #ddd #ddd;
}
.nav-tabs.bordered {
  background: #fff;
  border: 1px solid #ddd;
}
.nav-tabs.bordered > :first-child a {
  border-left-width: 0 !important;
}
.nav-tabs.bordered + .tab-content {
  border: 1px solid #ddd;
  border-top: none;
}
.tabs-pull-right.nav-pills > li,
.tabs-pull-right.nav-tabs > li {
  float: right;
}
.tabs-pull-right.nav-pills > li:first-child > a,
.tabs-pull-right.nav-tabs > li:first-child > a {
  margin-right: 1px;
}
.tabs-pull-right.bordered.nav-pills > li:first-child > a,
.tabs-pull-right.bordered.nav-tabs > li:first-child > a {
  border-left-width: 1px !important;
  margin-right: 0;
  border-right-width: 0;
}
.jarviswidget > header > .nav-tabs.pull-left > li:first-child a {
  border-left-width: 0 !important;
}
.btn,
a:link,
button {
  -webkit-tap-highlight-color: rgba(169, 3, 41, 0.5);
}
#logo-group *,
.login-info,
.login-info *,
.minified .menu-item-parent {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.hidden-desktop {
  display: none !important;
}
.dropdown-menu-xs {
  min-width: 37px;
}
.dropdown-menu-xs > li > a {
  padding: 3px 10px;
}
.dropdown-menu-xs > li > a:hover i {
  color: #fff !important;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
}
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #bfbfbf;
  margin-top: 5px;
  margin-right: -10px;
}
.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}
.dropdown-submenu.pull-left {
  float: none;
}
.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
}
.table.has-tickbox thead tr th:first-child {
  width: 18px;
}
.table.has-tickbox.smart-form tbody tr td:first-child .checkbox,
.table.has-tickbox.smart-form tbody tr td:first-child .radio,
.table.has-tickbox.smart-form thead tr th:first-child .checkbox,
.table.has-tickbox.smart-form thead tr th:first-child .radio {
  padding: 0 !important;
}
.fc-border-separate thead tr,
.table thead tr {
  background-color: #eee;
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    from(#f2f2f2),
    to(#fafafa)
  );
  background-image: -webkit-linear-gradient(top, #f2f2f2 0, #fafafa 100%);
  background-image: -moz-linear-gradient(top, #f2f2f2 0, #fafafa 100%);
  background-image: -ms-linear-gradient(top, #f2f2f2 0, #fafafa 100%);
  background-image: -o-linear-gradient(top, #f2f2f2 0, #fafafa 100%);
  background-image: -linear-gradient(top, #f2f2f2 0, #fafafa 100%);
  font-size: 12px;
}
.fc-border-separate thead tr th {
  padding: 4px;
  line-height: 1.428571429;
}
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border-width: 1px;
}
.no-padding > .table-bordered,
.no-padding > .table-responsive > .table-bordered {
  border: none !important;
}
.no-padding > .table-bordered tbody tr td:first-child,
.no-padding > .table-bordered thead tr td:first-child,
.no-padding > .table-bordered thead tr th:first-child,
.no-padding > .table-responsive > .table-bordered tbody tr td:first-child,
.no-padding > .table-responsive > .table-bordered thead tr td:first-child,
.no-padding > .table-responsive > .table-bordered thead tr th:first-child {
  border-left-width: 0 !important;
}
.no-padding > .table-bordered tbody tr td:last-child,
.no-padding > .table-bordered thead tr th:last-child,
.no-padding > .table-responsive > .table-bordered tbody tr td:last-child,
.no-padding > .table-responsive > .table-bordered thead tr th:last-child {
  border-right-width: 0 !important;
}
.no-padding > .table-bordered tbody tr:last-child td,
.no-padding > .table-responsive > .table-bordered tbody tr:last-child td {
  border-bottom-width: 0 !important;
}
.no-padding .table-responsive .table {
  margin-bottom: 0;
}
.no-padding .note-editable .table-bordered {
  border: 1px solid #ddd !important;
}
.smart-form.table td .checkbox i,
.smart-form.table td .radio i,
.smart-form.table th .checkbox i,
.smart-form.table th .radio i {
  top: 0 !important;
}
.table-condensed.table > tbody > tr > td,
.table-condensed.table > tbody > tr > th,
.table-condensed.table > tfoot > tr > td,
.table-condensed.table > tfoot > tr > th,
.table-condensed.table > thead > tr > td,
.table-condensed.table > thead > tr > th {
  padding: 5px 10px !important;
}
.show-stats .progress {
  height: 7px;
  border-radius: 0;
  margin-top: 3px;
  margin-bottom: 15px;
}
.show-stats {
  padding-top: 6px !important;
}
.show-stat-buttons {
  float: left;
  width: 100%;
}
.show-stats .show-stat-buttons > :first-child {
  padding-right: 5px;
}
.show-stats .show-stat-buttons > :last-child {
  padding-left: 5px;
}
.show-stat-microcharts {
  margin-left: -10px;
  margin-right: -10px;
}
.show-stat-microcharts > div {
  border-right: 1px solid #dadada !important;
  border-top: 1px solid #dadada !important;
  margin-top: 10px !important;
  height: 65px;
  overflow: hidden;
  padding: 7px 9px;
}
.show-stat-microcharts > :last-child {
  border-right: none !important;
}
.show-stat-microcharts .sparkline {
  margin-top: 10px;
  margin-right: 8px;
  opacity: 0.7;
}
.show-stat-microcharts .sparkline:hover {
  opacity: 1;
}
.smaller-stat {
  margin: 0;
  padding: 0;
  list-style: none;
}
.smaller-stat li {
  margin-top: 3px;
  margin-right: 0;
}
.smaller-stat li:first-child {
  margin-top: 8px;
}
.smaller-stat span.label {
  width: 40px;
  display: block;
  text-align: left;
  opacity: 0.5;
  cursor: default;
}
.smaller-stat span.label:hover {
  opacity: 1;
}
.login-info {
  display: block;
  font-size: 12px;
  height: 39px;
  color: #fff;
  border: solid transparent;
  border-width: 1px 0;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1),
    inset 0 -1px 0 rgba(0, 0, 0, 0.07);
  width: 100%;
  margin: 0 !important;
  border-bottom: 1px solid #525151;
}
.login-info a {
  text-decoration: none !important;
  color: #c0bbb7;
  display: inline-block;
  margin-top: 6px;
}
.login-info a span {
  text-transform: capitalize;
  font-size: 14px;
  display: inline-block;
  text-decoration: none;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.login-info a span + i {
  vertical-align: middle;
}
.login-info a:hover {
  color: #fff;
}
.login-info > span {
  height: 38px;
  display: block;
  padding: 0 10px;
  border-bottom: 1px solid #1a1817;
}
.login-info img {
  width: 25px;
  height: auto;
  display: inline-block;
  vertical-align: middle;
  margin-top: 1px;
  margin-right: 5px;
  margin-left: 0;
  border-left: 3px solid #fff;
}
img.away,
img.busy,
img.offline,
img.online {
  border-left: 3px solid #fff;
}
img.online {
  border-left-color: #40ac2b !important;
}
img.busy {
  border-left-color: #ed1c24 !important;
}
img.offline {
  border-left-color: #ddd !important;
}
img.away {
  border-left-color: #ffc40d !important;
}
.client-form header {
  padding: 15px 13px;
  margin: 0;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.1);
  background: rgba(248, 248, 248, 0.9);
}
.minified .inbox-badge {
  border-radius: 50%;
  font-size: 9px;
  padding: 2px;
  position: absolute;
  top: 6px;
  right: 6px;
  background: #ed1c24;
  min-width: 13px;
}
.minifyme {
  border-bottom: 1px solid #302f2f;
  background: #454545;
  color: #a8a8a8;
  text-align: center;
  padding: 0;
  width: 36px;
  height: 28px;
  display: block;
  position: absolute;
  right: 0;
  border-radius: 5px 0 0 5px;
  cursor: pointer;
  margin-top: 7px;
  font-size: 19px;
  transition: all 0.1s linear 0s;
}
.minifyme:hover {
  background: #646464;
  color: #e4e4e4;
  right: 0;
  width: 40px;
  padding-right: 5px;
}
.minified .minifyme {
  right: 0;
  color: #a8a8a8;
  position: relative;
  width: 100%;
  border-radius: 0;
  margin: 0;
  height: 34px;
  font-size: 23px;
}
.minified .minifyme .fa:before {
  content: "\f0a9";
}
.minified .login-info a i,
.minified .login-info a span {
  display: none;
}
.minified .login-info span {
  padding: 0 5px;
  text-align: center;
}
.minified .login-info img {
  margin: -2px 0 0;
  width: 30px;
}
.minified .login-info {
  height: 38px;
  border-bottom: 1px solid #181818;
}
.minified #left-panel {
  width: 45px;
}
.minified #main {
  margin-left: 45px;
}
.minified #left-panel,
.minified nav > ul > li {
  overflow: visible;
}
.minified nav > ul > ul {
  min-height: 100px !important;
  max-height: 180px !important;
  overflow-y: scroll;
  overflow-x: hidden;
  background: #ed1c24 !important;
}
.minified nav > ul > li,
.minified nav > ul > li a {
  position: relative;
}
.minified nav ul > li > a {
  padding: 10px 11px;
  display: block;
}
.minified nav > ul > li > a .fa.fa-fw {
  display: block;
  width: auto;
  text-align: center;
  padding: 0;
}
.minified nav > ul > li > a > i {
  text-align: center;
  margin: 0;
  display: block;
}
.minified nav > ul > li.active > a::before {
  content: "\f0d9";
}
.minified nav ul ul li a {
  padding-left: 14px;
}
.minified nav ul ul li li a {
  padding-left: 25px;
}
.minified nav > ul ul li::before,
.minified nav > ul > li > ul::before {
  left: 12px;
}
.minified nav > ul > li > a .menu-item-parent,
.minified nav > ul > li > a > b {
  display: none;
}
.minified nav > ul > li > a > .menu-item-parent {
  display: none;
  position: absolute;
  left: 40px;
  top: -3px;
  width: 186px;
  height: 38px;
  line-height: 38px;
  background-color: #f5f5f5;
  color: #333;
  z-index: 3;
  -webkit-box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.2);
  border-left: 1px solid #bfbfbf;
  border-top: 1px solid #d8d4d4;
  border-bottom: 1px solid #fff;
  padding-left: 12px;
}
.minified nav > ul > li > ul {
  display: none !important;
  position: absolute;
  left: 40px;
  width: 199px;
  z-index: 5;
  border: 1px solid #bfbfbf;
  min-height: 180px;
  -webkit-box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.2);
  background: #454545;
  margin-top: -3px;
  overflow: hidden;
}
.minified nav ul > li > ul > li > ul {
  position: relative;
  left: 13px;
  border: none;
  margin-left: -12px;
  width: 197px;
}
.minified nav > ul > li:hover > a > .menu-item-parent,
.minified nav > ul > li:hover > ul {
  display: block !important;
}
.minified nav > ul > li > ul > li {
  background-color: rgba(69, 69, 69, 0.9);
}
.minified nav > ul > li > ul > li > ul > li {
  background-color: #484b50;
}
.minified nav > ul > li {
  border-bottom: 1px solid #1a1817;
  border-top: 1px solid #525151;
}
.minified nav > ul > li > ul::before {
  border-left: none;
}
.minified nav > ul ul li::before {
  border: none;
}
.slimScrollDiv,
.slimScrollDiv > :first-child {
  overflow: hidden;
}
.minified .slimScrollDiv,
.minified .slimScrollDiv > :first-child {
  overflow: visible !important;
}
.minified .slimScrollBar,
.minified .slimScrollRail {
  display: none;
}
.minified nav > ul > li > ul > li > a,
.minified nav > ul > li > ul > li > ul > li > a {
  padding-top: 7px;
  padding-bottom: 7px;
}
.minified nav > ul > li > ul > li > ul > li a {
  padding-top: 6px;
  padding-bottom: 6px;
}
.minified nav ul ul ul ul li a {
  padding-left: 45px;
}
.minified nav ul ul ul ul ul li a {
  padding-left: 62px;
}
.minified nav ul ul ul ul ul ul li a {
  padding-left: 82px;
}
.pagination.pagination-alt > li > a {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border: none;
  margin-left: -1px;
}
.pagination.pagination-alt > li:first-child > a {
  padding-left: 0;
}
.pagination > li > a,
.pagination > li > span {
  box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.05);
}
.disabled {
  color: #fff;
}
.btn-default.disabled {
  color: #999;
}
.btn {
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.05);
}
.btn:active {
  position: relative;
  top: 1px;
  left: 1px;
}
.btn.btn-ribbon {
  background-color: #707070;
  background-image: -moz-linear-gradient(top, #777, #666);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#777), to(#666));
  background-image: -webkit-linear-gradient(top, #777, #666);
  background-image: -o-linear-gradient(top, #777, #666);
  background-image: linear-gradient(to bottom, #777, #666);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff777777', endColorstr='#ff666666', GradientType=0);
  color: #fff;
  padding: 0 5px;
  line-height: 20px;
  vertical-align: middle;
  height: 20px;
  display: block;
  border: none;
  float: left;
  margin: 0 8px 0 0;
  cursor: pointer;
}
.btn.btn-ribbon > i {
  font-size: 111%;
}
.ribbon-button-alignment {
  padding-top: 10px;
  display: inline-block;
}
.ribbon-button-alignment.pull-right > .btn.btn-ribbon {
  margin: 0 0 0 8px;
}
.panel-purple {
  border-color: #6e587a;
}
.panel-purple > .panel-heading {
  color: #fff;
  background-color: #6e587a;
  border-color: #6e587a;
}
.panel-greenLight {
  border-color: #71843f;
}
.panel-greenLight > .panel-heading {
  color: #fff;
  background-color: #71843f;
  border-color: #71843f;
}
.panel-greenDark {
  border-color: #496949;
}
.panel-greenDark > .panel-heading {
  color: #fff;
  background-color: #496949;
  border-color: #496949;
}
.panel-darken {
  border-color: #333;
}
.panel-darken > .panel-heading {
  color: #fff;
  background-color: #404040;
  border-color: #404040;
}
.panel-pink {
  border-color: #ac5287;
}
.panel-pink > .panel-heading {
  color: #fff;
  background-color: #ac5287;
  border-color: #ac5287;
}
.panel-green {
  border-color: #356e35;
}
.panel-green > .panel-heading {
  color: #fff;
  background-color: #356e35;
  border-color: #356e35;
}
.panel-blueLight {
  border-color: #92a2a8;
}
.panel-blueLight > .panel-heading {
  color: #fff;
  background-color: #92a2a8;
  border-color: #92a2a8;
}
.panel-pinkDark {
  border-color: #a8829f;
}
.panel-pinkDark > .panel-heading {
  color: #fff;
  background-color: #a8829f;
  border-color: #a8829f;
}
.panel-redLight {
  border-color: #a65858;
}
.panel-redLight > .panel-heading {
  color: #fff;
  background-color: #a65858;
  border-color: #a65858;
}
.panel-red {
  border-color: #a90329;
}
.panel-red > .panel-heading {
  color: #fff;
  background-color: #a90329;
  border-color: #a90329;
}
.panel-teal {
  border-color: #568a89;
}
.panel-teal > .panel-heading {
  color: #fff;
  background-color: #568a89;
  border-color: #568a89;
}
.panel-orange {
  border-color: #c79121;
}
.panel-orange > .panel-heading {
  color: #fff;
  background-color: #c79121;
  border-color: #c79121;
}
.panel-blueDark {
  border-color: #4c4f53;
}
.panel-blueDark > .panel-heading {
  color: #fff;
  background-color: #4c4f53;
  border-color: #4c4f53;
}
.panel-magenta {
  border-color: #6e3671;
}
.panel-magenta > .panel-heading {
  color: #fff;
  background-color: #6e3671;
  border-color: #6e3671;
}
.panel-blue {
  border-color: #57889c;
}
.panel-blue > .panel-heading {
  color: #fff;
  background-color: #57889c;
  border-color: #57889c;
}
.panel-footer > .btn-block {
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-bottom: none;
  border-left: none;
  border-right: none;
}
.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 18px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}
.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 15px;
  font-size: 18px;
  line-height: 30px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 15px;
  font-size: 24px;
  line-height: 50px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}
.btn-metro {
  margin: 0 0 20px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.btn-metro > span {
  display: block;
  vertical-align: bottom;
  margin-top: 10px;
  text-transform: uppercase;
}
.btn-metro > span.label {
  position: absolute;
  top: 0;
  right: 0;
}
.btn-label {
  position: relative;
  left: -12px;
  display: inline-block;
  padding: 6px 12px;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 3px 0 0 3px;
}
.btn-labeled {
  padding-top: 0;
  padding-bottom: 0;
}
.btn-link {
  box-shadow: none;
  -webkit-box-shadow: none;
  font-size: 14px;
}
.nav-demo-btn {
  background-color: rgba(77, 118, 134, 0.5);
  border-color: #3a7775;
}
.nav-demo-btn:active,
.nav-demo-btn:hover {
  background-color: rgba(77, 118, 134, 0.7);
  border-color: #3a7775;
}
#left-panel {
  -webkit-transition: 250ms cubic-bezier(0.1, 0.57, 0.1, 1);
  transition: 250ms cubic-bezier(0.1, 0.57, 0.1, 1);
  -webkit-transform: translate(0px, 0) translateZ(0px);
}
.chart-large,
.chart-small,
.chart-xl {
  overflow: hidden;
}
.chart-small {
  height: 100px;
  width: 100%;
}
.chart-large {
  height: 235px;
  width: 100%;
}
.chart-xl {
  height: 297px;
  width: 100%;
}
#flotTip {
  padding: 3px 5px;
  background-color: #fff;
  z-index: 9999;
  color: #333;
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  border: 1px solid #c1c1c1;
  -moz-border-radius: 4px;
  -khtml-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
#flotTip span {
  color: #38812d;
  font-weight: 700;
}
.legendLabel span {
  display: block;
  margin: 0 5px;
}
.legendColorBox {
  padding-left: 10px;
  vertical-align: top;
  padding-top: 5px;
}
.legendColorBox div > div {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
}
.morris-hover.morris-default-style {
  border-radius: 10px;
  padding: 6px;
  color: #666;
  background: rgba(255, 255, 255, 0.8);
  border: solid 2px rgba(230, 230, 230, 0.8);
  font-family: sans-serif;
  font-size: 12px;
  text-align: center;
}
.morris-hover.morris-default-style .morris-hover-row-label {
  font-weight: 700;
  margin: 0.25em 0;
}
.morris-hover.morris-default-style .morris-hover-point {
  white-space: nowrap;
  margin: 0.1em 0;
}
.morris-hover {
  position: absolute;
  z-index: 903;
}
.fixed-page-footer .morris-hover {
  z-index: 900;
}
.chart {
  overflow: hidden;
  height: 220px;
  margin: 20px 5px 10px 10px;
}
.has-legend {
  margin-top: 30px !important;
}
.has-legend-unique {
  margin-top: 19px !important;
}
.icon-color-good {
  color: #40ac2b;
}
.icon-color-bad {
  color: #ed1c24;
}
.degree-sign:after,
.percent-sign:after {
  content: " %";
  line-height: normal;
}
.degree-sign:after {
  content: "°F";
}
.hidden-menu #left-panel {
  left: -210px;
  z-index: 903;
}
.hidden-menu #main {
  margin-left: 10px;
}
.hidden-menu #left-panel:hover {
  left: 0;
}
.hidden-menu .minifyme,
.minified #hide-menu {
  display: none;
}
.btn-header.pull-right {
  margin-left: 6px;
}
.btn-header a > span {
  font-size: 13px;
  font-weight: 400;
  line-height: 30px;
  height: 30px;
  display: inline-block;
}
.btn-header > :first-child > a {
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  cursor: default !important;
  display: inline-block;
  font-weight: 700;
  height: 30px;
  line-height: 24px;
  min-width: 30px;
  padding: 2px;
  text-align: center;
  text-decoration: none !important;
  -moz-user-select: none;
  -webkit-user-select: none;
  background-color: #f8f8f8;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f8f8f8),
    to(#f1f1f1)
  );
  background-image: -webkit-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -moz-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -ms-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -o-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: linear-gradient(to bottom, #f8f8f8, #f1f1f1);
  border: 1px solid #bfbfbf;
  color: #6d6a69;
  font-size: 17px;
  margin: 10px 0 0;
}
.btn-header > :first-child > a:hover {
  border: 1px solid #bfbfbf;
  color: #222;
  transition: all 0s;
  cursor: pointer;
  -webkit-box-shadow: inset 0 0 4px 1px rgba(0, 0, 0, 0.08);
  box-shadow: inset 0 0 4px 1px rgba(0, 0, 0, 0.08);
}
.btn-header > :first-child > a:active {
  background-color: #e8e8e8;
  background-image: -moz-linear-gradient(top, #e8e8e8 0, #ededed 100%);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #e8e8e8),
    color-stop(100%, #ededed)
  );
  background-image: -webkit-linear-gradient(top, #e8e8e8 0, #ededed 100%);
  background-image: -o-linear-gradient(top, #e8e8e8 0, #ededed 100%);
  background-image: -ms-linear-gradient(top, #e8e8e8 0, #ededed 100%);
  background-image: linear-gradient(to bottom, #e8e8e8 0, #ededed 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e8e8e8', endColorstr='#ededed', GradientType=0);
  -webkit-box-shadow: inset 0 0 3px 1px rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 0 3px 1px rgba(0, 0, 0, 0.15);
}
.hidden-menu #hide-menu > :first-child > a {
  background-color: #e8e8e8;
  background-image: -moz-linear-gradient(top, #5a5a5a 0, #686868 100%);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #5a5a5a),
    color-stop(100%, #686868)
  );
  background-image: -webkit-linear-gradient(to bottom, #5a5a5a 0, #686868 100%);
  background-image: -o-linear-gradient(to bottom, #5a5a5a 0, #686868 100%);
  background-image: -ms-linear-gradient(to bottom, #5a5a5a 0, #686868 100%);
  background-image: linear-gradient(to bottom, #5a5a5a 0, #686868 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5A5A5A', endColorstr='#686868', GradientType=0);
  -webkit-box-shadow: inset 0 0 3px 1px rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 0 3px 1px rgba(0, 0, 0, 0.15);
  color: #fff;
  border-color: #494949;
}
.full-screen #fullscreen > :first-child > a:active,
.hidden-menu #hide-menu > :first-child > a:active {
  -webkit-box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.2);
}
.ajax-dropdown > :first-child {
  margin: 0 0 3px;
  padding: 0 0 9px;
}
.ajax-dropdown {
  position: absolute;
  display: none;
  z-index: 905;
  top: 48px;
  left: 16px;
  width: 344px;
  height: 435px;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -webkit-box-shadow: 0 2px 4px rgba(30, 30, 100, 0.25);
  -moz-box-shadow: 0 2px 4px rgba(30, 30, 100, 0.25);
  box-shadow: 0 2px 4px rgba(30, 30, 100, 0.25);
  padding: 10px;
  background: #fff;
  border: 1px solid #b3b3b3;
}
.ajax-dropdown:after,
.ajax-dropdown:before {
  bottom: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ajax-dropdown:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 7px;
  left: 50%;
  margin-left: -7px;
}
.ajax-dropdown:before {
  border-color: rgba(131, 131, 131, 0);
  border-bottom-color: #838383;
  border-width: 8px;
  left: 50%;
  margin-left: -8px;
}
.ajax-dropdown .btn-group .btn {
  font-weight: 700;
  text-transform: capitalize;
}
.ajax-dropdown .btn-group > :nth-child(2) {
  border-right-width: 0;
  border-left-width: 0;
}
.ajax-dropdown .btn-group > :last-child {
  border-right-width: 1px !important;
}
.ajax-dropdown .btn-group .btn:active {
  top: 0;
  left: 0;
}
.ajax-notifications {
  height: 365px;
  display: block;
  overflow: auto;
  margin-right: -10px;
  margin-left: -10px;
  border-bottom: 1px solid #cecece;
  border-top: 1px solid #cecece;
  background: #e9e9e9;
}
.ajax-notifications .alert.alert-transparent {
  background-color: transparent;
  border-color: transparent;
  color: #757575;
  margin: 13px 10px;
}
.ajax-notifications .alert > :first-child {
  margin-bottom: 10px;
  font-weight: 400;
}
.ajax-dropdown > :last-child {
  font-size: 13px;
  display: block;
  padding: 5px 0;
  line-height: 22px;
  font-weight: 400;
}
.ajax-dropdown .fa-4x.fa-border {
  border-width: 3px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  display: block;
  margin: 0 auto;
  width: 46px;
  text-align: center;
  color: #d1d1d1;
  border-color: #d1d1d1;
}
.dropdown-large {
  position: static !important;
}
.dropdown-menu-large {
  margin-left: 16px;
  margin-right: 16px;
  padding: 20px 0;
}
.dropdown-menu-large > li > ul {
  padding: 0;
  margin: 0;
}
.dropdown-menu-large > li > ul > li {
  list-style: none;
}
.dropdown-menu-large > li > ul > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.428571429;
  color: #333;
  white-space: normal;
}
.dropdown-menu-large > li ul > li > a:focus,
.dropdown-menu-large > li ul > li > a:hover {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5;
}
.dropdown-menu-large .disabled > a,
.dropdown-menu-large .disabled > a:focus,
.dropdown-menu-large .disabled > a:hover {
  color: #999;
}
.dropdown-menu-large .disabled > a:focus,
.dropdown-menu-large .disabled > a:hover {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  cursor: not-allowed;
}
.dropdown-menu-large .dropdown-header {
  color: #428bca;
  font-size: 18px;
}
@media (max-width: 768px) {
  .dropdown-menu-large {
    margin-left: 0;
    margin-right: 0;
  }
  .dropdown-menu-large > li {
    margin-bottom: 30px;
  }
  .dropdown-menu-large > li:last-child {
    margin-bottom: 0;
  }
  .dropdown-menu-large .dropdown-header {
    padding: 3px 15px !important;
  }
}
.pagination-xs > li > a,
.pagination-xs > li > span {
  padding: 4px 9px;
  font-size: 12px;
}
.notification-body *,
.notification-body:after *,
.notification-body:before * {
  box-sizing: border-box !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
}
.notification-body {
  padding: 0 0 0 3px;
  margin: 0;
  list-style: none;
}
.notification-body > li {
  border-bottom: 1px solid #e9e9e9;
  position: relative;
}
.notification-body > li > span {
  background: #fff;
  display: block;
  min-height: 25px;
  overflow: hidden;
  padding: 8px 10px 13px;
  white-space: normal;
}
.notification-body > li > span:hover {
  background: #f0f4f7;
  color: #667f8f;
}
.notification-body > li a.msg {
  padding-left: 50px !important;
}
.notification-body > li a,
.notification-body > li a span {
  display: block;
  position: relative;
  overflow: hidden;
}
.notification-body > li a,
.notification-body > li a:hover {
  text-decoration: none;
}
.notification-body > li:hover .progress {
  background: #fff;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0), 0 0 0 1px #a7a7a7 inset;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0), 0 0 0 1px #ccc inset;
  -moz-box-shadow: 0 1px 0 transparent, 0 0 0 1px #fff inset;
}
.notification-body > li:hover .text-muted {
  color: #333;
}
.notification-body .from {
  font-size: 14px;
  line-height: normal;
  color: #333;
  font-weight: 400;
  margin-right: 60px;
  margin-bottom: 6px;
  height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.notification-body .unread .from {
  font-weight: 700;
}
.notification-body .unread {
  background: #ffffe0;
}
.notification-body time {
  position: absolute;
  top: 3px;
  right: 0;
  font-size: 11px;
  font-weight: 400;
  color: #058dc7;
}
.notification-body .msg-body,
.notification-body .subject {
  font-size: 13px;
  color: #a0a0a0;
  max-height: 35px;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.notification-body .subject {
  font-size: 13px;
  color: #333;
  max-height: 22px;
}
.bootstrapWizard {
  display: block;
  list-style: none;
  padding: 0;
  position: relative;
  width: 100%;
}
.bootstrapWizard a:active,
.bootstrapWizard a:focus,
.bootstrapWizard a:hover {
  text-decoration: none;
}
.bootstrapWizard li {
  display: block;
  float: left;
  width: 25%;
  text-align: center;
  padding-left: 0;
}
.bootstrapWizard li:before {
  border-top: 3px solid #55606e;
  content: "";
  display: block;
  font-size: 0;
  overflow: hidden;
  position: relative;
  top: 11px;
  right: 1px;
  width: 100%;
  z-index: 1;
}
.bootstrapWizard li:first-child:before {
  left: 50%;
  max-width: 50%;
}
.bootstrapWizard li:last-child:before {
  max-width: 50%;
  width: 50%;
}
.bootstrapWizard li.complete .step {
  background: #0aa66e;
  padding: 1px 6px;
  border: 3px solid #55606e;
}
.bootstrapWizard li .step i {
  font-size: 10px;
  font-weight: 400;
  position: relative;
  top: -1.5px;
}
.bootstrapWizard li .step {
  background: #b2b5b9;
  color: #fff;
  display: inline;
  font-size: 15px;
  font-weight: 700;
  padding: 7px 13px;
  border: 3px solid transparent;
  border-radius: 50%;
  line-height: normal;
  position: relative;
  text-align: center;
  z-index: 2;
  transition: all 0.1s linear 0s;
}
.bootstrapWizard li.active .step,
.bootstrapWizard li.active.complete .step {
  background: #0091d9;
  color: #fff;
  font-weight: 700;
  padding: 7px 13px;
  font-size: 15px;
  border-radius: 50%;
  border: 3px solid #55606e;
}
.bootstrapWizard li.active .title,
.bootstrapWizard li.complete .title {
  color: #2b3d53;
}
.bootstrapWizard li .title {
  color: #bfbfbf;
  display: block;
  font-size: 13px;
  line-height: 15px;
  max-width: 100%;
  position: relative;
  table-layout: fixed;
  text-align: center;
  top: 20px;
  word-wrap: break-word;
  z-index: 104;
}
.wizard-actions {
  display: block;
  list-style: none;
  padding: 0;
  position: relative;
  width: 100%;
}
.wizard-actions li {
  display: inline;
}
.tab-content.transparent {
  background-color: transparent;
}
.fuelux .wizard {
  position: relative;
  overflow: hidden;
  background-color: #f9f9f9;
  border: 1px solid #d4d4d4;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  zoom: 1;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
}
.fuelux .wizard:after,
.fuelux .wizard:before {
  display: table;
  line-height: 0;
  content: "";
}
.fuelux .wizard:after {
  clear: both;
}
.fuelux .wizard ul {
  width: 4000px;
  padding: 0;
  margin: 0;
  list-style: none outside none;
}
.fuelux .wizard ul.previous-disabled li.complete {
  cursor: default;
}
.fuelux .wizard ul.previous-disabled li.complete:hover {
  color: #356e35;
  cursor: default;
  background: #f3f4f5;
}
.fuelux .wizard ul.previous-disabled li.complete:hover .chevron:before {
  border-left-color: #f3f4f5;
}
.fuelux .wizard ul li {
  position: relative;
  float: left;
  height: 46px;
  padding: 0 20px 0 30px;
  margin: 0;
  font-size: 16px;
  line-height: 46px;
  color: #999;
  cursor: default;
  background: #ededed;
}
.fuelux .wizard ul li .chevron {
  position: absolute;
  top: 0;
  right: -14px;
  z-index: 1;
  display: block;
  border: 24px solid transparent;
  border-right: 0;
  border-left: 14px solid #d4d4d4;
}
.fuelux .wizard ul li .chevron:before {
  position: absolute;
  top: -24px;
  right: 1px;
  display: block;
  border: 24px solid transparent;
  border-right: 0;
  border-left: 14px solid #ededed;
  content: "";
}
.fuelux .wizard ul li.complete {
  color: #468847;
  background: #f3f4f5;
}
.fuelux .wizard ul li.complete:hover {
  cursor: pointer;
  background: #e7eff8;
}
.fuelux .wizard ul li.complete:hover .chevron:before {
  border-left: 14px solid #e7eff8;
}
.fuelux .wizard ul li.complete .chevron:before {
  border-left: 14px solid #f3f4f5;
}
.fuelux .wizard ul li.active {
  color: #3a87ad;
  background: #f1f6fc;
}
.fuelux .wizard ul li.active .chevron:before {
  border-left: 14px solid #f1f6fc;
}
.fuelux .wizard ul li .badge {
  margin-right: 8px;
}
.fuelux .wizard ul li:first-child {
  padding-left: 20px;
  border-radius: 4px 0 0 4px;
}
.fuelux .wizard .actions {
  position: absolute;
  right: 0;
  z-index: 2;
  float: right;
  padding-right: 15px;
  padding-left: 15px;
  line-height: 46px;
  vertical-align: middle;
  background-color: #eee;
  border-left: 1px solid #d4d4d4;
}
.fuelux .wizard .actions a {
  margin-right: 8px;
  font-size: 12px;
  line-height: 45px;
}
.fuelux .wizard .actions .btn-prev i {
  margin-right: 5px;
}
.fuelux .wizard .actions .btn-next i {
  margin-left: 5px;
}
.fuelux .step-content .step-pane {
  display: none;
}
.fuelux .step-content .active {
  display: block;
}
.fuelux .step-content .active .btn-group .active {
  display: inline-block;
}
.jarvismetro-tile {
  float: left;
  display: block;
  background-color: #fff;
  width: 100px;
  height: 70px;
  cursor: pointer;
  box-shadow: inset 0 0 1px #ffc;
  border: 1px dotted #c5c5c5;
  text-decoration: none;
  color: #fff;
  font-weight: 300;
  font-smooth: always;
  margin: 0 10px 20px 0;
  padding: 5px;
  position: relative;
}
.jarvismetro-tile:hover {
  z-index: 10;
  -webkit-transform: scale(1.07);
  -moz-transform: scale(1.07);
}
.jarvismetro-tile.big-cubes {
  width: 120px;
  height: 120px;
}
.jarvismetro-tile.double {
  width: 249px;
}
.jarvismetro-tile:active {
  top: 1px;
  left: 1px;
}
.jarvismetro-tile .iconbox {
  text-align: center;
}
.jarvismetro-tile .iconbox i {
  display: block;
  margin: 15px auto 0;
  height: 75px;
}
.jarvismetro-tile .iconbox span {
  display: block;
  text-align: left;
}
.jarvismetro-tile .iconbox span > span {
  position: absolute;
  right: -3px;
  bottom: -3px;
  border-radius: 50%;
  padding: 5px;
  border: 1px solid #fff;
}
#shortcut .selected {
  border: 1px solid #0091d9 !important;
  position: relative;
}
#shortcut .selected:before {
  display: block;
  position: absolute;
  content: "\f00c";
  color: #fff;
  right: 4px;
  top: 3px;
  font-family: FontAwesome;
  z-index: 2;
}
#shortcut .selected:after {
  width: 0;
  height: 0;
  border-top: 35px solid #0091d9;
  border-left: 35px solid rgba(0, 0, 0, 0);
  position: absolute;
  display: block;
  right: 0;
  content: "";
  top: 0;
  z-index: 1;
}
#shortcut {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  width: 100%;
  background-color: #33302f;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 907;
  display: none;
  color: #fff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#shortcut ul {
  padding: 15px 20px 10px;
  list-style: none;
  margin: 0;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid #423f3f;
}
#shortcut ul li {
  display: inline-block;
  width: auto;
}
#shortcut ul li .jarvismetro-tile {
  margin: 0 3px 3px;
  border: none;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
}
#shortcut ul li .jarvismetro-tile:hover {
  color: #fff;
  text-decoration: none;
}
#shortcut ul li .jarvismetro-tile:active,
#shortcut ul li .jarvismetro-tile:focus {
  left: 0;
  top: 0;
}
.shortcut-on #response-btn {
  display: none !important;
}
.shortcut-on #left-bar .navbar,
.shortcut-on #main .navbar {
  border: none !important;
}
.arrow-box-up:after,
.arrow-box-up:before {
  bottom: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.arrow-box-up:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 7px;
  left: 50%;
  margin-left: -7px;
}
.arrow-box-up:before {
  border-color: rgba(131, 131, 131, 0);
  border-bottom-color: #838383;
  border-width: 8px;
  left: 50%;
  margin-left: -8px;
}
.arrow-box-up-right:after,
.arrow-box-up-right:before {
  bottom: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.arrow-box-up-right:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 7px;
  right: 10px;
  margin-left: -7px;
}
.arrow-box-up-right:before {
  border-color: rgba(131, 131, 131, 0);
  border-bottom-color: #838383;
  border-width: 8px;
  right: 9px;
  margin-left: -8px;
}
.tooltip-inner {
  padding: 5px 10px;
  font-size: 11px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  border-radius: 0;
}
.custom-scroll::-webkit-scrollbar {
  -webkit-overflow-scrolling: touch;
  height: 10px;
  width: 10px;
}
.custom-scroll::-webkit-scrollbar:hover {
  background-color: #e9e9e9;
  border: 1px solid #dbdbdb;
}
.custom-scroll::-webkit-scrollbar-button:end:increment,
.custom-scroll::-webkit-scrollbar-button:start:decrement {
  background: 0 0;
  display: block;
  height: 0;
}
.custom-scroll::-webkit-scrollbar-track {
  background-clip: padding-box;
  border: solid transparent;
}
.custom-scroll::-webkit-scrollbar-track-piece {
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  -webkit-border-radius: 0;
  background-color: transparent;
  border-radius: 0;
}
.custom-scroll::-webkit-scrollbar-thumb {
  -moz-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1),
    inset 0 -1px 0 rgba(0, 0, 0, 0.07);
  -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1),
    inset 0 -1px 0 rgba(0, 0, 0, 0.07);
  background-clip: padding-box;
  background-color: rgba(0, 0, 0, 0.2);
  border: none;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1),
    inset 0 -1px 0 rgba(0, 0, 0, 0.07);
}
.custom-scroll::-webkit-scrollbar-thumb:horizontal,
.custom-scroll::-webkit-scrollbar-thumb:vertical {
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  -webkit-border-radius: 0;
  background-color: #bfbfbf;
  border-radius: 0;
}
.custom-scroll::-webkit-scrollbar-thumb:active {
  -moz-box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.33);
  -webkit-box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.33);
  background-color: rgba(0, 0, 0, 0.44);
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.33);
}
.custom-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #959595;
}
.twitter-typeahead .tt-hint,
.twitter-typeahead .tt-query {
  margin-bottom: 0;
  height: 42px;
  padding: 6px 12px;
}
.tt-dropdown-menu {
  min-width: 160px;
  margin-top: 2px;
  padding: 5px 0;
  background-color: #fff;
  border: 1px solid #bfbfbf;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-right-width: 2px;
  border-bottom-width: 2px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}
.tt-suggestion {
  display: block;
  padding: 3px 20px;
}
.tt-suggestion.tt-is-under-cursor {
  color: #fff;
  background-color: #0081c2;
}
.tt-suggestion.tt-is-under-cursor a {
  color: #fff;
}
.tt-suggestion p {
  margin: 0;
}
.google_maps {
  width: 100%;
  height: 350px;
  position: relative;
}
.google_maps * {
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
}
.google_maps img {
  max-width: none;
}
.header-btn {
  margin-top: 5px;
}
.cke_top {
  background: rgba(248, 248, 248, 0.9) !important;
  background-image: none !important;
}
.multiselect-container {
  position: absolute;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.multiselect-container .input-group {
  margin: 5px;
}
.multiselect-container > li {
  padding: 0;
}
.multiselect-container > li > a.multiselect-all label {
  font-weight: 700;
}
.multiselect-container > li > label.multiselect-group {
  margin: 0;
  padding: 3px 20px;
  height: 100%;
  font-weight: 700;
}
.multiselect-container > li > a > label {
  margin: 0;
  height: 100%;
  cursor: pointer;
  font-weight: 400;
}
.multiselect-container > li > a > label.checkbox,
.multiselect-container > li > a > label.radio {
  margin: 0;
}
.multiselect-container > li > a > label > input[type="checkbox"] {
  margin-bottom: 5px;
}
.btn-group > .btn-group:nth-child(2) > .multiselect.btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.input-group .icon-addon .form-control {
  border-radius: 0;
}
.icon-addon {
  position: relative;
  color: #555;
  display: block;
}
.icon-addon:after,
.icon-addon:before {
  display: table;
  content: " ";
}
.icon-addon:after {
  clear: both;
}
.icon-addon .fa,
.icon-addon .glyphicon,
.icon-addon.addon-md .fa,
.icon-addon.addon-md .glyphicon {
  position: absolute;
  z-index: 2;
  left: 10px;
  width: 20px;
  margin-left: -2.5px;
  text-align: center;
  padding: 10px 0;
  top: 1px;
}
.icon-addon.addon-lg .form-control {
  line-height: 1.33;
  height: 46px;
  font-size: 18px;
  padding: 10px 16px 10px 40px;
}
.input-group-lg .icon-addon.addon-lg + .input-group-btn > .btn {
  height: 46px;
}
.icon-addon.addon-sm .form-control {
  height: 30px;
  padding: 5px 10px 5px 28px;
  font-size: 12px;
  line-height: 1.5;
}
.icon-addon.addon-lg .fa,
.icon-addon.addon-lg .glyphicon {
  font-size: 18px;
  margin-left: 0;
  left: 11px;
  top: 4px;
}
.icon-addon .form-control,
.icon-addon.addon-md .form-control {
  padding-left: 30px;
  float: left;
  font-weight: 400;
}
.icon-addon.addon-sm .fa,
.icon-addon.addon-sm .glyphicon {
  margin-left: 0;
  font-size: 12px;
  left: 5px;
  top: -1px;
}
.icon-addon .form-control:focus + .fa,
.icon-addon .form-control:focus + .glyphicon,
.icon-addon:hover .fa,
.icon-addon:hover .glyphicon {
  color: #2580db;
}
.icon-addon input:disabled + .fa,
.icon-addon input:disabled + .glyphicon,
.icon-addon select:disabled + .fa,
.icon-addon select:disabled + .glyphicon {
  color: #ccc;
}
html.hidden-menu-mobile-lock {
  overflow-x: hidden;
}
.no-content-padding {
  margin: -10px -14px 0 -14px;
}
.no-padding {
  padding: 0 !important;
}
.no-padding > pre {
  margin: 30px;
}
.no-padding .dataTables_wrapper table,
.no-padding > table {
  border: none !important;
  margin-bottom: 0 !important;
  border-bottom-width: 0 !important;
}
.no-padding-bottom {
  padding-bottom: 0 !important;
}
.padding-5 {
  padding: 5px !important;
}
.padding-7 {
  padding: 7px !important;
}
.padding-10 {
  padding: 10px !important;
}
.padding-gutter {
  padding: 13px !important;
}
.padding-top-10 {
  padding-top: 10px !important;
}
.padding-top-15 {
  padding-top: 15px !important;
}
.padding-bottom-10 {
  padding-bottom: 10px !important;
}
.padding-bottom-0 {
  padding-bottom: 0 !important;
}
.padding-top-0 {
  padding-top: 0 !important;
}
.no-padding .dataTables_wrapper table tr td:last-child,
.no-padding .dataTables_wrapper table tr th:last-child,
.no-padding > table tr td:last-child,
.no-padding > table tr th:last-child {
  border-right: none !important;
}
.no-padding .dataTables_wrapper table tr td:first-child,
.no-padding .dataTables_wrapper table tr th:first-child,
.no-padding > table tr td:first-child,
.no-padding > table tr th:first-child {
  border-left: none !important;
}
.no-padding .dataTables_wrapper table tbody tr:last-child td {
  border-bottom: 0;
}
.semi-bold {
  font-weight: 400 !important;
}
.ultra-light {
  font-weight: 300 !important;
}
.font-xs {
  font-size: 85% !important;
}
.font-sm {
  font-size: 95% !important;
}
.font-md {
  font-size: 130% !important;
}
.font-lg {
  font-size: 160% !important;
}
.font-xl {
  font-size: 200% !important;
}
.font-400 {
  font-size: 400% !important;
}
.no-margin {
  margin: 0 !important;
}
.margin-top-5 {
  margin-top: 5px !important;
}
.margin-top-10 {
  margin-top: 10px !important;
}
.margin-bottom-5 {
  margin-bottom: 5px !important;
}
.margin-bottom-10 {
  margin-bottom: 10px !important;
}
.margin-right-5 {
  margin-right: 5px !important;
}
.margin-top-0 {
  margin-top: 0 !important;
}
.margin-bottom-0 {
  margin-bottom: 0 !important;
}
.no-border,
.well[class*=" bg-"].no-border {
  border-width: 0 !important;
}
.no-border-transparent {
  border-color: transparent !important;
}
.no-border-radius {
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
}
.display-inline {
  display: inline-block !important;
}
.display-normal {
  display: inline !important;
}
.cursor-pointer {
  cursor: pointer;
}
.hover-transparent {
  opacity: 0.5;
}
.hover-transparent:hover {
  opacity: 1;
}
.text-align-center,
.text-center {
  text-align: center !important;
}
.text-align-left,
.text-left {
  text-align: left !important;
}
.text-align-right,
.text-right {
  text-align: right !important;
}
hr.simple {
  margin-top: 10px;
  margin-bottom: 10px;
  border-style: dashed;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.air {
  position: absolute;
  z-index: 5;
}
.air-bottom {
  bottom: 0;
}
.air-top {
  top: 0;
}
.air-top-right {
  top: 0;
  right: 0;
}
.air-top-left {
  top: 0;
  left: 0;
}
.air-bottom-left {
  bottom: 0;
  left: 0;
}
.air-bottom-right {
  bottom: 0;
  right: 0;
}
.display-none {
  display: none !important;
}
.checkbox input[type="checkbox"].checkbox + span,
.checkbox-inline input[type="checkbox"].checkbox + span,
.radio input[type="radio"].radiobox + span,
.radiobox-inline input[type="radio"].radiobox + span {
  margin-left: -20px;
}
.form-inline .checkbox input[type="checkbox"].checkbox + span,
.form-inline .radiobox input[type="radio"].radiobox + span {
  margin-left: 0;
}
.form-inline .checkbox,
.form-inline .radiobox {
  padding-left: 0;
}
input[type="checkbox"].checkbox + span,
label input[type="radio"].radiobox + span {
  vertical-align: middle;
  line-height: 20px;
}
input[type="checkbox"].checkbox + span,
input[type="checkbox"].checkbox + span:after,
input[type="checkbox"].checkbox + span:before,
input[type="radio"].radiobox + span,
input[type="radio"].radiobox + span:after,
input[type="radio"].radiobox + span:before {
  box-sizing: content-box !important;
  -webkit-box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
}
input[type="checkbox"].checkbox,
input[type="radio"].radiobox {
  visibility: hidden;
  position: absolute;
  z-index: 20;
  width: 18px;
  height: 18px;
}
label input[type="checkbox"].checkbox + span,
label input[type="radio"].radiobox + span {
  position: relative;
  z-index: 19;
  display: inline-block;
  margin: 0;
  line-height: 17px;
  min-height: 14px;
  min-width: 14px;
  margin-right: 5px;
}
label input[type="checkbox"].checkbox + span:hover,
label input[type="radio"].radiobox + span:hover {
  cursor: pointer;
}
label input[type="checkbox"].checkbox + span:before,
label input[type="radio"].radiobox + span:before {
  font-family: FontAwesome;
  font-size: 12px;
  border-radius: 0;
  content: "\a0";
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  padding: 1px;
  height: 12px;
  line-height: 12px;
  min-width: 12px;
  margin-right: 5px;
  border: 1px solid #bfbfbf;
  background-color: #f4f4f4;
  font-weight: 400;
  margin-top: -1px;
}
label input[type="radio"].radiobox + span:before {
  content: "\a0";
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 0 0 rgba(255, 255, 255, 0.2);
}
label input[type="radio"].radiobox:checked + span {
  font-weight: 700;
}
label input[type="radio"].radiobox:checked + span:before {
  content: "\f111";
  color: #2e7bcc;
}
label input[type="radio"].radiobox.style-3:checked + span:before {
  color: #a90329;
  border-color: #a90329;
}
label input[type="radio"].radiobox.style-2:checked + span:before {
  color: #66665e;
  border-color: #4d90fe;
  font-size: 9px;
}
label input[type="radio"].radiobox.style-2:checked + span {
  font-weight: 400;
}
label input[type="radio"].radiobox.style-1 + span:before {
  font-size: 9px;
  background: #fff;
  text-shadow: none;
  box-shadow: none !important;
  border-width: 1px;
  height: 13px;
  line-height: 13px;
  min-width: 13px;
}
label input[type="radio"].radiobox.style-1:checked + span:before {
  font-size: 10px;
  color: #333;
  border-color: #333;
  background: #fff;
}
label input[type="radio"].radiobox.style-1:checked + span {
  font-weight: 400;
}
label:hover input[type="radio"].radiobox + span:before,
label:hover input[type="radio"].radiobox:checked + span:before {
  color: #2e7bcc;
  border-color: #2e7bcc;
}
label:hover input[type="radio"].radiobox.style-3 + span:before,
label:hover input[type="radio"].radiobox.style-3:checked + span:before {
  color: #a90329;
  border-color: #a90329;
}
label:hover input[type="radio"].radiobox.style-2:checked + span:before {
  color: #66665e;
  border-color: #4d90fe;
}
label:hover input[type="radio"].radiobox.style-1 + span:before,
label:hover input[type="radio"].radiobox.style-1:checked + span:before {
  color: #333;
  border-color: #333;
  background: #fff;
}
label:active input[type="radio"].radiobox + span:before {
  box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.3),
    0 1px 0 0 rgba(255, 255, 255, 0.4);
}
label input[type="radio"][disabled].radiobox + span,
label input[type="radio"][disabled].radiobox:checked + span,
label:hover input[type="radio"][disabled].radiobox:checked + span:before {
  color: #a7a7a7;
  cursor: not-allowed;
}
label input[type="radio"][disabled].radiobox:checked + span:before {
  content: "\f111";
}
label input[type="radio"][disabled].radiobox + span:before,
label input[type="radio"][disabled].radiobox:checked + span:before,
label:active input[type="radio"][disabled].radiobox:checked + span:before,
label:hover input[type="radio"][disabled].radiobox:checked + span:before {
  background: #eee !important;
  border-color: #ccc !important;
  box-shadow: none;
  color: #a7a7a7;
}
label input[type="checkbox"].checkbox + span:before {
  content: "\a0";
}
label input[type="checkbox"].checkbox:checked + span:before {
  content: "\f00c";
}
label input[type="checkbox"].checkbox:checked + span {
  font-weight: 700;
}
label input[type="checkbox"].checkbox:checked + span:before {
  color: #2e7bcc;
}
label input[type="checkbox"].checkbox.style-3:checked + span:before {
  color: #fff;
  border-color: #a90329;
  background: #a90329;
}
label input[type="checkbox"].checkbox.style-2 + span:before {
  background: 0 0;
  border-color: #c1c1c1;
  box-shadow: none;
}
label input[type="checkbox"].checkbox.style-2:checked + span:before {
  content: "\f00c";
  color: #66665e;
  border-color: #4d90fe;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYxIDY0LjE0MDk0OSwgMjAxMC8xMi8wNy0xMDo1NzowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNS4xIFdpbmRvd3MiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MUQ0MEM4RUFEQUE4MTFFMkEwMjM5NzlCRjAxM0UwRjciIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MUQ0MEM4RUJEQUE4MTFFMkEwMjM5NzlCRjAxM0UwRjciPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxRDQwQzhFOERBQTgxMUUyQTAyMzk3OUJGMDEzRTBGNyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoxRDQwQzhFOURBQTgxMUUyQTAyMzk3OUJGMDEzRTBGNyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PsD/PVAAAAAQSURBVHjaYv7//78vQIABAAlYA05NMPppAAAAAElFTkSuQmCC)
    #fff;
}
label input[type="checkbox"].checkbox.style-2:checked + span {
  font-weight: 400;
}
label input[type="checkbox"].checkbox.style-1 + span:before {
  color: #333;
  background: #fff;
  text-shadow: none;
  box-shadow: none !important;
  border-width: 1px;
  height: 12px;
  line-height: 13px;
  min-width: 12px;
}
label input[type="checkbox"].checkbox.style-1:checked + span:before {
  color: #333;
  border-color: #333;
}
label input[type="checkbox"].checkbox.style-1:checked + span {
  font-weight: 400;
}
label:active input[type="checkbox"].checkbox + span:before {
  box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.3),
    0 1px 0 0 rgba(255, 255, 255, 0.4);
}
label:hover input[type="checkbox"].checkbox + span:before {
  color: #2e7bcc;
  border-color: #2e7bcc;
}
label:hover input[type="checkbox"].checkbox.style-3 + span:before {
  color: #fff;
  border-color: #a90327;
}
label:hover input[type="checkbox"].checkbox.style-2 + span:before {
  box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 0 0 rgba(255, 255, 255, 0.2);
}
label:hover input[type="checkbox"].checkbox.style-2:checked + span:before {
  box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.3),
    0 1px 0 0 rgba(255, 255, 255, 0.4);
}
label:hover input[type="checkbox"].checkbox.style-1 + span:before {
  border-color: #333;
}
label:active input[type="checkbox"].checkbox:checked + span:before {
  color: #fff;
  background: #2e7bcc;
  border-color: #2e7bcc;
}
label:active input[type="checkbox"].checkbox.style-3:checked + span:before {
  color: #a90329;
  background: #fff;
  border-color: #a90329;
}
label:active input[type="checkbox"].checkbox.style-2 + span:before,
label:active input[type="checkbox"].checkbox.style-2:checked + span:before {
  color: #66665e;
  background: 0 0;
  border-color: #c1c1c1;
  box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.3),
    0 1px 0 0 rgba(255, 255, 255, 0.4);
}
label:active input[type="checkbox"].checkbox.style-1:checked + span:before {
  color: #fff;
  background: #333;
  border-color: #333;
}
label input[type="checkbox"][disabled].checkbox + span,
label input[type="checkbox"][disabled].checkbox:checked + span,
label:hover input[type="checkbox"][disabled].checkbox:checked + span:before {
  color: #a7a7a7;
  cursor: not-allowed;
}
label input[type="checkbox"][disabled].checkbox.style-2:checked + span:before,
label input[type="checkbox"][disabled].checkbox:checked + span:before {
  content: "\f00c";
}
label input[type="checkbox"][disabled].checkbox + span:before,
label input[type="checkbox"][disabled].checkbox:checked + span:before,
label:active input[type="checkbox"][disabled].checkbox:checked + span:before,
label:hover input[type="checkbox"][disabled].checkbox:checked + span:before {
  background: #eee !important;
  border-color: #ccc !important;
  color: #a7a7a7;
  box-shadow: none !important;
}
.square {
  display: block;
  float: left;
  margin-right: 10px;
  height: 20px;
  width: 20px;
}
[class*=" bg-"],
[class^="bg-"] {
  filter: none !important;
}
.txt-color-blue {
  color: #57889c !important;
}
.txt-color-blueLight {
  color: #92a2a8 !important;
}
.txt-color-blueDark {
  color: #4c4f53 !important;
}
.txt-color-green {
  color: #356e35 !important;
}
.txt-color-greenLight {
  color: #71843f !important;
}
.txt-color-greenDark {
  color: #496949 !important;
}
.txt-color-red {
  color: #a90329 !important;
}
.txt-color-yellow {
  color: #b09b5b !important;
}
.txt-color-orange {
  color: #b19a6b !important;
}
.txt-color-orangeDark {
  color: #a57225 !important;
}
.txt-color-pink {
  color: #ac5287 !important;
}
.txt-color-pinkDark {
  color: #a8829f !important;
}
.txt-color-purple {
  color: #6e587a !important;
}
.txt-color-darken {
  color: #404040 !important;
}
.txt-color-lighten {
  color: #d5e7ec !important;
}
.txt-color-white {
  color: #fff !important;
}
.txt-color-grayDark {
  color: #525252 !important;
}
.txt-color-magenta {
  color: #6e3671 !important;
}
.txt-color-teal {
  color: #568a89 !important;
}
.txt-color-redLight {
  color: #a65858 !important;
}
.bg-color-blue {
  background-color: #57889c !important;
}
.bg-color-blueLight {
  background-color: #92a2a8 !important;
}
.bg-color-blueDark {
  background-color: #4c4f53 !important;
}
.bg-color-green {
  background-color: #356e35 !important;
}
.bg-color-greenLight {
  background-color: #71843f !important;
}
.bg-color-greenDark {
  background-color: #496949 !important;
}
.bg-color-red {
  background-color: #a90329 !important;
}
.bg-color-yellow {
  background-color: #b09b5b !important;
}
.bg-color-orange {
  background-color: #c79121 !important;
}
.bg-color-orangeDark {
  background-color: #a57225 !important;
}
.bg-color-pink {
  background-color: #ac5287 !important;
}
.bg-color-pinkDark {
  background-color: #a8829f !important;
}
.bg-color-purple {
  background-color: #6e587a !important;
}
.bg-color-darken {
  background-color: #404040 !important;
}
.bg-color-lighten {
  background-color: #d5e7ec !important;
}
.bg-color-white {
  background-color: #fff !important;
}
.bg-color-grayDark {
  background-color: #525252 !important;
}
.bg-color-magenta {
  background-color: #6e3671 !important;
}
.bg-color-teal {
  background-color: #568a89 !important;
}
.bg-color-redLight {
  background-color: #a65858 !important;
}
.page-footer {
  height: 52px;
  padding: 15px 13px 0;
  padding-left: 233px;
  border-top: 1px solid #cecece;
  background: #2a2725;
  width: 100%;
  position: absolute;
  display: block;
  bottom: 0;
}
.fixed-page-footer .page-footer {
  position: fixed;
  z-index: 901;
}
.minified .page-footer {
  padding-left: 58px;
}
.container.fixed-page-footer .page-footer {
  max-width: 1164px;
}
.hidden-menu .page-footer {
  padding-left: 20px;
}
@media (max-width: 979px) {
  html.hidden-menu-mobile-lock {
    overflow-x: hidden;
  }
  #main {
    width: 100% !important;
  }
  .page-footer {
    padding: 15px 14px 0;
  }
  .profile-pic > img {
    width: 110px;
  }
  .profile-pic {
    float: left;
  }
  .profile-pic > img {
    margin-right: 10px;
  }
  .profile-pic + div,
  .profile-pic + div + div {
    padding-left: 30px;
    padding-right: 30px;
  }
  #fullscreen > :first-child > a {
    display: none !important;
  }
  body.container {
    border: none !important;
    width: 100% !important;
  }
  .inbox-data-message > :first-child {
    height: 50px;
    overflow: hidden;
  }
  .show-stats .show-stat-buttons > :first-child {
    padding-right: 13px;
  }
  .show-stats .show-stat-buttons > :last-child {
    padding-left: 13px;
  }
  .inbox-checkbox-triggered > .btn-group .btn {
    padding-left: 10px;
    padding-right: 10px;
  }
  .inbox-body .table-wrap {
    padding: 0 !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .inbox-nav-bar {
    padding-left: 5px;
    padding-right: 5px;
  }
  .inbox-data-message > :first-child > :first-child {
    display: block !important;
    font-size: 14px;
  }
  .inbox-table-icon > :first-child {
    margin-top: 12px;
  }
  #inbox-table .inbox-table-icon {
    padding-left: 10px !important;
  }
  .header-search.pull-right {
    margin-left: 0;
  }
  .visible-tablet {
    display: inline-block !important;
  }
  .display-inline.hidden-tablet,
  .hidden-tablet {
    display: none !important;
  }
  .jarviswidget header h2 {
    width: 310px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .dropzone .dz-default.dz-message {
    background-size: 100%;
    width: 200px;
    height: 43px;
    margin-left: -112px;
    margin-top: -23.5px;
  }
}
@media (min-width: 768px) and (max-width: 979px) {
  #main {
    margin-left: 0;
  }
  #left-panel {
    left: -220px;
  }
  .minified #left-panel {
    left: 0;
  }
  #hide-menu > :first-child > a {
    margin-top: 9px;
    width: 40px;
  }
  .btn-header a {
    margin-top: 9px !important;
    width: 40px !important;
  }
  #hide-menu i {
    color: #6d6a69;
    font-size: 100%;
  }
  .hidden-menu #hide-menu i {
    color: #fff;
  }
  #hide-menu i::before {
    content: "\f0c9";
  }
  .hidden-menu #left-panel {
    left: 0;
  }
  .hidden-menu #main {
    position: relative;
    left: 210px;
  }
  .hidden-menu.fixed-ribbon #ribbon {
    left: 220px;
    width: 100%;
  }
  .fixed-ribbon #ribbon {
    left: 0;
  }
  .jarviswidget header h2 {
    width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .header-search > input[type="text"] {
    margin-top: 9px;
  }
}
@media (min-width: 768px) and (max-width: 880px) {
  #main {
    margin-left: 0;
    overflow-x: hidden;
  }
  #left-panel {
    left: -220px;
  }
  .no-content-padding {
    margin: 0 -14px !important;
  }
  .inbox-nav-bar.no-content-padding {
    margin-top: -10px !important;
  }
  .minified #left-panel {
    left: 0;
  }
  #hide-menu > :first-child > a,
  .btn-header a {
    margin-top: 5px !important;
    width: 40px !important;
    height: 39px !important;
    line-height: 38px !important;
  }
  #logo {
    margin-top: 10px;
  }
  #logo img {
    width: 127px;
  }
  .btn-header.transparent a {
    border: none !important;
    background: 0 0;
    margin-left: 0;
    width: 25px !important;
    box-shadow: none !important;
  }
  .btn-header.transparent a:hover {
    color: #a90329;
  }
  #hide-menu i {
    color: #6d6a69;
    font-size: 121%;
  }
  .hidden-menu #hide-menu i {
    color: #fff;
  }
  #hide-menu i::before {
    content: "\f0c9";
  }
  .hidden-menu #left-panel {
    left: 0;
  }
  .hidden-menu #main {
    position: relative;
    left: 210px;
  }
  .hidden-menu.fixed-ribbon #ribbon {
    left: 220px;
    width: 100%;
  }
  .fixed-ribbon #ribbon {
    left: 0;
  }
  .jarviswidget header h2 {
    width: 180px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .header-search > input[type="text"] {
    margin-top: 9px;
  }
}
@media (max-width: 767px) {
  .profile-pic > img {
    width: 100px;
  }
  #main {
    margin-left: 0;
    overflow-x: hidden;
  }
  #left-panel {
    left: -220px;
  }
  .show-stat-microcharts > div {
    margin-top: 0 !important;
    border-right: none !important;
  }
  .show-stat-buttons {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  #content,
  #ribbon {
    padding-left: 5px;
    padding-right: 5px;
  }
  #header {
    padding-right: 5px;
  }
  #logo {
    margin-left: 4px;
  }
  .no-content-padding {
    margin: 0 -5px !important;
  }
  .inbox-nav-bar.no-content-padding {
    margin-top: -10px !important;
  }
  .minified #left-panel {
    left: 0;
  }
  .btn-header.transparent a {
    border: none !important;
    background: 0 0;
    margin-left: 0;
    width: 25px !important;
    box-shadow: none !important;
  }
  .btn-header.transparent a:hover {
    color: #a90329;
  }
  #hide-menu > :first-child > a,
  .btn-header a {
    margin-top: 5px !important;
    width: 40px !important;
    height: 39px !important;
    line-height: 38px !important;
  }
  #hide-menu > :first-child > a {
    width: 50px !important;
  }
  #logo {
    margin-top: 10px;
  }
  #logo img {
    width: 127px;
  }
  #hide-menu i {
    color: #6d6a69;
    font-size: 121%;
  }
  .hidden-menu #hide-menu i {
    color: #fff;
  }
  #hide-menu i::before {
    content: "\f0c9";
  }
  .hidden-menu #left-panel {
    left: 0;
  }
  .hidden-menu #main {
    position: relative;
    left: 210px;
  }
  .hidden-menu.fixed-ribbon #ribbon {
    left: 220px;
    width: 100%;
  }
  .fixed-ribbon #ribbon {
    left: 0;
  }
  body.fixed-navigation.hidden-menu {
    overflow: hidden !important;
  }
  .fixed-ribbon #content {
    padding-top: 52px;
  }
  .header-search {
    display: none;
  }
  #search-mobile {
    display: block;
  }
  .search-mobile .header-search {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 49px;
    background: #333;
    padding: 0 2px;
    box-sizing: border-box;
    left: 0;
  }
  .search-mobile .header-search > input[type="text"] {
    margin-top: 2px;
    height: 45px;
    border-color: #333;
    padding-right: 75px;
  }
  .search-mobile .header-search > button {
    height: 29px;
    line-height: 29px;
    background: #ddd;
    right: 44px;
  }
  .search-mobile #search-mobile {
    display: none;
  }
  .search-mobile #cancel-search-js {
    display: block;
  }
  .jarviswidget header h2 {
    width: 250px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
@media only screen and (min-width: 0) and (max-width: 679px) {
  .profile-pic > img {
    width: 90px;
  }
  #main {
    margin-left: 0;
    overflow-x: hidden;
  }
  #left-panel {
    left: -220px;
  }
  .show-stat-microcharts > div {
    margin-top: 0 !important;
    border-right: none !important;
  }
  .show-stat-buttons {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  #content,
  #ribbon {
    padding-left: 5px;
    padding-right: 5px;
  }
  #header {
    padding-right: 5px;
  }
  #logo {
    margin-left: 4px;
  }
  .no-content-padding {
    margin: 0 -5px !important;
  }
  .inbox-nav-bar.no-content-padding {
    margin-top: -10px !important;
  }
  .minified #left-panel {
    left: 0;
  }
  #header {
    background: #fff;
  }
  #ribbon {
    border-bottom: 1px solid #cfcfcf;
    border-top: 1px solid #e6e6e6;
    background: #f5f5f5;
  }
  #ribbon .breadcrumb,
  #ribbon .breadcrumb a {
    color: #6d6c6c !important;
  }
  #ribbon .breadcrumb li:last-child,
  #ribbon .breadcrumb > .active {
    color: #333 !important;
  }
  #hide-menu > :first-child > a,
  .btn-header a {
    margin-top: 5px !important;
    width: 40px !important;
    height: 39px !important;
    line-height: 38px !important;
  }
  #hide-menu > :first-child > a {
    width: 50px !important;
  }
  #logo {
    margin-top: 10px;
  }
  #logo img {
    width: 127px;
  }
  .btn-header.transparent a {
    border: none !important;
    background: 0 0;
    margin-left: 0;
    width: 25px !important;
    box-shadow: none !important;
  }
  .btn-header.transparent a:hover {
    color: #a90329;
  }
  #hide-menu i {
    color: #6d6a69;
    font-size: 121%;
  }
  .hidden-menu #hide-menu i {
    color: #fff;
  }
  #hide-menu i::before {
    content: "\f0c9";
  }
  .hidden-menu #left-panel {
    left: 0;
  }
  .hidden-menu #main {
    position: relative;
    left: 210px;
  }
  .hidden-menu.fixed-ribbon #ribbon {
    left: 220px;
    width: 100%;
  }
  .fixed-ribbon #ribbon {
    left: 0;
  }
  .header-search {
    display: none;
  }
  #search-mobile {
    display: block;
  }
  .search-mobile .header-search {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 49px;
    background: #333;
    padding: 0 2px;
    box-sizing: border-box;
    left: 0;
  }
  .search-mobile .header-search > input[type="text"] {
    margin-top: 2px;
    height: 45px;
    border-color: #333;
    padding-right: 75px;
  }
  .search-mobile .header-search > button {
    height: 29px;
    line-height: 29px;
    background: #ddd;
    right: 44px;
  }
  .search-mobile #search-mobile {
    display: none;
  }
  .search-mobile #cancel-search-js {
    display: block;
  }
  .hidden-tablet {
    display: none !important;
  }
  .jarviswidget header h2 {
    width: 300px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .page-title {
    display: none;
  }
  .profile-pic {
    text-align: left;
  }
  .profile-pic > img {
    width: 90px;
    margin-left: 0;
    top: -25px;
    margin-right: 0;
  }
  .profile-pic + div > h1 {
    font-size: 20px;
  }
  .profile-pic + div > h1 small {
    font-size: 16px;
  }
  .profile-carousel .carousel-indicators {
    top: 10px;
  }
  #main {
    margin-left: 0;
    overflow-x: hidden;
  }
  #left-panel {
    left: -220px;
  }
  .show-stat-microcharts > div {
    margin-top: 0 !important;
    border-right: none !important;
  }
  .show-stat-buttons {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  #content,
  #ribbon {
    padding-left: 5px;
    padding-right: 5px;
  }
  #header {
    padding-right: 5px;
  }
  #logo {
    margin-left: 4px;
  }
  .no-content-padding {
    margin: 0 -5px !important;
  }
  .inbox-nav-bar.no-content-padding {
    margin-top: -10px !important;
  }
  .minified #left-panel {
    left: 0;
  }
  #header {
    background: #fff;
  }
  #ribbon {
    border-bottom: 1px solid #cfcfcf;
    border-top: 1px solid #e6e6e6;
    background: #f5f5f5;
  }
  #ribbon .breadcrumb,
  #ribbon .breadcrumb a {
    color: #6d6c6c !important;
  }
  #ribbon .breadcrumb li:last-child,
  #ribbon .breadcrumb > .active {
    color: #333 !important;
  }
  #logo {
    width: 135px;
  }
  #logo-group {
    width: 169px !important;
  }
  #sparks {
    text-align: center;
    background: #e0e0e0;
    padding: 10px 0;
    margin-bottom: 15px;
  }
  #sparks li {
    padding: 0 10px 0 20px;
  }
  #hide-menu > :first-child > a,
  .btn-header a {
    margin-top: 5px !important;
    width: 40px !important;
    height: 39px !important;
    line-height: 38px !important;
  }
  #logo {
    margin-top: 10px;
  }
  #logo img {
    width: 127px;
  }
  .btn-header.transparent a {
    border: none !important;
    background: 0 0;
    margin-left: 0;
    width: 25px !important;
    box-shadow: none !important;
  }
  .btn-header.transparent a:hover {
    color: #a90329;
  }
  #hide-menu > :first-child > a {
    width: 46px !important;
  }
  #hide-menu i {
    color: #6d6a69;
    font-size: 121%;
  }
  .hidden-menu #hide-menu i {
    color: #fff;
  }
  #hide-menu i::before {
    content: "\f0c9";
  }
  .hidden-menu #left-panel {
    left: 0;
  }
  .hidden-menu #main {
    position: relative;
    left: 210px;
  }
  .hidden-menu.fixed-ribbon #ribbon {
    left: 220px;
    width: 100%;
  }
  .fixed-ribbon #ribbon {
    left: 0;
  }
  .hidden-mobile {
    display: none !important;
  }
  .visible-mobile {
    display: inline-block !important;
  }
  .ajax-notifications {
    height: 250px;
  }
  .ajax-dropdown {
    width: 299px;
    height: 320px;
    left: 0;
    top: 49px;
  }
  .ajax-dropdown:before {
    margin-left: -14px;
  }
  .ajax-dropdown:after {
    margin-left: -13px;
  }
  .header-search {
    display: none;
  }
  #search-mobile {
    display: block;
    margin-left: 0;
  }
  .search-mobile .header-search {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 49px;
    background: #333;
    padding: 0 2px;
    box-sizing: border-box;
    left: 0;
  }
  .search-mobile .header-search > input[type="text"] {
    margin-top: 2px;
    height: 45px;
    border-color: #333;
    padding-right: 75px;
  }
  .search-mobile .header-search > button {
    height: 29px;
    line-height: 29px;
    background: #ddd;
    right: 44px;
  }
  .search-mobile #search-mobile {
    display: none;
  }
  .search-mobile #cancel-search-js {
    display: block;
  }
  .jarviswidget header h2 {
    width: 135px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
#extr-page #main {
  margin-top: 0 !important;
}
#mobile-profile-img {
  display: none !important;
  padding-right: 2px !important;
  padding-left: 2px !important;
}
.menu-on-top #mobile-profile-img {
  display: block !important;
}
#mobile-profile-img a.userdropdown img {
  width: 30px;
  margin-top: 5px;
  margin-left: 2px;
  border-radius: 3px;
  border: 1px solid #797979 !important;
}
.menu-on-top .page-footer {
  padding: 15px 13px 0;
}
.menu-on-top.hidden-menu #left-panel {
  left: 0;
}
.menu-on-top #main {
  margin-left: 0 !important;
  margin-top: 69px !important;
}
.menu-on-top #hide-menu,
.menu-on-top #logout,
.menu-on-top .minifyme {
  display: none !important;
}
.menu-on-top aside#left-panel {
  width: 100% !important;
  min-height: 0 !important;
  height: auto;
  overflow: visible;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  border-bottom: 1px solid #222;
  border-top: 1px solid #333;
}
.menu-on-top aside#left-panel nav > ul {
  display: block;
  width: 100%;
}
.menu-on-top aside#left-panel nav > ul > li:first-child {
  margin-left: 0;
}
.menu-on-top aside#left-panel nav > ul > li {
  position: relative;
  height: auto !important;
  padding: 0 !important;
  display: inline-block;
  float: left;
  border-right: 1px solid #222;
  border-left: 1px solid #4e4e4e;
  overflow: visible;
}
.menu-on-top aside#left-panel nav > ul > li > a {
  display: inline-block;
  height: 68px;
  text-shadow: none !important;
  font-size: 13px;
  text-decoration: none;
  line-height: 22px;
  padding: 10px 9px !important;
}
.menu-on-top li.active > a {
  font-weight: 700 !important;
}
.menu-on-top .login-info,
.menu-on-top nav > ul > li > a b {
  display: none;
}
.menu-on-top aside#left-panel nav > ul > li > a > i {
  display: block !important;
  margin: 1px 0 4px;
  line-height: inherit;
  text-align: center;
  font-size: 24px;
  margin-bottom: 2px;
  width: 100%;
}
.menu-on-top .menu-item-parent {
  max-width: 72px;
  min-width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-size: 13px;
}
.menu-on-top .menu-item-parent + span {
  float: none !important;
  top: 5px;
  right: 5px;
  position: absolute;
}
.menu-on-top nav ul ul li a {
  padding-left: 10px !important;
  font-size: 13px;
}
.menu-on-top aside#left-panel nav > ul > li:hover {
  background: #fff;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, #fff),
    color-stop(1, #cfcfcf)
  );
  background: -ms-linear-gradient(bottom, #fff, #cfcfcf);
  background: -moz-linear-gradient(center bottom, #fff 0, #cfcfcf 100%);
  background: -o-linear-gradient(#cfcfcf, #fff);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cfcfcf', endColorstr='#ffffff', GradientType=0);
}
.menu-on-top aside#left-panel nav > ul > li:hover > a {
  color: #333 !important;
  border-left: 1px solid #c7c7c7;
  border-right: 1px solid #c7c7c7;
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.menu-on-top aside#left-panel nav > ul > li > ul {
  position: absolute !important;
  background: #3a3633;
}
.menu-on-top aside#left-panel nav > ul > li:hover > ul {
  display: block !important;
}
.menu-on-top aside#left-panel nav ul li.active > a:before {
  content: "" !important;
}
.menu-on-top nav > ul ul li::before,
.menu-on-top nav > ul > li > ul::before {
  border: none;
}
.menu-on-top nav ul ul li a,
.menu-on-top nav ul ul ul li a {
  color: #4c4f53;
}
.menu-on-top nav ul ul li a:hover,
.menu-on-top nav ul ul li:hover > a,
.menu-on-top nav ul ul ul li a:hover {
  color: #fff;
  background-color: #4c4f53;
}
.menu-on-top nav > ul ul ul {
  border-top: 1px solid #c7c7c7 !important;
}
.menu-on-top nav ul ul li {
  overflow: visible;
}
.menu-on-top nav ul ul,
.menu-on-top nav ul ul ul {
  display: none !important;
  background: #fff !important;
  border: 1px solid #c7c7c7;
  -moz-box-shadow: inset 0 -4px 0 0 #bd2a2a;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  border-bottom: 1px solid #bd2a2a;
  border-top: none;
  width: 200px;
  border-radius: 0;
  padding: 3px;
  margin-left: 0;
}
.menu-on-top nav > ul ul li:hover > ul {
  display: block !important;
  position: absolute;
  z-index: 999;
  left: 100%;
  top: -8px;
  background: #333;
}
.menu-on-top nav > ul > li > a:after {
  content: "\f0dd" !important;
  top: 76% !important;
  color: #636363 !important;
  left: 46%;
}
.menu-on-top nav ul ul li:hover > a:after,
.menu-on-top nav > ul ul > li a:after,
.menu-on-top nav > ul ul > li a:hover:after,
.menu-on-top nav > ul > li > a:after {
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f0da";
  display: block !important;
  position: absolute;
  top: 9px;
  right: 4px;
  color: #333;
}
.menu-on-top nav ul ul li:hover > a:after,
.menu-on-top nav > ul ul > li a:hover:after {
  color: #fff;
}
.menu-on-top nav > ul ul > li a:only-child:after,
.menu-on-top nav > ul > li > a:only-child:after {
  content: "" !important;
}
.menu-on-top nav ul ul .active > a {
  color: #333 !important;
}
.menu-on-top nav ul ul .active > a:hover,
.menu-on-top nav ul ul li.active:hover > a {
  color: #fff !important;
}
.menu-on-top .slimScrollDiv,
.menu-on-top nav {
  overflow: visible !important;
}
body.menu-on-top.fixed-header {
  padding-top: 49px;
}
body.menu-on-top.fixed-header.fixed-navigation nav > ul {
  padding-right: 0;
  position: relative;
  height: auto;
  width: auto;
  overflow: visible;
}
body.menu-on-top.fixed-header.fixed-navigation.fixed-ribbon #ribbon {
  left: 0;
  top: 118px;
}
@media (max-width: 979px) {
  .menu-on-top #mobile-profile-img a.userdropdown img {
    width: 39px;
    margin-top: 0;
    margin-left: 2px;
    border-radius: 3px;
    margin-right: -3px;
  }
  .menu-on-top .btn-header.pull-right {
    margin-left: 0 !important;
  }
  .menu-on-top #main {
    margin-top: 0 !important;
  }
  .menu-on-top #left-panel {
    left: 0;
    display: none;
  }
  body.menu-on-top.fixed-header.fixed-navigation.fixed-ribbon #ribbon {
    top: 49px;
  }
  .menu-on-top.hidden-menu #left-panel {
    display: block;
  }
  .menu-on-top.hidden-menu #main {
    left: 0 !important;
  }
  .menu-on-top #hide-menu {
    display: block !important;
  }
  .menu-on-top aside#left-panel nav {
    height: 300px !important;
    overflow: scroll;
    overflow-x: hidden !important;
    -webkit-overflow-scrolling: touch;
  }
  .menu-on-top .menu-item-parent {
    white-space: inherit;
    overflow: visible;
    text-overflow: inherit;
    text-align: left;
    min-width: 85%;
    max-width: 85%;
    padding-left: 5px;
  }
  .menu-on-top .menu-item-parent + span {
    top: 50%;
    margin-top: -8px;
  }
  .menu-on-top nav li.active > ul {
    display: block;
  }
  .menu-on-top nav > ul > li.active {
    background: #fff;
    background: -moz-linear-gradient(top, #cfcfcf 0, #fff 66%);
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, #cfcfcf),
      color-stop(66%, #fff)
    );
    background: -webkit-linear-gradient(top, #cfcfcf 0, #fff 66%);
    background: -o-linear-gradient(top, #cfcfcf 0, #fff 66%);
    background: -ms-linear-gradient(top, #cfcfcf 0, #fff 66%);
    background: linear-gradient(to bottom, #cfcfcf 0, #fff 66%);
  }
  .menu-on-top nav > ul > li.active > a {
    color: #333 !important;
  }
  .menu-on-top nav ul ul li:hover > a:after,
  .menu-on-top nav > ul ul > li a:after,
  .menu-on-top nav > ul ul > li a:hover:after,
  .menu-on-top nav > ul > li > a:after {
    top: 50%;
    right: 15px;
    font-size: 15px;
    margin-top: -8px;
    content: "\f0dd" !important;
  }
  .menu-on-top nav > ul ul > li a:only-child:after,
  .menu-on-top nav > ul > li > a:only-child:after {
    content: "" !important;
  }
  .menu-on-top nav > ul > li > a:after {
    top: 50% !important;
    left: auto;
    margin-top: -14px;
    font-size: 20px;
  }
  .menu-on-top nav ul ul,
  .menu-on-top nav ul ul ul {
    padding: 0;
    border: none;
  }
  .menu-on-top nav ul ul ul {
    border-bottom: 2px solid #333;
  }
  .menu-on-top aside#left-panel nav > ul > li {
    width: 100%;
  }
  .menu-on-top aside#left-panel nav > ul > li > a {
    height: auto;
    display: block;
    padding: 8px 9px !important;
    border-bottom: 1px dotted #585858;
  }
  .menu-on-top nav > ul > li > ul > li > a,
  .menu-on-top nav > ul > li > ul > li > ul > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .menu-on-top aside#left-panel nav > ul > li > a > i {
    display: inline !important;
  }
  .menu-on-top aside#left-panel nav > ul li ul {
    position: relative !important;
    width: 100%;
    top: 0;
    left: 0;
  }
  .menu-on-top aside#left-panel nav > ul > li:active > ul,
  .menu-on-top aside#left-panel nav > ul > li:focus > ul {
    display: block !important;
  }
}
.menu-on-top .top-menu-invisible,
.no-menu #hide-menu,
.no-menu #left-panel,
.no-menu #logout {
  display: none !important;
}
.no-menu #main {
  margin: 0 !important;
}
.no-menu #mobile-profile-img {
  display: block !important;
}
.no-menu #mobile-profile-img a.userdropdown img {
  width: 30px;
  margin-top: 5px;
  margin-left: 2px;
  border-radius: 3px;
  border: 1px solid #797979 !important;
}
