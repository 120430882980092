/*
 * It is advised not to edit the smartadmin_production.css
 * as this will override any changes you make in the
 * later versions of this theme.
 *
 * We advise that you use use this CSS to override
 * SmartAdmin styles.
 *
 * Rename the stylesheet to whatever your liking so it will stay
 * unique to you with each update of SmartAdmin.
 */

/*
 * INDEX
 *
 * - HTML
 * - BODY
 * - CUSTOM STYLES

 */

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  font-family: "Roboto", sans-serif;
}

body {
  background-color: #f4f5fa;
  height: 100%;
}

#divmar {
  margin-top: -30px;
}

#lbl {
  margin-left: -90px;
}

#lbl1 {
  margin-left: 140px;
  margin-top: 10px;
}

#frmCtl {
  margin-top: 10px;
}

#rowItem {
  margin-left: 140px;
}

/*To Draw Horizontal Line*/
#hr {
  -moz-border-bottom-colors: none;
  -moz-border-image: none;
  border-image: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: #eeeeee -moz-use-text-color #ffffff;
  border-style: solid none;
  border-width: 1px 0;
  margin: 18px 0;
}

/*To insert fa-icons in the form controls*/

.left-inner-addon {
  position: relative;
}

.left-inner-addon input {
  padding-left: 30px;
}

.left-inner-addon i {
  position: absolute;
  padding: 10px 12px;
  pointer-events: none;
}

.right-inner-addon {
  position: relative;
}

.right-inner-addon input {
  padding-right: 30px;
}

.right-inner-addon i {
  position: absolute;
  right: 20px;
  padding: 20px 12px;
  pointer-events: none;
}

.input-group>.input-group-btn:last-child>.selectpicker {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 100px;
}

.input-group>.input-group-btn:first-child>.selectpicker {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.input-group .input-group-addon {
  z-index: 5;
  position: relative;
}

#loginContainer {
  width: 50%;
  margin-left: 180px;
  margin-bottom: 80px;
  margin-right: 100px;
  margin-top: 80px;
}

div.mar {
  margin-left: 10px;
  margin-right: 10px;
}

.hide-text {
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}

.input-block-level {
  display: block;
  width: 100%;
  min-height: 28px;
  /* Make inputs at least the height of their button counterpart */
  /* Makes inputs behave like true block-level elements */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.fieldLabel {
  width: 30%;
  background: #f7f7f9;
}

td.fieldValue input,
td.fieldValue .input-append {
  margin-bottom: 0;
}

.table {
  background: #ffffff;
}

.table-bordered th {
  border-top: 1px solid #ffffff !important;
}

.table th {
  background-color: #f5f5f5;
  background-image: -moz-linear-gradient(top, #f6f6f6, #f3f3f4);
  background-image: -ms-linear-gradient(top, #f6f6f6, #f3f3f4);
  background-image: -webkit-gradient(linear,
      0 0,
      0 100%,
      from(#f6f6f6),
      to(#f3f3f4));
  background-image: -webkit-linear-gradient(top, #f6f6f6, #f3f3f4);
  background-image: -o-linear-gradient(top, #f6f6f6, #f3f3f4);
  background-image: linear-gradient(to bottom, #f6f6f6, #f3f3f4);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f6f6f6', endColorstr='#f3f3f4', GradientType=0);
  border-color: #f3f3f4 #f3f3f4 #cbcbcf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  text-shadow: 0px 1px #ffffff;
  color: #444444;
}

.detailview-table th,
.detailview-table td {
  vertical-align: middle;
}

.detailview-table th label,
.detailview-table td label {
  margin-bottom: 3px;
}

.detailViewContainer {
  padding: 0 10px;
}

.detailViewTitle {
  padding: 1.5% 0 0 1%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  word-wrap: break-word;
}

.detailViewInfo {
  box-shadow: 0 -4px 4px -4px #959595;
  margin-top: 2px;
}

.detailViewInfo .contents {
  padding: 2.2% 0 0 2.2%;
  min-height: 450px;
}

.detailViewInfo .details {
  min-height: 500px;
  padding-right: 1.5%;

  padding-bottom: 10px;
}

.leftBorder {
  border-left: 4px solid #1560bd;
  min-height: 700px;
}

.marginLeftZero {
  margin-left: 0 !important;
  padding-left: 0 !important;
  margin-right: 0 !important;
  padding-right: 0 !important;
}

.detailViewInfo .related .nav-pills .active>a,
.detailViewInfo .related .nav-pills .active>a:hover {
  background: #1560bd;
  border-radius: 1px;
  margin-top: 0;
  margin-bottom: 2px;
}

.contentsDiv {
  min-height: 500px;
  background: #fafafb;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  word-wrap: break-word;
  border-left: 1px solid #e6e6e6;
  position: relative;
}

.mainContainer {
  min-height: 500px;
  margin-top: 05px;
  background: #eeeff2;
  min-width: 1200px;
}

.mainContainer>.col-md-2 {
  padding: 0 1.06383%;
  height: auto !important;
  min-width: 174px;
  padding-left: 0;
}

.mainContainer .contentsDiv {
  min-height: 500px;
  background: #fafafb;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  word-wrap: break-word;
  border-left: 1px solid #e6e6e6;
  position: relative;
}

.medium,
.table td.medium,
.listViewEntries td.medium,
td.listViewEntryValue.medium {
  padding: 5px 6px !important;
}

.contentHeader {
  padding: 15px;
  padding-left: 5px;
  line-height: 20px;
}

.textOverflowEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 9em;
}

.cancelLink {
  cursor: pointer;
  text-decoration: none !important;
  color: #aa3434;
  margin: 6px 8px;
  font-size: 12px !important;
}

.cancelLink:hover {
  color: #aa3434 !important;
}

.linkbutton {
  background: none !important;
  border: none;
  padding: 0 !important;
  font: inherit;
  /*border is optional*/
  border-bottom: 1px solid #444;
  cursor: pointer;
}

/*ui grid custome style*/

.ui-grid-cell-contents {
  padding: 5px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  white-space: nowrap;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 60px;
  font-size: 14px;
}

.ui-grid-filter-container {
  position: relative;
  margin-top: -25px;
  padding: inherit;
}

.ui-grid-header-cell {
  height: auto;
}

/*animation*/

/*.ng-enter {
    -moz-transition: 0.75s;
    -o-transition: 0.75s;
    -webkit-transition: 0.75s;
    transition: 0.75s;
    -ms-opacity: 0;
    opacity: 0;

}

.ng-enter-stragger {
    transition-delay: 0.1s;
}

.ng-enter-active {
    -ms-opacity: 1;
    opacity: 1;
}


.ng-leave {
     -moz-transition: 0.75s;
    -o-transition: 0.75s;
    -webkit-transition: 0.75s;
    transition: 0.75s;
    -ms-opacity: 1;
    opacity: 1;
}


.ng-leave-stragger {
    transition-delay: 0.1s;
}

.ng-leave-active {
      -ms-opacity: 0;
    opacity: 0;

}*/

/*source
 http://dfsq.github.io/ngView-animation-effects/app/#/code*/

.slide {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.slide.ng-enter,
.slide.ng-leave {
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.slide.ng-enter {
  left: 100%;
}

.slide.ng-enter-active {
  left: 0;
}

.slide.ng-leave {
  left: 0;
}

.slide.ng-leave-active {
  left: -100%;
}

.pop {
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.pop.ng-enter,
.pop.ng-leave {
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.pop.ng-enter {
  -webkit-transform: scale(0);
  transform: scale(0);
}

.pop.ng-enter-active,
.pop.ng-leave,
.pop.ng-leave-active {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.pop.ng-leave-active {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.slide-reveal.ng-enter {
  -webkit-transition: 0.5s linear all;
  -moz-transition: 0.5s linear all;
  -o-transition: 0.5s linear all;
  -ms-transition: 0.5s linear all;
  transition: 0.5s linear all;
  -ms-opacity: 0.5;
  opacity: 0.5;
  position: relative;
  opacity: 0;
  top: 10px;
}

.slide-reveal.ng-enter.ng-enter-active,
.slide-reveal.ng-enter-active {
  top: 0;
  -ms-opacity: 1;
  opacity: 1;
}

/*form Sroll in Modal*/
#modal-body {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

#modal-xlg {
  max-width: 80%;
}

/* To set margin of upper form in technician ticket edit view*/

div.tblmar {
  font-family: Verdana;
  margin-left: 26px;
  margin-right: 10px;
}

.tbl_nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.marginRow {
  margin-bottom: 0px !important;
}

#btn-print {
  background-color: #546672;
  border-color: #546672;
  border-radius: 1px;
}

#btn-estimate {
  background-color: #65b688;
  border-color: #65b688;
  border-radius: 1px;
  color: #fff;
}

#btn-addParts {
  background-color: #50abc2;
  border-color: #50abc2;
  border-radius: 1px;
}

#estimateFormFonts {
  font-size: 13px;
}

.changPassword {
  margin-left: 260px;
}

.btn-margin {
  margin: 5px;
}

.btn-secondary {
  color: #373a3c;
  background-color: #fff;
  border-color: #ccc;
}

.btn-secondary:hover {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.btn-secondary:active,
.btn-secondary.active,
.open>.btn-secondary.dropdown-toggle {
  color: #373a3c;
  background-color: #e6e6e6;
  background-image: none;
  border-color: #adadad;
}

.btn-secondary:active:hover,
.btn-secondary:active:focus,
.btn-secondary:active.focus,
.btn-secondary.active:hover,
.btn-secondary.active:focus,
.btn-secondary.active.focus,
.open>.btn-secondary.dropdown-toggle:hover,
.open>.btn-secondary.dropdown-toggle:focus,
.open>.btn-secondary.dropdown-toggle.focus {
  color: #373a3c;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}

.btn-secondary.disabled:focus,
.btn-secondary.disabled.focus,
.btn-secondary:disabled:focus,
.btn-secondary:disabled.focus {
  background-color: #fff;
  border-color: #ccc;
}

.btn-secondary.disabled:hover,
.btn-secondary:disabled:hover {
  background-color: #fff;
  border-color: #ccc;
}

@media screen {
  #printThisElement {
    display: none;
  }
}

@media print {
  h1 {
    color: #65b688;
    margin: 200px;
    margin-bottom: 2rem;
    line-height: 1.5;
  }

  nav,
  aside {
    display: none;
  }

  article {
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
  }

  @page {
    margin: 2cm;
  }

  table {
    border-bottom: #444444;
    border-width: 12px;
    page-break-inside: avoid;
  }
}

@media print {
  @page {
    margin: 1cm;
  }
}

@media print {

  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }

  .col-sm-12 {
    width: 100%;
  }

  .col-sm-11 {
    width: 91.66666667%;
  }

  .col-sm-10 {
    width: 83.33333333%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-8 {
    width: 66.66666667%;
  }

  .col-sm-7 {
    width: 58.33333333%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-5 {
    width: 41.66666667%;
  }

  .col-sm-4 {
    width: 33.33333333%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-2 {
    width: 16.66666667%;
  }

  .col-sm-1 {
    width: 8.33333333%;
  }

  .col-sm-pull-12 {
    right: 100%;
  }

  .col-sm-pull-11 {
    right: 91.66666667%;
  }

  .col-sm-pull-10 {
    right: 83.33333333%;
  }

  .col-sm-pull-9 {
    right: 75%;
  }

  .col-sm-pull-8 {
    right: 66.66666667%;
  }

  .col-sm-pull-7 {
    right: 58.33333333%;
  }

  .col-sm-pull-6 {
    right: 50%;
  }

  .col-sm-pull-5 {
    right: 41.66666667%;
  }

  .col-sm-pull-4 {
    right: 33.33333333%;
  }

  .col-sm-pull-3 {
    right: 25%;
  }

  .col-sm-pull-2 {
    right: 16.66666667%;
  }

  .col-sm-pull-1 {
    right: 8.33333333%;
  }

  .col-sm-pull-0 {
    right: auto;
  }

  .col-sm-push-12 {
    left: 100%;
  }

  .col-sm-push-11 {
    left: 91.66666667%;
  }

  .col-sm-push-10 {
    left: 83.33333333%;
  }

  .col-sm-push-9 {
    left: 75%;
  }

  .col-sm-push-8 {
    left: 66.66666667%;
  }

  .col-sm-push-7 {
    left: 58.33333333%;
  }

  .col-sm-push-6 {
    left: 50%;
  }

  .col-sm-push-5 {
    left: 41.66666667%;
  }

  .col-sm-push-4 {
    left: 33.33333333%;
  }

  .col-sm-push-3 {
    left: 25%;
  }

  .col-sm-push-2 {
    left: 16.66666667%;
  }

  .col-sm-push-1 {
    left: 8.33333333%;
  }

  .col-sm-push-0 {
    left: auto;
  }

  .col-sm-offset-12 {
    margin-left: 100%;
  }

  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-sm-offset-0 {
    margin-left: 0%;
  }

  .visible-xs {
    display: none !important;
  }

  .hidden-xs {
    display: block !important;
  }

  table.hidden-xs {
    display: table;
  }

  tr.hidden-xs {
    display: table-row !important;
  }

  th.hidden-xs,
  td.hidden-xs {
    display: table-cell !important;
  }

  .hidden-xs.hidden-print {
    display: none !important;
  }

  .hidden-sm {
    display: none !important;
  }

  .visible-sm {
    display: block !important;
  }

  table.visible-sm {
    display: table;
  }

  tr.visible-sm {
    display: table-row !important;
  }

  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
    margin-left: 10%;
  }

  .tablePrintBorder {
    border: 1px solid black;
    width: 100%;
  }

  .cellborderPrint {
    border: 1px solid black;
  }
}

.sparks-info div {
  margin: 1px;
}

/*progress tracker*/
#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  /*CSS counters to number the steps*/
  counter-reset: step;
}

#progressbar li {
  list-style-type: none;
  color: #e6e6e6;
  text-transform: uppercase;
  font-size: 10px;
  width: 13%;
  float: left;
  position: relative;
  text-align: center;
  z-index: 20;
}

#progressbar li.active {
  color: #27ae60;
}

#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 50px;
  line-height: 50px;
  display: block;
  font-size: 12px;
  color: #333;
  background: #d2dbe7;
  border-radius: 25px;
  margin: 0 auto 5px auto;
  text-align: center;
  z-index: 20;
  font-weight: bold;
}

/*progressbar connectors*/
#progressbar li:after {
  content: "";
  width: 100%;
  height: 3px;
  background: silver;
  position: absolute;
  left: -25%;
  top: 25px;
  z-index: -1;
  /*put it behind the numbers*/
}

#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none;
}

/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active:before,
#progressbar li.active:after {
  background: #27ae60;
  color: white;
  top: 25px;
}

#progressbar h5 {
  font-size: 12px;
}

#progressbar span {
  display: block;
  font-weight: bold;
  font-size: 10px;
}

/*for second lago in iindex*/
#logo-group span#activity {
  border: 0px;
  /*padding-left: 10px;*/
  background: 0;
}

#header {
  background: white;
}

.panel-heading h1 {
  font-size: 45px;
}

/*.btn-primary {
    background: #00cccc;
}*/

.userdetails {
  display: block;
  padding: 4px;
  margin-top: 8px;
  color: #cac5c5;
  font-weight: bold;
}

.login-info {
  height: 70px;
}

.chosen-container {
  width: 100% !important;
}

hr.style-six {
  border: 0;
  height: 0;
  border-top: 1px solid #000000;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #ffffff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.headingText {
  background-color: #1560bd;
  border: 0;
  -ms-border-radius: 5px;
  border-radius: 5px;
  color: white;
  display: inline-block;
  font-weight: bold;
  padding: 7px 10px 7px 10px;
  font-size: 12px;

  text-transform: uppercase;
}

.gridloading {
  -ms-opacity: 6;
  opacity: 6;
  position: absolute;
  top: 20%;
  left: 20%;
  width: 60%;
  height: 50%;

  text-align: center;
  font-size: 24px;
  display: table;
}

/*
  Lastly this is the actual message text. It uses
  display: table-cell so the vertical alignment
  works properly.
*/
.gridloading span {
  display: table-cell;
  vertical-align: middle;
}

/*cards for dashbaoard*/
.card {
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
  background-color: #ffffff;
  margin-bottom: 30px;
}

.card .image {
  width: 100%;
  overflow: hidden;
  height: 260px;
  border-radius: 4px 4px 0 0;
  position: relative;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.card .image img {
  width: 100%;
}

.card .filter {
  position: absolute;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.68);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  opacity: 0;
  filter: alpha(opacity=0);
}

.card .filter .btn {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.card:hover .filter {
  opacity: 1;
  filter: alpha(opacity=100);
}

.card .btn-hover {
  opacity: 0;
  filter: alpha(opacity=0);
}

.card:hover .btn-hover {
  opacity: 1;
  filter: alpha(opacity=100);
}

.card .content {
  padding: 15px 15px 10px 15px;
}

.card .header {
  padding: 15px 15px 0;
}

.card .category,
.card label {
  font-size: 14px;
  font-weight: 400;
  /* color: #9a9a9a; */
  margin-bottom: 0px;
}

.card .category i,
.card label i {
  font-size: 16px;
}

.card label {
  font-size: 12px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.card .title {
  margin: 0;
  color: #333333;
  font-weight: 300;
}

.card .avatar {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 5px;
}

.card .description {
  font-size: 14px;
  color: #333;
}

.card .footer {
  padding: 0;
  background-color: transparent;
  line-height: 30px;
}

.card .footer .legend {
  padding: 5px 0;
}

.card .footer hr {
  margin-top: 5px;
  margin-bottom: 5px;
}

.card .stats {
  color: #a9a9a9;
}

.card .footer div {
  display: inline-block;
}

.card .author {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.card .author i {
  font-size: 14px;
}

.card h6 {
  font-size: 12px;
  margin: 0;
}

.card.card-separator:after {
  height: 100%;
  right: -15px;
  top: 0;
  width: 1px;
  background-color: #dddddd;
  content: "";
  position: absolute;
}

.card .ct-chart {
  margin: 30px 0 30px;
  height: 245px;
}

.card .table tbody td:first-child,
.card .table thead th:first-child {
  padding-left: 15px;
}

.card .table tbody td:last-child,
.card .table thead th:last-child {
  padding-right: 15px;
}

.card .alert {
  border-radius: 4px;
  position: relative;
}

.card .alert.alert-with-icon {
  padding-left: 65px;
}

.card-user .image {
  height: 110px;
}

.card-user .image-plain {
  height: 0;
  margin-top: 110px;
}

.card-user .author {
  text-align: center;
  text-transform: none;
  margin-top: -70px;
}

.card-user .avatar {
  width: 124px;
  height: 124px;
  border: 5px solid #ffffff;
  position: relative;
  margin-bottom: 15px;
}

.card-user .avatar.border-gray {
  border-color: #eeeeee;
}

.card-user .title {
  line-height: 24px;
}

.card-user .content {
  min-height: 240px;
}

.card-user .footer,
.card-price .footer {
  padding: 5px 15px 10px;
}

.card-user hr,
.card-price hr {
  margin: 5px 15px;
}

.card-plain {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
}

.card-plain .image {
  border-radius: 4px;
}

.greybg {
  background: #ebeff2;
}

.button-border-style {
  border-radius: 6px !important;
  padding-top: 4px;
  height: 26px;
}

.center {
  text-align: center;
}

.errorRow {
  color: red;
}

.edit:read-write {
  background: lightgreen;
}

/*#region Legends*/

/* basic positioning */
.legend {
  list-style: none;
}

.legend li {
  float: left;
  margin-right: 10px;
}

.legend span {
  border: 1px solid #ccc;
  float: left;
  width: 12px;
  height: 12px;
  margin: 2px;
}

/* your colors */
.legend .ber {
  background-color: #ff1a1a;
  font-weight: bold;
  font-size: larger;
}

.legend .reRepair {
  background-color: #0000ff;
  font-weight: bold;
  font-size: larger;
}

/*#endregion */

/*#region Cards*/

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-red::after {
  background: #ff5370;
}

.card-blue:after,
.card-green:after,
.card-red:after,
.card-yellow:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.main-body .page-wrapper {
  padding: 0.6rem 1.5rem;
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card {
  /* border-radius: 5px; */
  -webkit-box-shadow: 0 0 5px 0 rgba(43, 43, 43, 0.1),
    0 11px 6px -7px rgba(43, 43, 43, 0.1);
  box-shadow: 0 0 5px 0 rgba(43, 43, 43, 0.1),
    0 11px 6px -7px rgba(43, 43, 43, 0.1);
  border: none;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

/*#endregion */

/*#region Leave Legends*/
.lbltext {
  margin-left: 20px;
  font-size: 12px;
  font-weight: bolder;
}

.annualLeave {
  margin-top: 12px;
  margin-left: 10px;
  background-color: rgb(101, 180, 187);
  width: 54px;
  height: 53px;
  text-align: center;
  border-radius: 11px;
  padding: 10px;
  font-size: 12px;
  font-weight: bold;
  color: black;
  border: 2px solid whitesmo;
}

.sickLeave {
  margin: 2px;
  background-color: teal;
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 50px;
  padding: 5px;
  font-size: 10px;
  font-weight: bold;
  color: white;
  border: 2px solid whitesmoke;
}

.lwp {
  margin: 2px;
  background-color: red;
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 50px;
  padding: 4px;
  font-size: 10px;
  font-weight: bold;
  color: white;
  border: 2px solid whitesmoke;
}

/*#endregion */

FIELDSET {
  margin: 8px;
  border: 2px solid silver;
  padding: 8px;
  border-radius: 4px;
}

legend {
  /* background-color: #4d7886; */
  color: #fff;
  padding: 3px 6px;
  width: auto;
  /* border-radius: 2px;
  border: 1px solid #4d7886; */
  border-bottom: 0px solid white;
  margin: 0;
}

/*#region Comment Box */
/*
body{margin-top:20px;}

.comment-wrapper .panel-body {
    max-height:650px;
    overflow:auto;
}

.comment-wrapper .media-list .media img {
    width:64px;
    height:64px;
    border:2px solid #e5e7e8;
}

.comment-wrapper .media-list .media {
    border-bottom:1px dashed #efefef;
    margin-bottom:25px;
} */

/*#endregion */

/*#region TextWithDots */
.text {
  display: block;
  width: 600px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/*#endregion */

/* .clone {
	position:absolute;
	top:0;
	left:0;
	pointer-events:none;
}
.clone th, .clone td {
	visibility:hidden
}
.clone td, .clone th {
	border-color:transparent
}
.clone tbody th {
	visibility:visible;
	color:red;
}
.clone .fixed-side {
	border:1px solid #000;
	background:#eee;
	visibility:visible;
}
.clone thead, .clone tfoot{background:transparent;} */

fieldset.groupbox-border {
  border: 1px groove #ddd !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 1.2em 1.2em 1.2em 1.2em !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
}

legend.groupbox-border {
  font-size: 1.2em !important;
  font-weight: bold !important;
  text-align: left !important;
  width: auto;
  padding: 0px 10px 0px 10px;
  border-bottom: none;
}

/* salary components css */
.fillheader {
  background-color: #fdfbfb;
  color: black;
  padding: 8px 30px;
  overflow: visible;
  border-bottom: 1px solid #ededed;
  height: 65px;
}

label {
  font-size: 15px;
}

.labeltext {
  margin-left: 25px;
  margin-top: 21px;
}

.mandatory-field {
  color: red;
}

.mtops {
  margin-top: 10px !important;
}

.labletextmargin {
  margin-top: 19px;
}

.headertextmargin {
  margin-left: 12px;
}

.subtitlemarginleft {
  margin-left: 12px;
  padding: 4px;
}

.font-small {
  font-size: 13px !important;
}

.right-view-text {
  padding: 10px;
  margin-top: -8px;
  border-radius: 4px;
  background-color: #eef7ff;
  color: #11283d;
  transition: overflow 2s linear;
}

.form-style-6 {
  font: 95% "Roboto", sans-serif;
  max-width: 100%;
  margin-left: 3px;
  border-radius: 4px;
  margin-bottom: 5px;
  padding: 7px;
}

.form-style-6 h1 {
  background: #43d1af;
  padding: 20px 0;
  font-size: 140%;
  font-weight: 300;
  text-align: center;
  color: #fff;
  margin: -16px -16px 16px -16px;
}

.form-style-6 input[type="text"],
.form-style-6 input[type="date"],
.form-style-6 input[type="datetime"],
.form-style-6 input[type="email"],
.form-style-6 input[type="number"],
.form-style-6 input[type="search"],
.form-style-6 input[type="time"],
.form-style-6 input[type="url"],
.form-style-6 textarea,
.form-style-6 select {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  outline: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;
  background: #fff;
  margin-bottom: 4%;
  border: 1px solid #ccc;
  padding: 10px 20px;
  border-radius: 4px;
  color: #555;
  font: 95% "Roboto", sans-serif;
}

.form-style-6 input[type="text"]:focus,
.form-style-6 input[type="date"]:focus,
.form-style-6 input[type="datetime"]:focus,
.form-style-6 input[type="email"]:focus,
.form-style-6 input[type="number"]:focus,
.form-style-6 input[type="search"]:focus,
.form-style-6 input[type="time"]:focus,
.form-style-6 input[type="url"]:focus,
.form-style-6 textarea:focus,
.form-style-6 select:focus {
  box-shadow: 20 1.9px 1.5px #1b9ffc;

  border: 1px solid #1b9ffc;
  border-radius: 4px;
}

input[type="text"],
select {
  width: 100%;
  padding: 10px 20px;

  display: inline-block;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.form-style-6 input[type="submit"],
.form-style-6 input[type="button"] {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;
  padding: 3%;
  background: #43d1af;
  border-bottom: 2px solid #30c29e;
  border-top-style: none;
  border-right-style: none;
  border-left-style: none;
  color: #fff;
}

.form-style-6 input[type="submit"]:hover,
.form-style-6 input[type="button"]:hover {
  background: #2ebc99;
}

.custom-select {
  position: relative;
  font-family: Arial;
}

.custom-select select {
  display: none;
  /*hide original SELECT element: */
}

.select-selected {
  background-color: DodgerBlue;
}

/* Style the arrow inside the select element: */
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/* style the items (options), including the selected item: */
.select-items div,
.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}

/* Style items (options): */
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.bubble {
  background-color: #eef7ff;
  border-radius: 5px;

  display: inline-block;
  padding: 10px 22px;
  position: relative;
  vertical-align: top;
}

.bubble::before {
  background-color: #eef7ff;
  content: "\00a0";
  display: block;
  height: 16px;
  position: absolute;
  top: 11px;
  transform: rotate(29deg) skew(-35deg);
  -moz-transform: rotate(29deg) skew(-35deg);
  -ms-transform: rotate(29deg) skew(-35deg);
  -o-transform: rotate(29deg) skew(-35deg);
  -webkit-transform: rotate(29deg) skew(-35deg);
  width: 20px;
}

.me {
  float: left;
}

.me::before {
  left: -9px;
}

.fontsizestd {
  font-size: 16px;
  font-weight: w600;
  color: black;
}

.detail-table {
  vertical-align: middle;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: white;
  text-align: right;
  font-family: AvertaRegular, Helvetica, sans-serif;
  border: none;
}

.tableFont {
  font-family: AvertaRegular, Helvetica, sans-serif;
}

.detail-table-header {
  font-weight: bolder;
  text-transform: uppercase;
}

.paymentdetailContainer {
  background-color: rgb(255, 255, 255);
  width: auto;
  padding: 10px;
  margin-left: 8px;
  border-top: 1px solid rgb(219, 219, 219);
}

.paymentdetailContainer:hover {
  background-color: #f7f7f7;
}

.svgiconbackground {
  background-color: #e8e8e8;
  height: 35px;
  width: 35px;
  border-radius: 50%;
}

.svgicon {
  size: 12px;
  height: 35px;
  font-size: 10px;
  /* height: 32px; */
  text-align: end;
  align-self: flex-end;
  padding: -16px;
  padding: 5px;
  /* background-color: red; */
  fill: white;
  /* color: white; */
}

/* .myButton {
	-moz-box-shadow:inset 0px -3px 7px 0px #29bbff;
	-webkit-box-shadow:inset 0px -3px 7px 0px #29bbff;
	box-shadow:inset 0px -3px 7px 0px #29bbff;
	background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #2dabf9), color-stop(1, #0688fa));
	background:-moz-linear-gradient(top, #2dabf9 5%, #2e9eff 100%);
	background:-webkit-linear-gradient(top, #2dabf9 5%, #0688fa 100%);
	background:-o-linear-gradient(top, #2dabf9 5%, #0688fa 100%);
	background:-ms-linear-gradient(top, #2dabf9 5%, #0688fa 100%);
	background:linear-gradient(to bottom, #2dabf9 5%, #0688fa 100%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#2dabf9', endColorstr='#0688fa',GradientType=0);
	background-color:#2dabf9;
	-webkit-border-radius:3px;
	-moz-border-radius:3px;
	border-radius:3px;
	border:1px solid #40b3ff;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:14px;
	padding:6px 15px;
	text-decoration:none;
	text-shadow: 0px 1px 0px #636363;
	&:hover {
	background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #0688fa), color-stop(1, #2dabf9));
	background:-moz-linear-gradient(top, #0688fa 5%, #2dabf9 100%);
	background:-webkit-linear-gradient(top, #0688fa 5%, #2dabf9 100%);
	background:-o-linear-gradient(top, #0688fa 5%, #2dabf9 100%);
	background:-ms-linear-gradient(top, #0688fa 5%, #2dabf9 100%);
	background:linear-gradient(to bottom, #0688fa 5%, #2dabf9 100%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#0688fa', endColorstr='#2dabf9',GradientType=0);
	background-color:#0688fa;
}
 &:active {
	position:relative;
	top:1px;
} */

.myButton {
  -moz-box-shadow: 0px 1px 0px 0px #f0f7fa;
  -webkit-box-shadow: 0px 1px 0px 0px #f0f7fa;
  box-shadow: 0px 1px 0px 0px #e6f0f5;
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(0.05, #33bdef),
      color-stop(1, #019ad2));
  background: -moz-linear-gradient(top, #33bdef 5%, #019ad2 100%);
  background: -webkit-linear-gradient(top, #33bdef 5%, #019ad2 100%);
  background: -o-linear-gradient(top, #33bdef 5%, #019ad2 100%);
  background: -ms-linear-gradient(top, #33bdef 5%, #019ad2 100%);
  background: linear-gradient(to bottom, #33bdef 5%, #019ad2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#33bdef', endColorstr='#019ad2', GradientType=0);
  background-color: #33bdef;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 14px;
  font-weight: w500;
  padding: 6px 15px;
  text-decoration: none;

  text-shadow: 0px -1px 0px #9c9c9c;
}

.myButton:hover {
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(0.05, #5099ff),
      color-stop(1, #5099ff));
  background: -moz-linear-gradient(top, #5099ff 5%, #33bdef 100%);
  background: -webkit-linear-gradient(top, #5099ff 5%, #33bdef 100%);
  background: -o-linear-gradient(top, #5099ff 5%, #33bdef 100%);
  background: -ms-linear-gradient(top, #5099ff 5%, #33bdef 100%);
  background: linear-gradient(to bottom, #5099ff 5%, #33bdef 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5099ff', endColorstr='#33bdef', GradientType=0);
  background-color: #5099ff;
}

.myButton:active {
  position: relative;
  top: 3px;
}

.secoundrybutton {
  -moz-box-shadow: inset 0px 1px 0px 0px #ffffff;
  -webkit-box-shadow: inset 0px 1px 0px 0px #ffffff;
  box-shadow: inset 0px 1px 0px 0px #ffffff;
  background-color: #ffffff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #dcdcdc;
  display: inline-block;
  cursor: pointer;
  color: #383838;
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  padding: 6px 15px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #ffffff;
}

.secoundrybutton:hover {
  background-color: #f6f6f6;
}

.secoundrybutton:active {
  position: relative;
  top: 3px;
}

.btn-glyphicon {
  padding: 8px;
  background: #ffffff;
  margin-right: 4px;
}

.icon-btn {
  padding: 1px 15px 3px 2px;
  border-radius: 50px;
}

.circlepencil {
  background-color: #ececec;
  /* font-size: 59px; */
  height: 35px;
  width: 35px;
  padding: 10px;
  margin-top: 8px;
  border-radius: 50px;
  color: #545454;
  font-size: 15px;
}

div#top_nav a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -3px;
  left: 0;
  /* background-color: #4E98FF; */
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.15s ease-in-out 0s;
  transition: all 0.15s ease-in-out 0s;
}

.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 12px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px;
}

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}

.btn-circle.btn-lg-backgroundround {
  width: 50px;
  height: 50px;
  padding: 10px 0px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px;
  background-color: transparent;
  color: #19b5e4;
  border: 3px solid;
}

.btn-circle.btn-lg-backgroundround:active {
  position: relative;
  top: 3px;
}

.rwd-table {
  margin: auto;
  min-width: 100%;
  max-width: 100%;
  border-collapse: collapse;
}

.rwd-table tr:first-child {
  /* border-top: none; */
  /* border-bottom: 1px solid #ddd; */
  background: #ffffff;
  color: #151414;
  border-color: #e8e8e8;
  /* border-top: 1px solid #888585; */
  /* border-bottom: 1px solid #ddd; */

  border-color: #e8e8e8;
  /* border-top-color: #e8e8e8; */
}

.rwd-table tr {
  /* border-top: 1px solid #ddd; */
  /* border-bottom: 1px solid #ddd; */
  /* background-color: #f5f9fc; */
  background-color: white;
  font-family: "Roboto", sans-serif;
}

.rwd-table tr:nth-child(odd):not(:first-child) {
  background-color: white;
  font-family: "Roboto", sans-serif;
}

.rwd-table th {
  display: none;
  font-size: 17px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}

.rwd-table td {
  display: block;
  font-family: "Roboto", sans-serif;
  /* height: inherit;
    line-height: 1.2;
    padding: 12px 8px; */
}

.rwd-table td:first-child {
  margin-top: 0.5em;
  font-family: "Roboto", sans-serif;
}

.rwd-table td:last-child {
  margin-bottom: 0.5em;
  font-family: "Roboto", sans-serif;
}

.rwd-table td:before {
  content: attr(data-th) ": ";
  font-weight: bold;
  /* width: 120px; */
  display: inline-block;
  color: #000;
  font-family: "Roboto", sans-serif;
}

.rwd-table th,
.rwd-table td {
  text-align: left;
  font-family: "Roboto", sans-serif;
}

.rwd-table {
  color: #333;
  /* border-radius: .4em; */
  overflow: hidden;
  font-family: "Roboto", sans-serif;
}

.rwd-table tr {
  border-color: whitesmoke;
  font-family: "Roboto", sans-serif;
}

.rwd-table th,
.rwd-table td {
  padding: 0.5em 1em;
  font-family: "Roboto", sans-serif;

  /* height: inherit;
    line-height: 1.2;
    padding: 12px 8px; */
}

@media screen and (max-width: 601px) {
  .rwd-table tr:nth-child(2) {
    border-top: none;
  }
}

@media screen and (min-width: 600px) {
  .rwd-table tr:hover:not(:first-child) {
    background-color: #fcfcfc;
  }

  .rwd-table td:before {
    display: none;
  }

  .rwd-table th,
  .rwd-table td {
    display: table-cell;
    padding: 0.25em 0.5em;
  }

  .rwd-table th:first-child,
  .rwd-table td:first-child {
    padding-left: 0;
  }

  .rwd-table th:last-child,
  .rwd-table td:last-child {
    padding-right: 0;
  }

  .rwd-table th,
  .rwd-table td {
    padding: 12px !important;
  }
}

/* THE END OF THE IMPORTANT STUFF */

/* Basic Styling */

/* h1 {
  text-align: center;
  font-size: 2.4em;
  color: #f2f2f2;
} */
.container {
  display: block;
  text-align: center;
}

/* h3 {
  display: inline-block;
  position: relative;
  text-align: center;
  font-size: 1.5em;
  color: #cecece;
}
h3:before {
  content: "\25C0";
  position: absolute;
  left: -50px;
  -webkit-animation: leftRight 2s linear infinite;
  animation: leftRight 2s linear infinite;
}
h3:after {
  content: "\25b6";
  position: absolute;
  right: -50px;
  -webkit-animation: leftRight 2s linear infinite reverse;
  animation: leftRight 2s linear infinite reverse;
} */
@-webkit-keyframes leftRight {
  0% {
    -webkit-transform: translateX(0);
  }

  25% {
    -webkit-transform: translateX(-10px);
  }

  75% {
    -webkit-transform: translateX(10px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}

@keyframes leftRight {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-10px);
  }

  75% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}

.containercentertext {
  text-align: center;
  height: 85px;
  background-color: white;
  width: 100%;
  border-bottom: 1px solid #e8e8e8;
  border-top: 1px solid whitesmoke;
  padding: 17px;
  background: url(https://css.zohostatic.in/zfpayroll/zpayroll/assets/images/confetti-getting-started.svg) center no-repeat;
  font-size: 23px;
  background-size: cover;
}

.buttonalign {
  text-align: left;
  margin-left: 5px;
}

/* .tableborder{
  border: 1px solid black;
}
.container{
  margin-top: 10px;
}
.mainContainHeaderMargin-Top{
  margin-top: -18px;
}
.fontWeight{
  font-weight: bold;
}
.ContainAlign-Center{
  text-align: center;
}
.background-color{
  background-color: rgb(177, 174, 174);
}
tr.noborder td{
  border-top: 0px !important;
  border-bottom: 0px !important;
  border-left: 0px !important;

} */
.ContainAlign-Center {
  text-align: center;
}

tr.noborder td {
  border-top: 0px !important;
  border-bottom: 0px !important;
  border-left: 0px !important;
}

.rightborder {
  border-right: 2px solid rgb(231, 231, 231) !important;
}

.leftborder {
  border-left: 1px solid white !important;
}

.bor {
  border: solid #000 !important;
  border-width: 1px 0 0 1px !important;
}

/* #tabl2 th, td {
  border:solid #000 !important;
  border-width:0 1px 1px 0 !important;
}  */

#customers {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border-right: 1px solid black;

  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: white;
}

#customers tr:hover {
  background-color: rgb(255, 255, 255);
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  text-align: left;
  background-color: rgb(177, 174, 174);
  color: white;
}

#customers tr:last-child td {
  border-bottom: 1px solid black;
}

.customerstabletopborder {
  border-top: 1px solid black;
}

@media screen and (min-width: 600px) {

  .rwd-table th,
  .rwd-table td {
    padding: 12px !important;
  }
}

.font-moderate {
  font-size: 18px !important;
}

.font-semibold {
  font-family: "AvertaSemibold", "AvertaRegular";
}

.font-medium {
  font-size: 16px !important;
  font-weight: 550;
}

.checkBoxLable {
  font-weight: 550;
  font-size: 13px;
}

.table-scroll {
  position: relative;
  max-width: 100%;
  margin: auto;
  overflow: hidden;
  min-height: 50%;
  max-height: 70%;
  /* border:1px solid #000; */
}

.table-wrap {
  width: 99%;
  min-height: 50%;
  /* max-height: 70%; */
  max-height: 100%;
  overflow: auto;
}

.table-scroll table {
  width: 100%;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
}

.table-scroll th,
.table-scroll td {
  padding: 5px 10px;
  /* border:1px solid #000; */
  background: #fff;
  white-space: nowrap;
  vertical-align: top;
}

.table-scroll thead,
.table-scroll tfoot {
  background: #f9f9f9;
}

.table-wrapper-scroll-y {
  display: block;
}

.my-custom-scrollbar {
  position: relative;
  height: 400px;
  overflow: auto;
}

.backgroundcolor {
  background-color: white;
}

label {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
}

.btn {
  font-family: "Roboto", sans-serif;
}

span {
  font-family: "Roboto", sans-serif;
}

/*
.table tr:first-child {

  border-bottom: 1px solid #ddd;
  background: #ffffff;
  color: #151414;
  border-color: #e8e8e8;
  border-top: 1px solid #888585;
    border-bottom: 1px solid #ddd;

    border-color: #e8e8e8;
    border-top-color: #e8e8e8;
} */

.table-striped thead th {
  background: white;
  font-size: 16px;
  /* vertical-align: middle;

  height: 50px; */
  font-weight: 500;
  /* line-height: 50px; */
}

.table thead th:first-of-type {
  border-color: #f7f7f7;
  border-bottom: 1px solid #ececec;
  border-top: 1px solid #f7f7f7;
}

.rwd-table thead th {
  border-color: #f7f7f7;
  border-bottom: 1px solid #e8e8e8;
  border-top: 1px solid #e8e8e8;
}

.table thead th {
  border-color: #f7f7f7;
  border-bottom: 1px solid #e8e8e8;
  border-top: 1px solid #e8e8e8;
}

.table {
  border-collapse: collapse;
}

#textlebel {
  padding-top: 9px;
}

#inputfield {
  height: 40px;
}

.smart-form .input input,
.smart-form .select select,
.smart-form .textarea textarea {
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 5px 10px;
  outline: 0;
  border-width: 1px;
  border-style: solid;
  border-radius: 0;
  background: #fff;
  font: 13px/16px "Open Sans", Helvetica, Arial, sans-serif;
  color: #404040;
  appearance: normal;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.smart-form .icon-append,
.smart-form .icon-prepend {
  position: absolute;
  top: 5px;
  width: 22px;
  height: 20px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.smart-form .icon-append {
  right: 5px;
  padding-left: 3px;
  padding: 5px;
  /* margin: 2px; */
  padding-top: 5px;
  padding-bottom: 5px;
  border-left-width: 1px;
  border-left-style: solid;
}

.grtable thead {
  padding: 0.5em 1em;
}

.grtable>thead>tr>th {
  vertical-align: bottom;
  padding: 0.5em 1em;
  border-bottom: 1px solid #ddd;
  /* min-height: 75px; */
}

.grtable>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
  padding: 0.5em 1em;
  line-height: 2;
  /* line-height: 1.42857143; */
  vertical-align: top;
  /* border-top: 1px solid #ddd; */
}

.grtable {
  border-bottom: 0px !important;
}

.grtable>thead>tr>th {
  padding: 0.5em 1em;
  line-height: 2;
  vertical-align: top;
  background-color: white;
  border-top: 1px solid #ddd;
}

.grtable tr>td {
  padding: 0.5em 1em;
  line-height: 2;
  background-color: white;
  border: 0px !important;
}

.grtable>thead:first-child>tr:first-child>th {
  border-top: 0;
  line-height: 2;
  background-color: white;
  padding: 0.5em 1em;
}

.containers {
  height: 70px;
  /* top: 50%;
  left: 50%; */

  display: grid;
  grid-template-columns: auto auto auto auto;

  overflow: hidden;
  border-radius: 16px;
}

.profile-card {
  display: flex;
  /*   flex-direction: row; */
  width: auto;
  background: #1ccdff;
  height: 70px;
  border-radius: 16px;
  padding: 0px 0px 0px 0px;
}

.image-container {
  text-align: center;
}

.centertitle {
  padding: 10px 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: white;
}

.text-container {
  width: 130px;
  margin-left: 14px;
}

.text-container h1 {
  font-size: 18px;
  font-weight: bold;
  padding-top: 8px;
  padding-bottom: 8px;
  color: #263238;
}

.text-container h3 {
  font-size: 18px;
  font-weight: lighter;
  color: #263238;
}

.bottomroundCardmain {
  margin-top: 5px;
  min-height: 20px;
  padding: 5px;
  margin-right: 4px;
  border-radius: 6px;
  background-color: white;
  text-align: center;
}

.bottomroundCard {
  margin-top: 8px;
  height: 17px;
  width: 87%;
  padding: 5px;
  margin-right: 4px;
  border-radius: 6px;
  background-color: white;
  text-align: center;
}

.smallcard {
  display: inline-block;
  width: auto;
}

.bigcardBackgroundColor {
  background-color: #a0e0f2;
  color: #a0e0f2;
}

.wrapper {
  max-width: auto;

  background-color: #fff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.06);
}

.header2 {
  padding: 10px 11px 0;
  text-align: left;
  font-size: 1em;
  font-weight: 500;
}

.title {
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 500;
  line-height: 0.1;
}

.cards {
  display: inline-block;
  width: 150px;
  position: relative;
  height: 120px;

  background-color: white;
  padding: 0px;

  text-align: center;
}

.cards>h1 {
  font-size: 16px;
  color: rgb(87, 87, 87);
}

.cards2 {
  display: inline-block;
  width: 115px;
  position: relative;
  height: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: white;
  border-right: 1px solid #eee;
  border-image: linear-gradient(to bottom,
      #fff 0,
      #eee 25%,
      #eee 50%,
      #eee 75%,
      #fff 100%);
  border-image-slice: 1;
  border-left: 0;
  border-right-style: inset;
}

.cards>h4 {
  padding: 0px;
  margin: 0px;
}

.small td:first-child {
  margin-top: 0.5em;
  width: 20px;
  font-family: "Roboto", sans-serif;
}

.cardelevationduties td:first-child {
  color: white;
  font-size: 17px;
  font-weight: 300;
  min-width: 73px;
  height: 35px;
  text-align: center;
  padding: 5px;
  /* width: 86px; */
  margin-left: 20px;
  border-radius: 30px;
  margin-right: 20px;
  /* box-shadow: 0px 5px 100px rgb(239, 239, 240), 0px 0px 0px 0px; */
  /* box-shadow: 0px 8px 60px -10px rgba(13,28,39,0.6); */
}

.cardelevation {
  color: white;
  font-size: 17px;
  font-weight: 300;
  min-width: 73px;
  height: 35px;
  text-align: center;
  padding: 5px;
  /* width: 86px; */
  margin-left: 20px;
  border-radius: 30px;
  margin-right: 20px;
  /* box-shadow: 0px 5px 100px rgb(239, 239, 240), 0px 0px 0px 0px; */
  /* box-shadow: 0px 8px 60px -10px rgba(13,28,39,0.6); */
}

.wrap div:last-child {
  margin-left: auto;
}

.imagcircleelevation {
  box-shadow: 0px 5px 100px rgb(170, 170, 248), 0px 0px 0px 3px;
}

/* In page header */

/* Section headers */
/* h2 {
} */

/* a:hover {
  color: white;
}

a:visited {
  color: #9f9f9f;
}

a:active {
  color: #9f9f9f;
} */

/*iframe {
   Videos */

/* Classes */

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  font-size: 3vh;
}

.left {
  padding-top: 2.5em;
  padding-left: 0.8em;

  margin: 0;
  text-align: left;
  float: left;
}

.right {
  padding-top: 0em;
  padding-right: 0.7em;

  margin: 0;
  text-align: left;
  float: right;
}

.navbar {
  position: static;
  /* overflow: auto; */
  top: 0;
  left: 0;
  width: 100%;
  height: auto;

  /* box-shadow:
      0 0 0.2em #000,
      0 0 0.2em #000,
      0 0 0.4em #000,
      0 0 0.4em #000,
      0 0 0.6em #000,
      0 0 0.6em #000,
      0 0 0.8em #000,
      0 0 0.8em #000;
  font-size: 2vh; */
}

.navbar * {
  position: static;
}

.navbar .icons {
  font-size: 4vh;
  padding-top: 0;
  padding-bottom: 0;
}

/* For navbar quick-links display */
.navbar ul li {
  list-style-type: none;
  display: inline;
}

/* Vertical padding after navbar */
/* TODO: fix - padding is too low when viewport has low width and high height */
.navbar+.cardsss {
  margin-top: 18vh;
}

.cardscheduledashboard {
  font-family: "Roboto", sans-serif;
  background: white;
  max-width: 300px;
  position: relative;
  text-align: left;
  /* width: 275px; */
}

.cardschedule {
  font-family: "Roboto", sans-serif;
  background: white;
  max-width: 300px;
  position: relative;
  text-align: left;
  transition: transform 0.2s;
  width: 275px;
  z-index: 999;
}

.cardschedule:hover {
  -ms-transform: scale(1.4);
  /* IE 9 */
  -webkit-transform: scale(1.4);
  /* Safari 3-8 */
  transform: scale(1.4);
}

.list {
  list-style: none outside none;
  margin: 10px 0 30px;
}

.apps-container {
  /* border: 2px dashed blue; */
  margin: 10px 10px 0 0;
  padding: 5px;
  padding-top: 0px;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  display: inline-block;
  /* min-height: 500px;
  min-width: 150px; */
  width: 100%;
  min-height: 350px;
  height: 60%;

  border-radius: 10px;

  /* -webkit-box-shadow: 0 0 10px #bebcbc; */
  overflow-y: auto;
  background: #f5f7f7;

  left: -5px;
  top: -5px;
}

/* .app {
	width: auto;
	padding: 5px 10px;
	margin: 5px 0;
	border: 2px solid #444;
	border-radius: 5px;
	background-color: #EA8A8A;

	font-size: 1.1em;
	font-weight: bold;
	text-align: center;
	cursor: move;
} */

/***  Extra ***/

body {
  font-family: Verdana, "Trebuchet ms", Tahoma;
}

.logList {
  margin-top: 20px;
  width: 250px;
  min-height: 300px;
  padding: 5px 15px;
  border: 5px solid #000;
  border-radius: 15px;
}

.logItem {
  margin-bottom: 10px;
}

.logList:before {
  content: "log";
  padding: 0 5px;
  position: relative;
  top: -1.1em;
  background-color: #fff;
}

.container {
  width: 750px;
  margin: auto;
}

/* h2 {
	text-align: center;
} */

.floatleft {
  float: left;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
}

.floatright {
  float: right;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
}

.clear {
  clear: both;
}

/* --- Board Card ---- */
.productcard {
  display: inline-block;
  margin: 10px;
  width: 290px;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
}

#product-back {
  width: 290px;
  min-height: 400px;
  border-radius: 10px;

  background: #f5f7f7;

  left: -5px;
  top: -5px;
  -webkit-transition: all 100ms ease-out;
  -moz-transition: all 100ms ease-out;
  -o-transition: all 100ms ease-out;
  transition: all 100ms ease-out;
}

/* #product-back{
	display:none;
	transform: rotateY( 180deg );
} */

.griditem {
  margin: 10px;
  margin: 10 auto;
  display: grid;
}

/* .stats-container:hover{
  background: #b5e5e7;
} */
.stats-container {
  cursor: move;
  text-align: left;
  background: white;
  /* margin-left: 15px; */
  border-left: 8px solid lightgray;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-top: 10px;
  /* position: absolute; */
  top: 6px;
  left: 0;
  min-height: 65px;
  /* width: 230px; */
  /* margin-inline-start: 20px;
  margin-inline-end: 20px; */
  /* min-width: 230px; */
  /* height: 40px; */
  padding: 10px;
  /* padding: 10px 15px 35px; */
  /* -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
	-webkit-transition: all 200ms ease-out;
       -moz-transition: all 200ms ease-out;
         -o-transition: all 200ms ease-out;
            transition: all 200ms ease-out; */
}

/* .stats-container{
  top:20px;
	-webkit-transition: all 200ms ease-out;
       -moz-transition: all 200ms ease-out;
         -o-transition: all 200ms ease-out;
            transition: all 200ms ease-out;
} */
.stats-container .product_name {
  font-size: 15px;
  font-weight: 500;
  color: #393c45;
}

.stats-container .product_subname {
  font-size: 12px;
  font-weight: 400;
  color: #4d5058;
}

.stats-container p {
  font-size: 16px;
  color: #b1b1b3;
  /* padding:2px 0 20px 0; */
}

.stats-container .inline {
  display: inline-block;
  margin-top: 5px;
  margin-right: 10px;
}

.stats-container .roundcapsule {
  height: 12px;
  border-radius: 10px;
  background-color: #d2d4d4;
  width: 150px;
}

.stats-container .circleitem {
  height: 12px;
  border-radius: 50px;
  background-color: purple;
  width: 12px;
}

/* Start  search box  */
.search {
  display: flex;
  background: white;
  /* height: 35px; */
  border-radius: 35px;
  padding: 5px;
}

.search>input {
  width: 250px;
  padding: 0 10px;
}

input {
  border: none;
  background: none;
  outline: none;
  padding: 0;
  font-size: 16px;
  color: black;

  transition: 0.4s;
}

.buttonround {
  color: #78c6e5;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 0;
  background-color: #ebebeb;
  font-size: 20px;
  cursor: pointer;
  transition: 0.45;
}

input[type="search"] {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-appearance: searchfield;
  appearance: searchfield;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
  appearance: searchfield-cancel-button;
}

.clearfix {
  clear: both;
}

.sort-item {
  width: 50px;
  height: 50px;
  border: 1px solid #000;
  float: left;
  margin-right: 10px;
  text-align: center;
  cursor: move;
}

/* duty roster */

.leftfirst {
  width: 234px;
  float: left;
  background: lightblue;
  height: 100%;
  text-align: center;
  z-index: 99999999;
  position: relative;
}

.box2 {
  display: inline-block;
  width: auto;
  height: 100px;
  margin: 0em;
}

h4 {
  padding: 0px;
  text-align: left;
}

.image--cover {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0px;

  object-fit: cover;
  object-position: center right;
}

.rightsecond {
  float: none;
  position: relative;
  min-width: 100%;
  height: 500px;
  background-color: white;
}

.right_content {
  /*   position: absolute; */
  left: 50px;
  background-color: white;
  max-height: 100%;
}

.containergrid {
  height: auto;
  overflow: hidden;
  height: 500px;
  margin-top: 10px;
}

.th_font {
  font-size: 20px;
}

span {
  vertical-align: text-top;
  font-size: 13px;
}

sub {
  margin: 0px;
  font-size: 12px;
}

.stats-containerdutty {
  text-align: left;
  background: white;
  border: 1px solid #e1dddd;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 20px solid blue;
  left: 0;
  min-height: auto;
  /* width: 100%;  */
  /* width: 20rem;  */
  /* height: 5.3rem; */
  /* padding: 5px; */
  font-size: 12px;
  font-weight: 500;
  transition: transform 0.2s;
}

.stats-containerdutty .product_name {
  font-size: 15px;
  font-weight: 500;
  color: #393c45;
}

.stats-containerdutty .product_subname {
  font-size: 11px;
  margin-top: -0.5rem;
  font-weight: 500;
  color: black;
}

.stats-containerdutty p {
  font-size: 16px;
  color: #b1b1b3;
  /* padding:2px 0 20px 0; */
}

.stats-containerdutty .inline {
  display: inline-block;
  margin-top: 5px;
  margin-right: 10px;
}

.stats-containerdutty .roundcapsule {
  height: 12px;
  border-radius: 10px;
  background-color: #d2d4d4;
  width: 150px;
}

p {
  padding-top: 2px;
  font-size: 12px;
  padding-bottom: 2px;
}

.stats-containerdutty .circleitem {
  height: 12px;
  border-radius: 50px;
  background-color: purple;
  width: 12px;
}

.shiftNameContainer {
  width: 16rem;
}

.stats-containerdutty-darkgray {
  border-left: 20px solid darkgray;
}

.stats-containerdutty-empty {
  text-align: left;
  border: 1px solid #e1dddd;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  left: 0;
  min-height: auto;
  padding: 5px;
  height: 5.3rem;
  font-size: 12px;
  font-weight: 500;
  border-left: 20px solid red;
  transition: transform 0.2s;
}

.stats-containerdutty-empty:hover {
  -ms-transform: scale(1.4);
  /* IE 9 */
  -webkit-transform: scale(1.4);
  /* Safari 3-8 */
  transform: scale(1.4);
}

.stats-containerdutty-available {
  border-left: 20px solid darkorange;
}

.stats-containerdutty-available:hover {
  -ms-transform: scale(1.4);
  /* IE 9 */
  -webkit-transform: scale(1.4);
  /* Safari 3-8 */
  transform: scale(1.4);
}

.stats-containerdutty-all-alloted {
  border-left: 20px solid rgb(0, 255, 0);
}

.stats-containerdutty-all-alloted:hover {
  -ms-transform: scale(1.4);
  /* IE 9 */
  -webkit-transform: scale(1.4);
  /* Safari 3-8 */
  transform: scale(1.4);
}

.stats-containerdutty-leave {
  border-left: 20px solid skyblue;
}

.stats-containerdutty-leave:hover {
  -ms-transform: scale(1.4);
  /* IE 9 */
  -webkit-transform: scale(1.4);
  /* Safari 3-8 */
  transform: scale(1.4);
}

.stats-containerdutty-exceede {
  border-left: 20px solid mediumvioletred;
}

.stats-containerdutty-exceede:hover {
  -ms-transform: scale(1.4);
  /* IE 9 */
  -webkit-transform: scale(1.4);
  /* Safari 3-8 */
  transform: scale(1.4);
}

.form-control {
  font-family: "Roboto", sans-serif;
}

.input {
  font-family: "Roboto", sans-serif;
}

#pendingProgressBar {
  width: 100%;
  background-color: rgb(255, 105, 121);
}

#paidProgressBar {
  width: 20%;
  height: 30px;
  background-color: rgb(32, 142, 255);
}

.pay-info-column {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.pay-info-column .pay-info-item:not(:last-child) {
  margin-bottom: 25px;
}

.pay-info-column .pay-info-item.netpay {
  border-color: #47d9a0;
}

.pay-info-column .pay-info-item {
  padding-left: 15px;
  border-left: 4px solid;
}

.pay-info-column .pay-info-item:not(:last-child) {
  margin-bottom: 15px;
}

.pay-info-column .pay-info-item.deductions {
  border-color: #ff6979;
}

.pay-info-column .pay-info-item.grosspay {
  border-color: #c9cede;
}

.pay-info-column .pay-info-item {
  padding-left: 15px;
  border-left: 4px solid;
}

.common-padding {
  padding: 10px 5px;
}

.shiftTime {
  margin-left: -2.8rem;
  margin-top: -1em;
}

.agentCodeCircle {
  background-color: #fdfbfb;
  border-radius: 50%;
  height: 20px;
  width: 20px;
}

.remaingAgentsCountCircle {
  background-color: #fdfbfb;
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.shiftHeading {
  font-size: 2.5rem;
  padding: 15px;
}

.shiftDate {
  margin-left: 2rem;
  font-size: 2.5rem;
}

.shiftDay {
  margin-left: 4rem;
  font-size: 1.5rem;
}

.shiftMonth {
  /* margin-left: 2rem; */
  font-size: 1.5rem;
}

.verticalline {
  border-right: 0.2rem solid #eee;
  border-image: linear-gradient(to bottom,
      #fff 0,
      #eee 25%,
      #eee 50%,
      #eee 75%,
      #fff 100%);
  border-image-slice: 1;
  border-left: 0;
}

.mybadge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  background-color: #4c4f53;
  border-radius: 10px;
  font-size: xx-large;
  font-weight: 300;
  margin-right: 20px;
  margin-bottom: 5px;
}

.taskBoardHeader {
  height: 46px;
  overflow: hidden;
  margin-left: -10px;
  border-radius: 5px;
  margin-right: -10px;
}

.modal-confirm {
  color: #636363;
  width: 325px;
}

.modal-confirm .modal-content {
  padding: 20px;
  border-radius: 5px;
  border: none;
}

.modal-confirm .modal-header {
  border-bottom: none;
  position: relative;
}

.modal-confirm h4 {
  text-align: center;
  font-size: 26px;
  margin: 30px 0 -15px;
}

.modal-confirm .form-control,
.modal-confirm .btn {
  min-height: 40px;
  border-radius: 3px;
}

.modal-confirm .close {
  position: absolute;
  top: -5px;
  right: -5px;
}

.modal-confirm .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
}

.modal-confirm .icon-box {
  color: #fff;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: -70px;
  width: 95px;
  height: 95px;
  border-radius: 50%;
  z-index: 9;
  background: #ff6600;
  padding: 15px;
  text-align: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.modal-confirm .icon-box i {
  font-size: 58px;
  position: relative;
  top: 3px;
}

.modal-confirm.modal-dialog {
  margin-top: 80px;
}

.modal-confirm .btn {
  color: #fff;
  border-radius: 4px;
  background: #ff6600;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  border: none;
}

.modal-confirm .btn:hover,
.modal-confirm .btn:focus {
  background: #ff6600;
  outline: none;
}

.trigger-btn {
  display: inline-block;
  margin: 100px auto;
}

.payment-form {
  padding-bottom: 50px;
  font-family: "Montserrat", sans-serif;
}

.payment-form.dark {
  background-color: #f6f6f6;
}

.payment-form .content {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
  background-color: white;
}

.payment-form .block-heading {
  padding-top: 50px;
  margin-bottom: 40px;
  text-align: center;
}

.payment-form .block-heading p {
  text-align: center;
  max-width: 420px;
  margin: auto;
  opacity: 0.7;
}

.payment-form.dark .block-heading p {
  opacity: 0.8;
}

.payment-form .block-heading h1,
.payment-form .block-heading h2,
.payment-form .block-heading h3 {
  margin-bottom: 1.2rem;
  color: #ff6600;
}

.payment-form form {
  border-top: 2px solid #ff6600;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
  background-color: #ffffff;
  padding: 0;
  max-width: 600px;
  margin: auto;
}

.payment-form .title {
  font-size: 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 0.8em;
  font-weight: 600;
  padding-bottom: 8px;
}

.payment-form .products {
  background-color: #f7fbff;
  padding: 25px;
}

.payment-form .products .item {
  margin-bottom: 1em;
}

.payment-form .products .item-name {
  font-weight: 600;
  font-size: 0.9em;
}

.payment-form .products .item-description {
  font-size: 0.8em;
  opacity: 0.6;
}

.payment-form .products .item p {
  margin-bottom: 0.2em;
}

.payment-form .products .price {
  float: right;
  font-weight: 600;
  font-size: 0.9em;
}

.payment-form .products .total {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  padding-top: 19px;
  font-weight: 600;
  line-height: 1;
}

.payment-form .card-details {
  padding: 25px 25px 15px;
}

.payment-form .card-details label {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #79818a;
  text-transform: uppercase;
}

.payment-form .card-details button {
  margin-top: 0.6em;
  padding: 12px 0;
  font-weight: 600;
}

.payment-form .date-separator {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
}

@media (min-width: 576px) {
  .payment-form .title {
    font-size: 1.2em;
  }

  .payment-form .products {
    padding: 40px;
  }

  .payment-form .products .item-name {
    font-size: 1em;
  }

  .payment-form .products .price {
    font-size: 1em;
  }

  .payment-form .card-details {
    padding: 40px 40px 30px;
  }

  .payment-form .card-details button {
    margin-top: 2em;
  }
}

/* basic positioning */
.legend {
  list-style: none;
}

.legend li {
  float: left;
  margin-right: 10px;
}

.legend span {
  border: 1px solid #ccc;
  float: left;
  width: 12px;
  height: 12px;
  margin: 2px;
}

/* your colors */
.legend .available {
  background-color: red;
}

.legend .partiallyFilled {
  background-color: darkorange;
}

.legend .full {
  background-color: #00ff00;
}

.legend .overfilled {
  background-color: mediumvioletred;
}

.workflowContainer {
  /* border: 2px dashed blue; */
  margin: 10px 10px 0 0;
  padding: 5px;
  padding-top: 0px;
  text-align: left;
  font-size: 26px;
  font-weight: 600;
  display: inline-block;
  /* min-height: 500px;
  min-width: 150px; */
  /* width:290px; */
  min-height: 350px;
  max-height: 350px;
  border-radius: 10px;
  width: 26rem;
  /* -webkit-box-shadow: 0 0 10px #bebcbc; */
  overflow-y: scroll;
  background: #f5f7f7;

  left: -5px;
  top: -5px;
}

.sidepanel {
  width: 0;
  position: fixed;
  z-index: 1;
  height: 550px;
  top: 0;
  right: 0;
  overflow-x: hidden;
  transition: 0.5s;
  background-color: white;
  overflow: auto;
}

.sidepanel a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidepanel .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  color: black;
}

.sidePanelHeader {
  background: #f9faff;
  padding: 0px 10px 20px 0px;
}

.sidePanelHeaderText {
  color: #2b76d2;
}

.form-gap {
  padding-top: 70px;
}

summary::-webkit-details-marker {
  color: #00acf3;
  font-size: 70%;
  /* margin-right: 2px; */
  margin-left: 1rem;
}

summary:focus {
  outline-style: none;
}

article>details>summary {
  font-size: 14px;
  margin-top: 16px;
}

details>p {
  margin-left: 24px;
}

details details {
  margin-left: 36px;
}

details details summary {
  font-size: 16px;
}

.containerimg {
  display: flex;
  flex-direction: column;
  margin: 1.1rem auto;

  font-family: sans-serif;
  text-align: center;
  padding-top: 0.75rem;
}

.fontContainer img {
  /*   font-size: 1.4rem; */
  width: 60%;
  /*   padding: 0.5rem; */

  text-align: justify;
  margin: 1.1rem auto;
  /*   border: solid 0.01rem gray; */
  /* box-shadow: 0.1rem 0.1rem white;  */
}

.fontContainer p {
  font-size: 0.8rem;
  margin-bottom: 0.4rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.playstore img {
  margin: 2 0 0;
  width: 14.2rem;
  height: 6rem;
  /* margin-top: 10px; */
}

.image-container {
  display: flex;
  justify-content: center;
}

.apple img {
  height: 4.7rem;
  /* width: 14.2rem; */
  margin-top: 9px;
}

.bottomMarging5 {
  margin-bottom: 5px;
}

.importDutiestHeading {
  font-size: 2rem;
  padding: 10px;
}

.fa {
  position: relative;
}

.fa:hover::after {
  content: attr("data-title");
  padding-left: 0.25em;
  position: absolute;
  bottom: 0;
  margin-bottom: -1em;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.5em;
  color: red;
}

.topMargin {
  margin-top: 1.9rem;
}

.btn-my {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.btn-my:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.martb {
  margin-top: 10px;
  margin-bottom: 10px;
}

.marlr {
  margin-left: 10px;
  margin-right: 10px;
}

.btn-cancel {
  background-color: rgb(236, 234, 234);
}

.btn-square {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 13px;
  line-height: 1.42857143;
  border-radius: 2px;
  user-select: none;
  margin-left: 10px;
}

.btn-primary {
  color: #fff;
  background-color: #3276b1;
  border-color: #2c699d;
}

.btn-primary:hover {
  color: #fff;
  background-color: #275b89;
  border-color: #1f496d;
}

.nav-right-btn {
  cursor: pointer;
  color: #333;
  background-color: #fff;
  border: #adadad 1px solid;
  padding: 3px 10px;
  margin-top: 5px;
  margin-left: 10px;
  background-color: #f2f2f2;
}

.nav-right-btn:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.custom-file-updated-label {
  cursor: pointer;
  color: #333;
  background-color: #fff;
  border: #ccc 1px solid;
  border-radius: 2px;
  margin-top: 21px;
  padding: 3px 12px;
  background-color: #f2f2f2;
}

.custom-file-updated-message-passed {
  padding: 5px;
  margin-top: 21px;
  color: rgb(37, 179, 37);
  background-color: #fff;
}

.custom-file-updated-message-failed {
  padding-top: 2px;
  /* margin-top: 15px; */
  color: rgb(179, 58, 37);
  background-color: #fff;
  font-size: 10px;
}

.col-md-8 {
  padding-left: 3px;
}

.panel {
  border-color: #3276b1;
  margin-bottom: 18px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.panel-primary {
  border-color: #3276b1;
}

.col-xs-9 {
  width: 75%;
}

.panel-primary>.panel-heading {
  color: #fff;
  background-color: #3276b1;
  border-color: #3276b1;
}

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-right-radius: 1px;
  border-top-left-radius: 1px;
}

.panel-footer {
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 1px;
}

.treeList {
  list-style-type: none;
  margin-left: 1em;
  padding-left: 1em;
  position: relative;
}

.treeList::before {
  content: "";
  display: block;
  /* position: absolute; */
  top: 0;
  left: 0px;
  bottom: 50%;
  width: 0.65em;
  border: 1px solid #7a7a7a;
  border-left: 0 none transparent;
  border-top: 0 none transparent;
  border-right: 0 none transparent;
  box-sizing: border-box;
}

.treeList:last-child {
  left: -10px;
  border-left: 1px solid #7a7a7a;
}

.MuiGrid_forCard {
  padding-left: 0.4rem;
  padding-top: 1rem;
  flex-basis: 33.3333%;
  -webkit-box-flex: 0;
  flex-grow: 0;
}

.MuiGrid {
  background-color: rgb(255, 255, 255);
  color: rgba(58, 53, 65, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 6px;
  background-image: none;
  overflow: hidden;
  /* box-shadow: rgb(58 53 65 / 10%) 0px 2px 10px 0px; */
  /* position: relative; */
}

.MuiCardContent {
  /* padding-bottom: 1.25rem;
  padding: 1.25rem; */
  box-sizing: inherit;
}

.card h6 {
  margin: 0px;
  font-family: Inter, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 1.6;
  letter-spacing: 0.15px;
  color: rgba(58, 53, 65, 0.87);
  padding-left: 12px;
  box-sizing: inherit;
}

.card h5 {
  font-size: 2.4rem;
  margin: 1rem 0px;
  font-family: Inter, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 500;
  line-height: 1.334;
  letter-spacing: 0px;
  color: rgb(145, 85, 253);
  box-sizing: inherit;
  display: block;
  padding-left: 12px;
}

.card button {
  /* padding: 0.25rem 0.875rem;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: Inter, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.8125rem;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  min-width: 64px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
   background-color: rgb(145, 85, 253);
  font-weight: 500;
  border-radius: 5px;
  line-height: 1.71;
  letter-spacing: 0.3px;
  box-shadow: rgb(58 53 65 / 42%) 0px 4px 8px -4px;
  writing-mode: horizontal-tb !important;
  text-rendering: auto;
  word-spacing: normal;
  text-indent: 0px;
  text-shadow: none;
  text-align: center; */
}

.card button span {
  /* overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  inset: 0px;
  border-radius: inherit;
  box-sizing: inherit; */
}

.image_card1 {
  right: 0px;
  bottom: 0px;
  height: 170px;
  position: absolute;
  box-sizing: inherit;
}

.image_card2 {
  right: 25px;
  bottom: 25px;
  height: 98px;
  position: absolute;
}

.card p {
  /* margin: 0px;
  font-family: Inter, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 500; */
  /* font-size: 1.3rem; */
  /* line-height: 1.6;
  letter-spacing: 0.15px;
  color: rgba(58, 53, 65, 0.87);
  padding-left: 12px;
  box-sizing: inherit; */
}

.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.myFlexContainer {
  margin-right: 30px;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  float: right !important;
  width: 100%;
}

.main-layout {
  margin-left: 10px;
  padding-left: 20px;
  width: 100%;
}

.side-bar-items {
  min-width: 275px;
}

.side-bar-items-30 {
  min-width: 30vw;
}

.sideBar {
  background-color: rgb(63, 60, 60);
  color: wheat;
  position: fixed;
  height: 95%;
  z-index: 99;
  right: 0;
  width: 40px;
  text-align: center;
  word-break: break-all;
  padding-top: 10px;
  margin-top: -10px;
}

.sideBar p {
  padding: 5px;
  color: rgb(211, 204, 204);
  cursor: pointer;
}

.sideBar p :hover {
  color: white;
}

.ponter-cursor {
  cursor: pointer !important;
}

.media-card {
  height: 100px;
  border-radius: 10px;
}

.media-card:hover {
  background-color: rgba(219, 219, 219, 0.759);
}

.media-card-selected {
  height: 100px;
  border-radius: 10px;
  border: 2px solid skyblue;
  background-color: rgba(219, 219, 219, 0.759);
}

.dragging {
  border: 10px solid skyblue;
}

.EpisodeTileImg {
  height: 200px;
  width: 200px;
}

.ExpandableTagImg {
  height: 40px;
  width: 40px;
}

.expanderStyle {
  margin: 6px 0;
  padding: 2px;
  border: 1px solid silver;
}

.headerStyle {
  display: flex;
  cursor: pointer;
}

.titleStyle {
  padding: 3px;
  width: 80px;
  flex: none;
}

.spacerStyle {
  flex: 1;
}

.iconStyle {
  padding: 3px;
  flex: none;
}

.contentStyle {
  overflow: hidden;
  transition: all 0.3s;
}

.contentExpandedStyle {
  overflow: hidden;
  transition: all 0.3s;
  padding: 4px 0;
  border: 1px solid silver;
  height: auto;
  filter: opacity(1);
}

.contentCollapsedStyle {
  overflow: hidden;
  transition: all 0.3s;
  padding: 0 0;
  border: 1px solid transparent;
  height: 0;
  filter: opacity(0);
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.flex-container-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.flex-item-auto {
  flex: auto;
}

.w-300 {
  max-width: 300px;
}

.no-headers-grid .k-grid-header {
  display: none;
}

.no-headers-grid .k-grid-content {
  overflow: hidden !important;
}

.form-box {
  box-shadow: 0px 0px 10px 1px lightgrey;
  background-color: #eeeeee;
  border-radius: 10px;
  padding: 5px;
}

.page-edit-title {
  font-variant: JIS04;
  margin: 12px 0 0px;
}

.page-edit-title span {
  font-size: 16px;
  color: #333;
  display: inline-block;
  vertical-align: 1px;
}

#drag-ghost,
#drag-ghost-del {
  /* width: 150px; */
  /* height: 200px; */
  /* background-color: #7FB77E; */
  color: white;
  padding: 10px;
  font-size: small;
  border-radius: 5px;
  cursor: pointer;
}

.border-solid {
  border: 1px solid #6b6b69;
}

aside {
  overflow: scroll;
}

#main {
  background-color: #00acf3;
  overflow: scroll;
  height: 100%;
  width: 100%;
}

.left-menu {
  background: #3a3633;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSI5NyUiIHN0b3AtY29sb3I9IiMzYTM2MzMiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMmEyNzI1IiBzdG9wLW9wYWNpdHk9IjEiLz4KICA8L2xpbmVhckdyYWRpZW50PgogIDxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjZ3JhZC11Y2dnLWdlbmVyYXRlZCkiIC8+Cjwvc3ZnPg==);
  background: linear-gradient(to right, #3a3633 93%, #2a2725 100%);
  width: 15%;
  height: 94%;
  padding-bottom: 20px;
  overflow: auto;
  position: relative;
  margin-top: -1px;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
.left-menu {
  scrollbar-width: auto;
  scrollbar-color: #3a3633 #ffffff;
}

/* Chrome, Edge, and Safari */
.left-menu::-webkit-scrollbar {
  width: 7px;
}

.left-menu::-webkit-scrollbar-track {
  background: #a7a4a4;
}

.left-menu::-webkit-scrollbar-thumb {
  background-color: #3a3633;
  border-radius: 0;
  border: 3px none #ffffff;
}

.mainb {
  display: flex;
  height: 100%;
  overflow: auto;
  position: relative;
  overflow-y: hidden;

}

.main-container {
  width: 100%;
  height: 100%;
  padding: 10px;
  overflow: auto;
}

#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.headerMain {
  height: 6.1%;
  background: #3a3633;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSI5NyUiIHN0b3AtY29sb3I9IiMzYTM2MzMiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMmEyNzI1IiBzdG9wLW9wYWNpdHk9IjEiLz4KICA8L2xpbmVhckdyYWRpZW50PgogIDxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjZ3JhZC11Y2dnLWdlbmVyYXRlZCkiIC8+Cjwvc3ZnPg==);
  background: linear-gradient(to right, #3a3633 93%, #2a2725 100%);
  position: relative;
}

.minifyme {
  position: absolute;
  right: 0px;
  top: 10px;
}

.minified {
  width: 3%;
  overflow: visible;
  position: relative;
}

.minified .minifyme {

  position: absolute;

}

.minified nav>ul>li>ul {
  z-index: 9999;
}

.uploadFileButton {
  margin-left: 3px;
  margin-top: 1px;
  margin-bottom: -10px;
  /* height : 32px; */
  background-color: #ffc107;
  border-color: #ffc107;
  border-radius: 15px;
  padding: 6px 0;
  /* width:92px */
}

.salesDashboard_card {
  left: 25px;
  top: 06px;
  width: 68px;
  height: 68px;
  border-radius: 06px;
  position: absolute;
  box-sizing: inherit;
}

.salesDivider {
  margin-left: 05px;
  margin-right: 05px;
}

.salesDashboard_forCard {
  padding-left: 0.4rem;
  padding-top: 1rem;
  flex-basis: 33.3333%;
  border-radius: 20px;
  -webkit-box-flex: 0;
  flex-grow: 0;
}

.salesDashboardicon_card {
  font-size: 2.5em;
  left: 37px;
  top: 19px;
  color: #f6fbfd;
  height: 12px;
  position: absolute;
}

.salesCardContent {
  box-sizing: inherit;
  border-radius: 20px;
  height: 148px;
  margin-top: -18px;
}

.salesDashboard_text {
  text-align: right;
  font-weight: 500;
  color: #8b8b8d;
  margin-right: 0px;
  font-size: 13px;
  margin-top: 0px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.salesDashboard_Count {
  text-align: right;
  font-weight: 700;
  color: #141313;
  font-size: 29px;
}

.salesDashboard_title {
  margin-left: 0px;
  font-weight: 500;
  color: #8a8888;
  font-size: 15px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.textAndCount {
  text-align: right;
  margin-left: -15px;
}

.salesDashboardBottomicon_card {
  /* left: 14px; */
  color: rgb(110, 110, 116);
  height: 14px;
  font-size: 1.4em;
  margin-right: 10px;
  /* position: absolute; */
}

.bottomDetailIcon {
  color: #f0f7fa;
  font-size: 1.5em;
  bottom: 5px;
  margin-left: -28px;
  position: absolute;
}

@media screen and (max-width: 1550px) {
  .bottomDetailIcon {
    bottom: 05px;
  }
}

.salesDashboard_BottonIconcard {
  box-shadow: 2px 2px 2px #cfcece;
  width: 35px;
  height: 35px;
  margin-top: 0vmin;
  border-radius: 40px;
  position: inherit;
  box-sizing: inherit;
}

.iconBase {
  padding: 5px;
  margin-right: 10px;
}

.iconBase:hover {
  background-color: #e0e0e0;
}

.iconBase2 {
  padding: 5px;
  margin-right: 10px;
  user-select: none;
}

.iconBase2:hover {
  background-color: #403f3d;
}

.k-switch-thumb {
  display: none;
}

.k-switch-on .k-switch-track {
  border-color: #444746;
  color: white;
  background-color: #5c5e5d;
}

.scheduleFooterContainer {
  position: relative;
}

.scheduleFooter.scheduleFooterMinified {
  width: 97%;
}

.scheduleFooter {
  position: fixed;
  bottom: 0;
  height: 5%;
  width: 87%;
  background: #3a3633;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSI5NyUiIHN0b3AtY29sb3I9IiMzYTM2MzMiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMmEyNzI1IiBzdG9wLW9wYWNpdHk9IjEiLz4KICA8L2xpbmVhckdyYWRpZW50PgogIDxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjZ3JhZC11Y2dnLWdlbmVyYXRlZCkiIC8+Cjwvc3ZnPg==);
  background: linear-gradient(to right, #3a3633 93%, #2a2725 100%);
  margin-left: -10px;
}

.headerText {
  font-weight: 400;
  font-family: "Roboto";
  font-size: 10px;
  margin-right: 8px;

}

.footerText {
  font-weight: 400;
  font-family: "Roboto";
  font-size: 12px;
  color: #ffffff;
}

.headerPostion {
  position: absolute;
  bottom: -9px;
  right: -15%;
}

.k-dialog-titlebar {
  border-color: inherit;
  color: white;
  background: #3a3633;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSI5NyUiIHN0b3AtY29sb3I9IiMzYTM2MzMiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMmEyNzI1IiBzdG9wLW9wYWNpdHk9IjEiLz4KICA8L2xpbmVhckdyYWRpZW50PgogIDxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjZ3JhZC11Y2dnLWdlbmVyYXRlZCkiIC8+Cjwvc3ZnPg==);
  background: linear-gradient(to right, #3a3633 93%, #2a2725 100%);
}

.k-pane {
  overflow: hidden !important;
}

.summaryCardContent {
  box-sizing: inherit;
  border-radius: 40px;
  height: 110px;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;
}

.summaryCardRow {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}

.summaryDashboard_forCard {
  padding-left: 0.4rem;
  padding-top: 0.2rem;
  flex-basis: 33.3333%;
  border-radius: 20px;
  -webkit-box-flex: 0;
  flex-grow: 0;
}

.summaryDashboard_card {
  left: 20px;
  top: 20px;
  width: 55px;
  height: 55px;
  border-radius: 60px;
  position: absolute;
  box-sizing: inherit;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-size: 24px;
  color: white;
}

.summaryCardDetails {
  margin-left: 20px;
  display: flex;
  align-items: center;
  gap: 4px;
  flex-grow: 1;
}
.summaryCardSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.summaryCardIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-size: 24px;
margin-left: 10px;
  color: white;
}

.summaryDashboardicon_card {
  font-size: 1.4em;
  color: #f6fbfd;
}


.summaryDashboard_text {
  text-align: center;
  font-weight: 700;
  color: #8b8b8d;
  margin-right: 0px;
  font-size: 12px;
  margin-top: 0px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.summarytextAndCount {
  text-align: center;
}



.summaryDashboard_Count {
  text-align: center;
  font-weight: 700;
  color: #787676;
  font-size: 20px;
}

.pointer {
  cursor: pointer;
}

@keyframes visible {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.9;
  }
}

kendo-scheduler .k-event .k-event-template,
.k-event .k-event-template {
  line-height: 17px;
  padding: 4px 8px;
  font-size: 12px;
}

.k-dialog-wrapper {
  z-index: 100 !important;
}

.k-toolbar {
  z-index: 1 !important;
}

.k-grid-header .k-header.k-grid-header-sticky,
.k-grid-header .k-filter-row .k-grid-header-sticky,
.k-grid-content-sticky,
.k-grid-row-sticky,
.k-grid-footer-sticky {
  position: sticky;
  z-index: 1;
}

.k-window-content {
  background: white !important;
}


.dashboard-title-style {
  font-size: 12px;
  color: white;
  font-family: 'Roboto', Times, serif;
  font-weight: 400;
  background: #575957;
  /* background: rgb(87,89,87);
  background: linear-gradient(90deg, rgba(87,89,87,1) 0%, rgba(244,245,250,1) 100%); */
  /* background: rgb(14,131,136);
  background: linear-gradient(90deg, rgba(14,131,136,1) 0%, rgba(244,245,250,1) 100%); */
}


.notification-box {
  font-family: 'Manrope', sans-serif
}

.notification-header {
  font-size: 14px;
  padding: 10px;
  border-bottom: 1px solid #eee;
  color: #999;
}

.notifications-item {
  display: flex;
  border-bottom: 1px solid #eee;
  padding: 6px 9px;
  margin-bottom: 0px;
  cursor: pointer
}

.notifications-item:hover {
  background-color: #eee
}

.notifications-item-avatar {
  display: block;
  width: 50px;
  height: 50px;
  margin-right: 9px;
  border-radius: 50%;
  margin-top: 2px;
}

.notifications-item .text h4 {
  color: #777;
  font-size: 16px;
  margin-top: 3px;
}

.notifications-item .text p {
  color: #aaa;
  font-size: 12px;
}

.react-confirm-alert-overlay {
  z-index: 101 !important;
}


.dataResult {
  margin-top: 5px;
  width: 500px;
  height: 400px;
  background-color: #fefefe;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(32, 33, 36, .28);
  overflow: hidden;
  overflow-y: auto;
  z-index: 1000 !important;
  position: relative;

}

.dataResult::-webkit-scrollbar {
  display: none;
}



.dataItem {
  margin-top: 5px;
  width: 100%;
  padding: 7px;
  font-size: 14px;
  color: #151414;
}

.dataItem:hover {
  background-color: #efefef;
  cursor: pointer;
}

.dataItem.hover {
  background-color: #efefef;
  cursor: pointer;
}

.dataItem a {
  color: #000;
  font-size: 12px;
}



.dataitemText {
  color: #bcc0ca;
  font-size: 11px;
}

.p-datepicker-calendar thead tr th {
  width: auto !important;
  padding: 0.8rem !important;
}

.p-datepicker-calendar tbody tr td {
  width: auto !important;
  padding: 0 !important;
}

.k-event-template.k-event-template.k-event-time {
  display: none !important;
}


.k-window-actions {
  margin: 0px !important;
}

.border-with-title {
  position: relative;
  font-size: 12px;
  top: -0.9em;
  margin-left: 0.8em;
  display: inline;
  background-color: white
}

#dynamicDropdown::-webkit-scrollbar {
  display: none;
}

#dynamicDropdown {
  transition: max-height 0.4s ease-in-out;
  z-index: 999999 !important;
}

#dynamicDropdown .dataItem {
  width: 100%;
  margin: 0;
  padding: 12px;
  font-size: 14px;
  color: #151414;
  text-align: left;
  background-color: white;
  border: none;
}

#dynamicDropdown .dataItem:hover {
  background-color: whitesmoke;
}

#dynamicDropdown .dataItem.active {
  background-color: #3AA6B9;
  color: white;
}

.k-splitbar {
  background-color: #1274AC;
  color: white;
  min-width: 10px;
  min-height: 15px;
}

.k-splitbar:hover:focus {
  background-color: #1274AC;
  color: white;
}

.k-splitbar:hover {
  background-color: #05344f;
  color: white;
}

/* .react-confirm-alert-overlay{
  background: rgba(255, 255, 255, 0.2) !important;
} */

#myProgressBar {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

#myProgressBar .myProgressBarBox {
  width: 400px;
  height: 130px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  background-color: white;
  border-radius: 10px;
  display: flex;
  padding-top: 40px;
  align-items: center;
  flex-direction: column;
  row-gap: 20px;
  position: relative;
}

#myProgressBar .myProgressBarBox button {
  position: absolute;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
}

#myProgressBar .myProgressBarBox .message {
  display: flex;
  align-items: center;
  position: absolute;
  left: 20px;
  top: 80px;
}

#myProgressBar .myProgressBarBox .message p:last-child {
  margin: 0;
  font-size: 13px;
}

.dashboard-booking-Summary-title-style {
  font-size: 18px;
  color: rgb(25, 25, 25);
  font-family: 'Roboto', Times, serif;
  font-weight: 300;
  height: 45px;
  text-align: center;
  background: #F5F5F5;
  /* background: rgb(87,89,87);
  background: linear-gradient(90deg, rgba(87,89,87,1) 0%, rgba(244,245,250,1) 100%); */
  /* background: rgb(14,131,136);
  background: linear-gradient(90deg, rgba(14,131,136,1) 0%, rgba(244,245,250,1) 100%); */
}

.ScheduleMediaCollection {
  height: 74%;
  width: 100%;
}

.ScheduleBookedCollection {
  height: 69%;
  width: 100%;
}

.ScheduleWrapper {
  height: 85%;
  width: 100%;
}

.FormatScheduleWrapper {
  height: 85%;
  width: 100%;
}

#searchInput::placeholder {
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  color: gainsboro;
}

#searchInput:focus::placeholder {
  color: gray;
}

.scrollbarForSeachBox::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.scrollbarForSeachBox::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.scrollbarForSeachBox::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px gray;
  background-color: #555;
}

.jodit-workplace {
  min-height: 400px !important;
  height: auto !important;
}


@media screen and (min-height: 1080px) {
  .ScheduleMediaCollection {
    height: 80%;
    width: 100%;
  }

  .ScheduleWrapper {
    height: 87%;
    width: 100%;
  }

  .FormatScheduleWrapper {
    height: 87%;
    width: 100%;
  }

  .ScheduleBookedCollection {
    height: 73%;
    width: 100%;
  }


}

@media screen and (min-height: 1599px) {
  .ScheduleMediaCollection {
    height: 87%;
    width: 100%;
  }

  .ScheduleWrapper {
    height: 89%;
    width: 100%;
  }

  .FormatScheduleWrapper {
    height: 89%;
    width: 100%;
  }

  .ScheduleBookedCollection {
    height: 82%;
    width: 100%;
  }


}



@media screen and (max-height: 800px) {
  .ScheduleMediaCollection {
    height: 70%;
    width: 100%;
  }

  .ScheduleWrapper {
    height: 84%;
    width: 100%;
  }

  .FormatScheduleWrapper {
    height: 84%;
    width: 100%;
  }

  .ScheduleBookedCollection {
    height: 59%;
    width: 100%;
  }
}

.k-scheduler-row {
  overflow-x: hidden;
}

@media screen and (max-height: 611px) {
  .ScheduleMediaCollection {
    height: 62%;
    width: 100%;
  }

  .ScheduleWrapper {
    height: 83%;
    width: 100%;
  }

  .FormatScheduleWrapper {
    height: 83%;
    width: 100%;
  }

  .ScheduleBookedCollection {
    height: 48%;
    width: 100%;
  }



}

.k-marquee-color {
  color: #ffffff;
  background-color: none;
  border-color: rgba(0, 0, 0, 0.08);
  opacity: 0;
}

.timecell:hover {
  color: #0000ff;
  font-weight: bold;
  cursor: pointer;
}

/* .ScheduleWrapper .k-table-md .k-table-td, .k-table-md {
  padding: 0px;
} */

.p-menuitem-link {
  padding: 5px 10px !important;
  border-radius: 3px !important;
  margin-bottom: 5px !important;
  color: black !important;
}

.p-menuitem-link:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  background-color: transparent !important;
}

.p-menuitem-link:active {
  background-color: #e1e3e6 !important;
}

.p-menuitem-link:visited {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  background-color: transparent !important;
}

.p-menuitem-link:hover {
  background-color: #e1e3e6 !important;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.pi-angle-down {
  display: none !important;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.otp-input {
  width: 50px !important;
  height: 50px;
  padding: 0 !important;
  text-align: center;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 24px;
  outline: none;
  transition: all 0.2s ease;
}

.otp-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}

#dynamicWidthInput {
  transition: none;
  padding: 5px;
  border-radius: 5px;
  padding-right: 0;
  box-sizing: content-box;
  min-width: 100px;
  border: 1px solid transparent;
}

#dynamicWidthInput:hover {
  border: 1px solid grey;
}

/* Default height */
.digitalsign-dashboard-custom-grid {
  height: 46vh;
}

/* Adjust height for larger screens */
@media (min-width: 1800px) {
  .digitalsign-dashboard-custom-grid {
    height: 58vh;
    /* or another value that suits large screens */
  }
}

/* Adjust height for smaller screens */
@media (max-width: 768px) {
  .digitalsign-dashboard-custom-grid {
    height: 40vh;
    /* or another value that suits small screens */
  }
}

/* Default height */
.digitalsign-admin-dashboard-custom-grid {
  height: 30vh;
}

/* Adjust height for larger screens */
@media (min-width: 1800px) {
  .digitalsign-admin-dashboard-custom-grid {
    height: 46vh;
    /* or another value that suits large screens */
  }
}

.rotate-icon {
  transform: rotate(90deg);
  display: inline-block;
}

/* ScreenTileView.css */
.screen-tile-container {
  display: flex;
  flex-wrap: wrap;
  gap: 35px;
  padding: 20px;
}

.screen-tile {
  width: 300px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  /* overflow: hidden; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  position: relative;
}

.screen-tile:hover {
  transform: translateY(-5px);
}

.screen-tile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: #f5f5f5;
}

.screen-tile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: #f5f5f5;
}

.header-right {
  display: flex;
  align-items: center;
}

.status-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 10px;
}

.three-dots {
  font-size: 20px;
  cursor: pointer;
  position: relative;
}


.screen-tile-header h3 {
  margin: 0;
  font-weight: 400;
  font-size: large;
  letter-spacing: 0.5px;
  font-family: "roboto";
  color: #333;
}

.status-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.online {
  background-color: #4CAF50;
}

.offline {
  background-color: #F44336;
}

.screen-tile-image {
  height: 180px;
  overflow: hidden;
}

.screen-tile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.screen-tile-details {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.screen-tile-details p {
  margin: 5px 0;
  font-size: 14px;
  color: #666;
}

.context-menu {
  position: absolute;
  right: 0;
  top: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.context-menu div {
  padding: 8px 12px;
  cursor: pointer;
}

.context-menu div:hover {
  background-color: #f0f0f0;
}

.boss-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.boss-dialog {
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-height: 99vh;
}

.boss-dialog-header {
  align-items: center;
  border-style: solid;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-width: 0 0 1px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  padding: 12px 16px;
  white-space: nowrap;
  background-color: #3A3633;
  color: #ffffff;
}

.boss-dialog-title {
  cursor: default;
  display: flex;
  flex: 1 1;
  flex-flow: row nowrap;
  font-size: 16px;
  line-height: 1.25;
  margin: -.5em 0;
  overflow: hidden;
  padding: .5em 0;
  text-overflow: ellipsis;
}

.boss-dialog-close {
  background: none;
  border: none;
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
}

.boss-dialog-content {
  padding: 20px;
  overflow-y: auto;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #333;
}

.box-shadow-container {
  box-shadow : 0px 0px 10px 1px lightgrey;
  background-color : #EEEEEE;
  border-radius : 10px;
  height : 95px;
}

.plan-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 8px;
}
.plan-header {
  font-size: 22px;
  margin-bottom: 2px;
}
.plan-subheader {
  font-size: 15px;
  color: #888;
  margin-top: 0px;
  margin-right: 0px;
}
.toggle-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.toggle-button {
  display: flex;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  overflow: hidden;
  width: 220px;
}
.toggle-button div {
  flex: 1;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}
.yearly {
  background-color: #f4f4f4;
  font-weight: 400;
  color: #000;
}
.yearly.active {
  background-color: #e0e0e0;
  font-weight: 400;
  font-family: "Roboto";
  color:#008000;
}
.monthly {
  background-color: #f4f4f4;
  font-weight: 400;
  font-family: "Roboto";
  color: #000;
}
.monthly.active {
  background-color:#e0e0e0;
  font-weight: 400;
  font-family: "Roboto";
  color: #008000;
}
.discount {
  font-weight: 400;
  font-family: "Roboto";
  font-size: 14px;
}
.ScreenCount{
  font-family: "Roboto";
  color: #222a3b;
  font-size: 28px;
  font-weight:bold;
  margin-right: 2px;
}
.titleBold{
  font-family: "Roboto";
  color: #57595c;
  font-size: 25px;
  font-weight:bold;
  margin-right: 5px;
}
.priceBold{
  margin-top: -10px;
  font-family: "Roboto";
  font-size: 25px;
  font-weight:bold;
  margin-right: 2px;
  margin-left: 03px;
}

.titleNormal{
  font-family: "Roboto";
  color: #737578;
  font-size: 14px;
  margin-right: 2px;
}

.lineThrought
{
  font-family: "Roboto";
  font-size: 22px;
  font-weight:bold;
  margin-right: 2px;
}

.suggestions-dropdown {
  max-height: 200px;        
  overflow-y: auto;         
  width: 94.5%;              
  border-radius: 0.25rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 1000;
}

.suggestion-item {
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.suggestion-item:hover {
  background-color: #edeeef;
}

.suggestions {
  max-height: 200px;
  overflow-y: auto;
  position: fixed;
  z-index: 100;
  width: 660px;
  background-color: #fff;
  border-radius: 0.25rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.suggestion-item {
  cursor: pointer;
  padding: 0.5rem;
}

.suggestion-item:hover {
  background-color: #e3e4e5;
}

.suggestion-item.active {
  background-color: #e3e4e5;
  color: black;
  border: none;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.digital-sign-login-container{
  padding: 0px;
  background-color: rgba(0, 0, 0, 0.6)
}

.digital-sign-login-heading{
  color: white;
  line-height: 1; 
  margin-left: 10%
}

.digital-sign-login-heading div {
  font-size: 50px;
  font-weight: 600;
  letter-spacing: .2rem
}

.digital-sign-login-content {
  margin-right: 10%
}

@media screen and (max-width: 1059px) {
  .digital-sign-login-container {
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .digital-sign-login-heading {
    display: none !important;
  }

  .digital-sign-login-heading div {
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 0.1rem;
    line-height: 1.5;
    padding-top: 30px;
  }

  .digital-sign-login-content {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin: 0;
    width: 100%;
    height: auto;
    padding: 1rem;
    padding-top: 0px;
  }

  .digital-sign-login-form {
    width: 100%;
    max-width: 400px;
  }

  .toggle-container {
    position: absolute;
    top: 15px;
    left: 40px;

  }

  .p-menubar.p-menubar-mobile-active .p-menubar-root-list{
    width: 200px;
    right: 0;
    left: auto !important;
  }
}

.custom-card {
  transition: all 0.4s ease;
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
}

.custom-card.hover-effect {
  transform: translateY(-10px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
}

.custom-title {
  font-size: 20px;
  font-weight: 800;
  background: linear-gradient(45deg, #2c3e50, #3498db);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.custom-description {
  font-size: large;
  line-height: 1.6;
}

.custom-badge {
  padding: 3px 5px;
  bottom: 5px;
  right: 5px;
  background: linear-gradient(45deg, #f1c40f, #f39c12);
  color: white;
  border-radius: 2rem;
  box-shadow: 0 4px 15px rgba(243, 156, 18, 0.3);
}

.digital-sign-wrapper {
    display: flex;
    flex-direction: column;
}

@media (min-width: 768px) {
    .digital-sign-wrapper {
        flex-direction: row;
    }
}

.digital-sign-wrapper .col {
    margin-bottom: 20px;
}

.screen-pin::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0; 
}

