
.margintop15 {
    margin-top: 15px;
}

.margintop20 {
    margin-top: 20px;
}


.marginleft15 {
    margin-left: 15px;
}

.paddingtop15 {
    padding-top: 20px;
}

.paddingleft15 {
    padding-left: 15px;
}

.bookstatusviewtable > thead:first-child > tr:first-child > th:first-child {
    position: absolute;
    display: inline-block;
    /* background-color: #f6f6f6; */
    /* height: auto; */
    /* height: auto; */
    border: none;
    z-index: 1;
}

.bookstatusviewtable > tbody > tr > td:first-child {
    position: absolute;
    display: inline-block;
    /* padding-top: 38px; */
    /* background-color: #f6f6f6; */
    z-index: 1;
}

.table-striped td {
    background: #ffffff;
}

/* .bookstatusviewtable > tbody > tr > td:first-child {
    background: -moz-linear-gradient(left, #f4f4f4 83%, #b5b5b5 100%); 
    background: -webkit-linear-gradient(left, #f4f4f4 83%,#b5b5b5 100%); 
        background: linear-gradient(to right, #f4f4f4 83%,#b5b5b5 100%); 
    width: 150px;
    min-height: 0px;
    align-items: center;
    margin-top: 25px;
    background-color: #a1edd5;
    border-radius: 48px; 
     padding-top: 38px;

   
} */

/* 
.bookstatusviewtable > tbody > td {
    display: inline-block;
    width: 160px;
} */

.bookstatusviewtable > thead:first-child > tr:first-child > th:nth-child(2) {
    padding-left: 200px;
    white-space: nowrap;
    
  
}

.bookstatusviewtable > tbody > tr > td:nth-child(2) {
    padding-left: 200px !important;
}

.icon-addon.addon-sm .form-control {
    height: 25px;
}

.icon-addon.addon-sm .fa, .icon-addon.addon-sm .glyphicon {
    top: -4px;
}

.bookstatusviewtable > thead:first-child > tr:first-child > th {
    white-space: nowrap;
}

    /* .bookstatusviewtable > thead:first-child > tr:first-child > th:first-child {
        height: auto;
        width: 150px;
       
        min-height: 0px;
        white-space: nowrap;
        background: -moz-linear-gradient(left, #3db3d9 0%, #1e85a8 83%, #1e85a8 83%, #123156 100%);
        background: -webkit-linear-gradient(left, #3db3d9 0%,#1e85a8 83%,#1e85a8 83%,#123156 100%);
        background: linear-gradient(to right, #3db3d9 0%,#1e85a8 83%,#1e85a8 83%,#123156 100%); 
   
    } */

.bookstatusviewtable th {
    background: #5f95bb;
    color: #ffffff;
    text-shadow: none;
}

.progress-bar-success {
    background: -moz-linear-gradient(top, #3db3d9 0%, #1e85a8 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #3db3d9 0%,#1e85a8 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #3db3d9 0%,#1e85a8 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    -webkit-box-shadow: inset 0px 1px 2px 0px rgba(255,255,255,1);
    -moz-box-shadow: inset 0px 1px 2px 0px rgba(255,255,255,1);
    box-shadow: inset 0px 1px 2px 0px rgba(255,255,255,1);
    border: solid 1px #2182a2;
    border-radius: 10px;
    color: #ffffff !important;
}

.progress-bar-week {
    background: -moz-linear-gradient(top, #999898 0%, #999898 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #999898 0%,#999898 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #999898 0%,#999898 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    -webkit-box-shadow: inset 0px 1px 2px 0px rgba(255,255,255,1);
    -moz-box-shadow: inset 0px 1px 2px 0px rgba(255,255,255,1);
    box-shadow: inset 0px 1px 2px 0px rgba(255,255,255,1);
    border: solid 1px #999898;
    border-radius: 10px;
    color: #ffffff !important;
}

.progress {
    margin: 0px;
    background: #ededed;
    border: solid 1px #93a8a9;
    border-radius: 10px;
}

.progress-bar {
    background-color: #a00707be;
    color: #ffffff;
}

.progress-bar-warning {
    background: -moz-linear-gradient(top, #d6ff2c 0%, #88d42f 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #d6ff2c 0%,#88d42f 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #d6ff2c 0%,#88d42f 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    -webkit-box-shadow: inset 0px 1px 2px 0px rgba(255,255,255,1);
    -moz-box-shadow: inset 0px 1px 2px 0px rgba(255,255,255,1);
    box-shadow: inset 0px 1px 2px 0px rgba(255,255,255,1);
}

.progress-bar-shiftCell {
    background: -moz-linear-gradient(top, #e6b64f 0%, #e6b64f 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #e6b64f 0%,#e6b64f 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #e6b64f 0%,#e6b64f 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    -webkit-box-shadow: inset 0px 1px 2px 0px rgba(255,255,255,1);
    -moz-box-shadow: inset 0px 1px 2px 0px rgba(255,255,255,1);
    box-shadow: inset 0px 1px 2px 0px rgba(255,255,255,1);
    color: #000000 !important;
}

.progress-bar-shiftAvailable {
    background: -moz-linear-gradient(top, #4ccfd1 0%, #4ccfd1 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #4ccfd1 0%,#4ccfd1 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #4ccfd1 0%,#4ccfd1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    -webkit-box-shadow: inset 0px 1px 2px 0px rgba(255,255,255,1);
    -moz-box-shadow: inset 0px 1px 2px 0px rgba(255,255,255,1);
    box-shadow: inset 0px 1px 2px 0px rgba(255,255,255,1);
    color: #000000 !important;
}

.margintop0 {
    margin-top: 0px;
}

.marginbottom0 {
    margin-bottom: 0px;
}

.dashboardcontent li {
    list-style-type: none;
    font-size: 14px;
}

.dashboardcontent h5 {
    font-weight: 600;
    font-size: 15px;
}

.ui-widget {
    font-family: "Open Sans",Arial,Helvetica,Sans-Serif;
}

.ui-corner-all {
    border-radius: 0px;
}

.ui-widget-header {
    background: #203745;
    border: 1px solid #203745;
    font-weight: 500;
}

.ui-state-default, .ui-widget-content .ui-state-default {
    color: #3276b1;
}

.popupdivstyle {
    width: 360px;
}

    .popupdivstyle h6 {
        margin: 0px;
        padding: 10px 0px;
        color: #5f95bb;
        font-weight: 600;
    }

    .popupdivstyle .widthleft {
        width: 100%;
        float: left;
        text-align: left;
        color: #666666;
        padding-bottom: 0px;
        font-size: 13px;
    }

    .popupdivstyle .widthright {
        width: 100%;
        font-size: 13px;
        float: left;
        padding-left: 0px;
        font-weight: 600;
        text-align: left;
        color: #000000;
        padding-bottom: 0px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

.widthleft1 {
    width: 100px;
    float: left;
    text-align: left;
    color: #666666;
    padding-bottom: 5px;
    font-size: 13px;
    clear: left;
}

.widthright1 {
    width: 130px;
    font-size: 13px;
    float: left;
    padding-left: 10px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    padding-bottom: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    clear: right;
    white-space: nowrap;
}

.textalignleft {
    text-align: left;
}

.textalignright {
    text-align: right;
}

.greycolor666 {
    color: #666666;
}

.blackcolor {
    color: #000000;
}

.chosen-container {
    width: 100% !important;
}

ul.nav li.dropdown:hover > ul.dropdown-menu {
    display: block;
}

.menu-on-top #main {
    margin-top: 0px !important;
}

.menu-on-top aside#left-panel nav > ul > li > a > i {
    width: auto;
    font-size: 20px;
    margin-bottom: 0px;
    display: inline-block !important;
}

.menu-on-top aside#left-panel nav > ul > li {
    border-left: 0px;
    border-right: 0px;
}

    .menu-on-top aside#left-panel nav > ul > li > a {
        height: auto;
    }

body.menu-on-top.fixed-header {
    padding-top: 0px;
}

.menu-on-top .menu-item-parent {
    max-width: none;
    overflow: initial;
    text-overflow: initial;
}

.fixed-navigation nav {
    padding-bottom: 0px;
}

.popover {
    width: 400px !important;
    padding: 0px;
    margin: 0px;
}

.menu-on-top nav ul ul li:hover > a:after, .menu-on-top nav > ul ul > li a:after, .menu-on-top nav > ul ul > li a:hover:after, .menu-on-top nav > ul > li > a:after {
    line-height: 0;
}

.selectcampaigntext {
    font-size: 13px;
    color: #000000;
    font-weight: 600;
}

.panel-heading h5 {
    margin-bottom: 0px;
}

.dashboardcontent li {
    /* list-style: url(../Images/cosmos.png); */
    line-height: 26px;
    font-size: 13px;
    list-style-position: inside;
}

    .dashboardcontent li span {
        position: relative;
        top: -4px;
    }

.topMar10 {
    margin-top: 10px;
}


/*animation*/

/*.ng-enter {
    -moz-transition: 0.75s;
    -o-transition: 0.75s;
    -webkit-transition: 0.75s;
    transition: 0.75s;
    -ms-opacity: 0;
    opacity: 0;
    
}

.ng-enter-stragger {
    transition-delay: 0.1s;
}

.ng-enter-active {
    -ms-opacity: 1;
    opacity: 1;
}


.ng-leave {
     -moz-transition: 0.75s;
    -o-transition: 0.75s;
    -webkit-transition: 0.75s;
    transition: 0.75s;
    -ms-opacity: 1;
    opacity: 1; 
}


.ng-leave-stragger {
    transition-delay: 0.1s;
}

.ng-leave-active {
      -ms-opacity: 0;
    opacity: 0;
    
}*/



/*source
 http://dfsq.github.io/ngView-animation-effects/app/#/code*/

.slide {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

    .slide.ng-enter,
    .slide.ng-leave {
        -webkit-transition: all 1s ease;
        transition: all 1s ease;
    }

    .slide.ng-enter {
        left: 100%;
    }

    .slide.ng-enter-active {
        left: 0;
    }

    .slide.ng-leave {
        left: 0;
    }

    .slide.ng-leave-active {
        left: -100%;
    }



.pop {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

    .pop.ng-enter,
    .pop.ng-leave {
        -webkit-transition: all 1s ease;
        -moz-transition: all 1s ease;
        -ms-transition: all 1s ease;
        -o-transition: all 1s ease;
        transition: all 1s ease;
    }

    .pop.ng-enter {
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
    }

    .pop.ng-enter-active,
    .pop.ng-leave,
    .pop.ng-leave-active {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }

    .pop.ng-leave-active {
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
    }

.slide-reveal.ng-enter {
    -webkit-transition: 0.5s linear all;
    -moz-transition: 0.5s linear all;
    -o-transition: 0.5s linear all;
    -ms-transition: 0.5s linear all;
    transition: 0.5s linear all;
    -ms-opacity: 0.5;
    opacity: 0.5;
    position: relative;
    opacity: 0;
    top: 10px;
}

    .slide-reveal.ng-enter.ng-enter-active,
    .slide-reveal.ng-enter-active {
        top: 0;
        -ms-opacity: 1;
        opacity: 1;
    }



/*for grid loading*/
.grid-msg-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
}

/*
  This guy will contain our message. We want it centered
  so it's positioned absolutely with percentage-based
  offsets and dimensions. It also has some basic border
  stuff and most important is using "display: table" so
  we can vertically center its contents.
*/
.gridloading {
    -ms-opacity: 6;
    opacity: 6;
    position: absolute;
    top: 20%;
    left: 20%;
    width: 60%;
    height: 50%;
    text-align: center;
    font-size: 24px;
    display: table;
}

    /*
  Lastly this is the actual message text. It uses
  display: table-cell so the vertical alignment
  works properly.
*/
    .gridloading span {
        display: table-cell;
        vertical-align: middle;
    }

.movecontrol input {
    display: block;
    clear: both;
    width:100%;
    margin-bottom:10px;
}

.movecontrol {
    width: 100px;
    float:left;
    padding-top:8%;
}
.panel-teal {
    color: #FFFFFF;
    background-color: #008080;
    border-color: #008080;
}
.panel-parrotgreen {
    color: #FFFFFF;
    background-color: #7BEE01;
    border-color: #7BEE01;
}
.panel-orange {
    color: #FFFFFF;
    background-color: #FF8C00;
    border-color: #FF8C00;
}
.panel-body-dash {
    color: #000000;
    background-color:#ffffff;
    
}
.huge {
    font-size: 45px;
}





.box {
    position:absolute;
    /* top:30%;*/
    left:50%; 
    transform:translate(-50%,-50%);
}
.notifications {
    width:50px;
    height:50px;
    background:#fff;
    border-radius:30px;
    box-sizing:border-box;
    text-align:center;
    box-shadow:0 2px 5px rgba(0,0,0,.2);
}
/* .notifications:hover {
    width:300px;
    height:60px;
    text-align:left;
    padding:0 15px;
    background:#ff2c74;
    transform:translateY(-100%);
    border-bottom-left-radius:0;
    border-bottom-right-radius:0;
} */
.notifications:hover .fa {
    color:#ff2c74;
}
.notifications .fa {
    color:#cecece;
    line-height:60px;
    font-size:30px;
    width:50px;
    height:50px;
}
.notifications .num {
    position:absolute;
    top:0;
    right:-5px;
    width:25px;
    height:25px;
    border-radius:50%;
    background:#ff2c74;
    color:#fff;
    line-height:25px;
    font-family:sans-serif;
    text-align:center;
}
.notifications:hover .num {
    position: absolute;
    top: 0;
    right: -5px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #ff2c74;
    color: #fff;
    line-height: 25px;
    font-family: sans-serif;
    text-align: center;
    /* position:absolute;
    background:transparent;
    color:#ff2c74;
    font-size:24px;
    top:0;
    border-radius:50%;
    line-height:25px;
    font-family:sans-serif;
    text-align:center;
    right:-5px;
    width:50px;
    height:50px; */
}
/* .notifications:hover .num:after {
    content:' Notification';
} */
/* 
ul {
    position:absolute;
    left:0;
    top:50px;
    margin:0;
    width:100%;
    background:#fff;
    box-shadow:0 5px 15px rgba(0,0,0,.5);
    padding:20px;
    box-sizing:border-box;
    border-bottom-left-radius:30px;
    border-bottom-right-radius:30px;
    display:none;
}
.notifications:hover ul {
    display:block;
}
ul li {
    list-style:none;
    border-bottom:1px solid rgba(0,0,0,.1);
    padding:8px 0;
    display:flex;
}
ul li:last-child {
    border-bottom:none;
}
ul li .icon {
    width:24px;
    height:24px; 
    background:#ccc;
    border-radius:50%;
    text-align:center;
    line-height:24px;
    margin-right:15px;
}
ul li .icon .fa {
    color:#fff;
    font-size:16px;
    line-height:24px;
}
ul li .text {
    position:relative;
    font-family:sans-serif;
    top:3px;
    cursor:pointer;
}
ul li:hover .text {
    font-weight:bold;
    color:#ff2c74;
} */

.progress p{
   margin-top:-4px;
   
}
.TitleStyle{
    background-color: white;
}