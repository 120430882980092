.round-img {
  border-radius: 15px;
  max-height: 100%;
  max-width: 100%;


}

.rail-img {
  max-height: 100%;
  max-width: 100%;
  user-select: none;
  -webkit-user-drag: none;
  border-radius: 5px;
  object-fit: contain;
  transition: all 0.4s;
}

.rail-img-circle {
  border-radius: 50% !important;
  height: 100px;
  width: 100px;
  user-select: none;
  -webkit-user-drag: none;
  transition: all 0.4s;
  object-fit: cover;
}


.rail-img-circle-tv {
  border-radius: 50% !important;
  height: 150px;
  width: 150px;
  user-select: none;
  -webkit-user-drag: none;
  transition: all 0.4s;
  object-fit: cover;
}

.grid-img {
  transition: all 0.4s;
}

.grid-img:hover {
  transform: scale(1.02);
}

.rail-img:hover {
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.3);
}

.rail-box-circle {
  height: 100px;
  width: 100px;
  cursor: pointer;
  transform: scale(0.9);
  transition: all 0.4s;
}


.rail-box-circle-tv {
  height: 150px;
  width: 150px;
  cursor: pointer;
  transform: scale(0.9);
  transition: all 0.4s;
}

.rail-box-circle:hover {
  transform: scale(1.0);
}

.rail-box {
  cursor: pointer;
  transform: scale(0.9);
  transition: all 0.4s;
}

.rail-box:hover {
  transform: scale(0.95);
}

.carousel-dots {
  padding-bottom: 30px;
}

@media only screen and (max-width: 700px) {
  .round-img {
    aspect-ratio: 16/9;
  }

}

.round-img-mobile {
  border-radius: 10px;
  max-height: 100%;
  max-width: 100%;
}



.mobilePosition {
  position: absolute;
  top: -50px;
  left: 35%;
  scale: 0.80;
}

._1HaoU {
  width: 200px !important;
  height: 40px !important;
  color: black !important;

}



._3ZP5s li {
  color: black;
}

.herobannerMobile {
  background-color: bisque;
}

.rail-title {
  margin-top: 5px;
  margin-bottom: 1px;
  margin-left: 7px !important;

}

.style-4::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: #575151;
}

.style-4::-webkit-scrollbar {
  width: 5px;
  background-color: #575151;
}

.style-4::-webkit-scrollbar-thumb {
  background-color: #5a5151;
  border: 2px solid #575151;
}